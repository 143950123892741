<template>
    <div class="container-fluid mt-5 mb-100">
        <bread-crumbs :pages-array="arrayLinks" />
        <div>
            <div class="col-12">
                <div class=" section--no-pt">
                <div class="container">
                    <h1 style="font-size: 3.2rem;">{{ element.name }}</h1>
                </div>
                </div>
                <div class="section--no-pt">
                    <div class="container">
                        <div class="offer">
                            <div class="offer-image">
                                <img :src="element.linkimg" alt="">
                            </div>
                            <div class="offer-content">
                                <h4>Подробности акции</h4>
                                <p v-html="element.details"></p>
                                <a class="button button--primary" @click="openModalApp">Отправить заявку</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <application v-if="app == true"/>
        </div>
    </div>
</template>
<script>
import application from '../../modals/application.vue'
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Vuex from 'vuex'
export default {
      mixins: [Functions],
    components:{
        application,
        breadCrumbs
    },
    data() {
        return {
            activeCategory: 0,
            object: {},
            element: {},
        }
    },
    watch:{
        uiPromotions(){
            this.getOnePromotion()
        },
    },
    computed: {
        ...Vuex.mapGetters({
            app:'appmodal/app',

            arrayLinks: 'ui_promotion/arrayLinks',
            uiPromotions: 'ui_promotion/promotions',
        }),
        
    },
    methods: {
         ...Vuex.mapMutations({
            setApp:'appmodal/setApp',
            setHrefApp:'appmodal/setHrefApp',
        }),
        getOnePromotion() {
            this.uiPromotions.map((el) => {
                if (el.link == this.$route.params.key) {
                    this.element = el
                }
            })
        },
        openModalApp(){
            this.setHrefApp(window.location.href)
            this.setApp(true) 
        },
    },
    created(){
        this.getOnePromotion()
    }
}
</script>
<style lang="scss">
    .mb-100{
        margin-bottom: 100px;
    }
</style>