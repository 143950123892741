<template>
    <div class="section home_build_block">
        <div class="container container-nd">
            <p class="header">Мы строим быстровозводимые здания</p>
            <div class="build_cards">
                <div class="build_card">
                    <img src="@/img/nd-imgs/build_1.png" alt="">
                    <p class="card_header">Проектирование металлоконструкций</p>
                    <p class="card_description">
                        Разработкой проектов КМД у нас занимается собственный конструкторский отдел. 
                        Мы подготовим комплект рабочей документации, согласуем его с заказчиком и передадим на реализацию.
                    </p>
                    <button @click="openModal('Проектирование металлоконструкций')" class="button">Оставить заявку</button>
                </div>
                <div class="build_card">
                    <img src="@/img/nd-imgs/build_2.png" alt="">
                    <p class="card_header">Изготовление металлоконструкций</p>
                    <p class="card_description">
                        Производство и монтаж металлоконструкций быстровозводимых зданий, а так же металлокнострукций 
                        для строительства зданий промышленного и гражданского назначения, арматурных каркасов и иных 
                        металлоконструкций по чертежам заказчика
                    </p>
                    <button @click="openModal('Изготовление металлоконструкций')" class="button">Оставить заявку</button>
                </div>
                <div class="build_card">
                    <img src="@/img/nd-imgs/build_3.png" alt="">
                    <p class="card_header">Монтаж металлоконструкций</p>
                    <p class="card_description">
                        Силами наших специалистов обеспечивается установка металлоконструкций любой сложности. 
                        Вам достаточно только указать место и назвать сроки завершения работ.
                    </p>
                    <button @click="openModal('Монтаж металлоконструкций')" class="button">Оставить заявку</button>
                </div>
            </div>
        </div>

        <application 
          v-if="app == true" 
          :nameServiceForMessage="nameServiceForMessage"
        />
    </div>
</template>
<script>
import Functions from '@/utils/functions'
import Vuex from 'vuex'
import application from '@/components/modals/application.vue'

export default {
    mixins: [Functions],
    components: {
        application,
    },
    data() {
        return {
            nameServiceForMessage: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            app:'appmodal/app',
        }),
    },
    methods: {
        ...Vuex.mapMutations({
            setApp:'appmodal/setApp',
        }),
        openModal(name) {
            this.nameServiceForMessage = name 
            this.setApp(true)
        },
    },
}
</script>