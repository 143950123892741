<template>
    <section class="about_company-info_page-sp_banner">
        <div class="container container-nd">
            <div 
                class="banner_block"
                :class="'banner_block_' + typeBanner"
            >
                <img v-if="typeBanner == 'default' || isEmptyObject(currentBanner)" class="main_banner" src="@/img/info-sp/banner.png" alt="">
                <img v-if="typeBanner == 'proflist'" class="main_banner" :src="mainImage" alt="">
                <div v-if="typeBanner != 'proflist'" class="text_block">
                    <p class="main_header">{{ mainHeader.toUpperCase() }}</p>
                    <button class="button button--primary" @click="push(mainLink)">Узнать подробнее</button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Functions from '@/utils/functions'
import Vuex from 'vuex'

export default {
    mixins: [Functions],
    data() {
        return {
            
        }
    },
    computed: {
        ...Vuex.mapGetters({
            bannersMap: 'dictionary/bannersMap',
            homeCategoryBanners: 'app/homeCategoryBanners',
            region: 'auth/city',
        }),
        route_id() {
            return this.$route.params.id_1c_category
        },
        currentBanner() {
            let banner = {}
            this.homeCategoryBanners.map((element) => {
                if (element.id_1c == this.route_id && element.root_id == this.region) {
                    banner = element
                }
            })
            return banner
        },
        typeBanner() {
            let type = 'default'
            this.homeCategoryBanners.map((element) => {
                if (element.id_1c == this.route_id  && element.root_id == this.region) {
                    type = element.name
                }
            })
            return type
        },
        mainImage() {
            let img = null
            this.homeCategoryBanners.map((element) => {
                if (element.id_1c == this.route_id && element.root_id == this.region) {
                    img = element.img
                }
            })
            return img
        },
        mainHeader() {
            let header = 'Сэндвич-панели'
            this.homeCategoryBanners.map((element) => {
                if (element.id_1c == this.route_id && element.root_id == this.region) {
                    if (element.name == 'proflist') {
                        header = 'Профилированный лист'
                    }
                }
            })
            return header
        },
        mainLink() {
            let link = 'aboutcompany/info/sp'
            this.homeCategoryBanners.map((element) => {
                if (element.id_1c == this.route_id && element.root_id == this.region) {
                    if (element.name == 'proflist') {
                        link = element.link
                    }
                }
            })
            return link
        },
    },
}
</script>