<template>
  <div class="index__admin">
      <span>Блок № {{index+1}}  </span>
       <span  class="index__del" @click="deleteBlock" >Удалить Х</span>
        <div class="col-12 row"  >
            
            <div class="col-3" >
                <input   type="text" placeholder="Год" v-model="editElement.blockName" @keyup="update">
            </div>
            <div class="col-9">
                <input type="text" placeholder="Заголовок" v-model="editElement.blockLink" @keyup="update">
            </div>
        </div>
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            company: 'app/company',
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateCompanyBlock: 'app/updateCompanyBlock',
            deleteCompanyBlock: 'app/deleteCompanyBlock',
        }),
         update() {
            this.updateCompanyBlock({idx: this.index, blockName: this.editElement.blockName, blockLink: this.editElement.blockLink})
        },
        deleteBlock(){
             this.deleteCompanyBlock(this.index) 
        },
    },
}
</script>