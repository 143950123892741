<template>
     <main class="main">
        <bread-crumbs :pages-array="pagesObjects" />
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Наши объекты</h2>
                </div>
              </div>
            </div>
            <div class="layout layout--page layout--page-wide">
                <div class="layout-aside">
                    <ul class="list navigation-aside">
                        <li class="list-item">
                            <a  :class="activeCategory == null ? 'list-item -active' :'list-item-active' " @click="push('objects')">Все объекты</a>
                        </li>
                        <li class="list-item" v-for="obj, i in our_objects.categories" :key="i">
                            <a :class="$route.params.cat == obj.link ? 'list-item -active' :'list-item ' " @click="push('objects/' + obj.link)">{{obj.name}}</a>
                        </li>
                    </ul>
                </div>
                <objecst-cat :objects="objects"  />
            </div>
          </div>
        </div>
      </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import ObjecstCat from './objectsCat.vue'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
export default {
    mixins: [Functions],
    components:{
        ObjecstCat,
        breadCrumbs,
    },
    data() {
        return {
            activeCategory: null,
            
        }
    },
    watch:{
        $route(){
            if(this.$route.params.cat){
                this.activeCategory = this.$route.params.cat
            }else{
                this.activeCategory = null
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
            pagesObjects: 'app/pagesObjects',
            
        }),
        objects(){
            var dict =[]
            if(!this.$route.params.cat ){
                if(this.our_objects.categories == null){
                    return
                }
                this.our_objects.categories.map((el)=>{
                    if(el == null){
                        return
                    }
                    el.objects.map((ell)=>{
                        dict.push(ell)
                        return
                    })
                    return
                })
            }else{
                if(this.our_objects.categories == null){
                    return
                }
                this.our_objects.categories.map((el)=>{
                    if(el.link == this.$route.params.cat){
                        dict = el.objects
                    }
                })
            }
            return dict
        }
    },
    // methods: {
    //     getObjects(){
            
    //     }
    // },
    created(){
        if(this.$route.params.cat){
            this.activeCategory = this.$route.params.cat
        }
    }
}
</script>