<template>
    <div class="layout-section">
        <ul class="list layout-objects-page">
            <li 
                v-for="obj, i in objects"
                v-show="obj.mainImg && obj.name" 
                :key="i"
                class="list-item" 
                @click="push('objects/' + obj.link + '/' + obj.linkObj)"
            >
                <a class="object">
                    <div class="object-image">
                        <img  :src="obj.mainImg" alt=""/>
                    </div>
                    <div class="object-title">{{obj.name}}</div>
                </a>
            </li>
        </ul>
    </div>  
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    props:['objects'],
    mixins: [Functions],
    components:{
    },
    data() {
        return {
            activeCategory: 0,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            offers: 'app/offers',
            offersFrom:'dictionary/offersFrom',
            offersRegion:'dictionary/offersRegion',
        }),
    },
    methods: {
        // changeActiveCategory(num) {
        //     this.activeCategory = num
        // },
    },
}
</script>