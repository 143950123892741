<template>
    <section class="about_company-info_block">
        <div class="container container-nd">
            <p class="main_header">Информация</p>
            <div class="info_cards">
                <div class="info_card">
                    <img src="@/img/info-sp/about_company_card1.png" alt="">
                    <p class="card_header">Сэндвич-панели</p>
                    <p class="card_description">
                        Сэндвич-панели - это оптимальное решение для любого строительного проекта. Они сочетают в себе прочность, 
                        энергоэффективность, легкость установки и эстетическую привлекательность. Независимо от того, строите вы склад, 
                        офисное здание или дом, сэндвич-панели являются надежным выбором для достижения высокого уровня качества и комфорта.
                        Сэндвич-панели нашего производства представляют собой трехслойную конструкцию, состоящую из двух листов металла, 
                        между которыми расположена теплоизоляционная прослойка, что особенно важно для зданий, требующих постоянной...
                        <!-- поддержки 
                        оптимальной температуры. Металл крепится к утеплителю с помощью двухкомпонентного клея. Стальные листы делают 
                        сэндвич-панели крайне прочными и стойкими к различным воздействиям окружающей среды. Строительство с использованием 
                        сэндвич-панелей происходит гораздо быстрее, так как установка сэндвич-панелей требует минимального количества 
                        рабочей силы и времени. -->
                    </p>
                    <div class="button_block">
                        <button @click="push('aboutcompany/info/sp')" class="button button--primary">Подробнее</button>
                    </div>
                </div>
                <div class="info_card">
                    <img src="@/img/info-sp/about_company_card2.png" alt="">
                    <p class="card_header">Профилированный лист</p>
                    <p class="card_description">
                        Профилированный лист является идеальным материалом для строительства и отделки различных объектов. 
                        Его прочность, универсальность, защитные свойства, легкость и доступность делают его незаменимым помощником 
                        в строительной сфере. Вам не придется беспокоиться о будущих ремонтах или заменах, так как профлист прослужит 
                        вам долгие годы, сохраняя свои отличные характеристики. Он изготавливается из высококачественной стали, которая 
                        обладает высокой степенью устойчивости к воздействию окружающей среды и механическим нагрузкам...
                    </p>
                    <div class="button_block">
                        <button @click="push('aboutcompany/info/proflist')" class="button button--primary">Подробнее</button>
                    </div>
                </div>
                <div class="info_card">
                    <img src="@/img/info-sp/about_company_card3.png" alt="">
                    <p class="card_header">Металлочерепица</p>
                    <p class="card_description">
                        Если вы ищете надежное и стильное решение для вашей крыши, металлочерепица - это идеальный выбор, который 
                        прослужит вам долгие годы. Металлочерепица – лучшее решение для кровли с множеством преимуществ. Благодаря 
                        использованию высококачественного металла и защитных покрытий, металлочерепица способна выдерживать экстремальные 
                        погодные условия, такие как сильные ветры, снегопады и дожди, без каких-либо повреждений или утраты эстетического 
                        вида. Это означает, что вам не придется беспокоиться о ремонтных работах...
                        <!-- на крыше в течение многих лет. -->
                    </p>
                    <div class="button_block">
                        <button @click="push('aboutcompany/info/proflist')" class="button button--primary">Подробнее</button>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
}
</script>