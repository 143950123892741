<template>
    <section class="about_company-info_page-sp_type-profiles">
        <div class="container container-nd">
            <p class="main_header">Тип профилирования</p>
            <div class="cards_proftypes profile_proftypes">
                <div 
                    v-for="profile in profiles"
                    :key="profile.id"
                    v-show="checkProfileNameOnNumeric(profile.name) != 0"
                    class="card_proftype"
                    @click="pushProflistById1c(profile.id)" 
                >
                    <img v-tooltip="profile.product_description" class="question_icon" src="@/img/info-sp/icons/question.png" alt="question icon">
                    <img :src="yandexCloud + profile.preview" class="profile_type_img" alt="proftype img" style="background-color: rgb(199, 199, 197);">
                    <p>{{ profile.name.toUpperCase() }}</p> 
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
import Finctions from '@/utils/functions'
import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

export default {
    mixins: [Finctions],
    data() {
        return {
            profiles: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            proflistCardsMap: 'dictionary/proflistCardsMap',
            region:'auth/city',
            yandexCloud: 'app/yandexCloud',
        }),
    },
    created() {
        this.getProfiles()
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        getLinkOfMap(name) {
            let link = null
            this.proflistCardsMap.map((element) => {
                if (element.name == name) {
                    link = element.link
                }
            })
            return link
        },
        pushProflist(name) {
            if (!this.getLinkOfMap(name)) {
                return
            }
            window.open('/' + this.getLinkOfMap(name), '_blank')
        },
        pushProflistById1c(id) {
            if (!id) {
                return
            }
            this.push('cardProduct/' + id)
            // window.open('/cardProduct/' + id, '_blank')
        },
        checkProfileNameOnNumeric(name) {
            return parseInt(name.replace( /\D/g, '')) || 0;
        },
        getProfiles() {
            let form = {
                root_id: this.region,
                query: 'профлист',
                is_group: true,
            }
            this.searchNavbar(form)
                .then((response) => {
                    this.profiles = response.data
                })
        },
    },
}
</script>