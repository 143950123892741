<template>
  <div class="good">
    <span class="index__del" @click="deleteMainPopularProduct(index)">Удалить X</span>
    <h5>Товар №{{ index + 1 }}</h5>
    <label>ID товара из 1c</label>
    <input 
      v-model="editElement.link"
      type="text" 
      placeholder="ID товара из 1c"
      @keyup="update"
    >
  </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {

        }
    },
    computed: {
        editElement() {
            return Object.assign({}, this.element)
        }
    },
    methods: {
        ...Vuex.mapMutations({
            deleteMainPopularProduct: 'app/deleteMainPopularProduct',
            updateMainPopularProduct: 'app/updateMainPopularProduct',
        }),
        update() {
            this.updateMainPopularProduct({idx: this.index, link: this.editElement.link})
        },
    },
}
</script>

<style lang="scss">
    .good{
        display: grid;
        margin-top: 15px;
    }
</style>