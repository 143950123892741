<template>
  <div class="section form_callback_nd">
    <div class="container" :class="isContainerNd ? 'container-nd' : ''">
      <div class="inner bg_pd">
        <div class="form_block">
          <div class="col left_part">
            <h2 class="header">ОСТАВЬТЕ ЗАЯВКУ НА<br> КОНСУЛЬТАЦИЮ</h2>
            <p class="subheader">Наш специалист ответит в течении 15 минут</p>
          </div>
          <div class="col right_part">
            <form class="form" onsubmit="return false">
              <div class="form-fieldset">
                <div class="form-group-nd">
                  <div class="form-item form-item-nd form-item-nd_left">
                    <div class="field">
                      <div class="field-label">Имя</div>
                      <div class="field-input">
                        <input type="text"  v-model="name">
                      </div>
                    </div>
                  </div>
                  <div class="form-item form-item-nd">
                    <div 
                      class="field"
                      :class="{'field--error' : isPhoneError}"
                    >
                      <div class="field-label">Телефон *</div>
                      <div class="field-input">
                        <the-mask 
                          v-model="phone" 
                          mask="+7 (###) ###-####" 
                          type="tel"  
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-item form-item-nd">
                  <button 
                    class="button button--primary" 
                    :class="{'button--primary' : phone.length == 10}" 
                    :disabled="phone.length < 10 ? true : false" 
                    @click="submitForm"
                  >
                    Заказать звонок
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vuex from 'vuex'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
  props: {
    isContainerNd: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      name: null,
      phone: '',
      isAgree: false,
      isSended: false,
      isPhoneError: false,
    }
  },
  watch: {
    phone() {
      this.checkPhoneOnError()
    }
  },
  computed: {
    ...Vuex.mapGetters({
      
    }),
  },
  methods: {
    ...Vuex.mapMutations({
        setShowSendedModal: 'app/setShowSendedModal',
        setSendedMessage: 'app/setSendedMessage',
    }),
    ...Vuex.mapActions({
        sentAppBackCall:'appmodal/sentAppBackCall'
    }),
    submitForm() {
      this.isSended = false
      if (!this.isAgree && this.phone.length != 10) {
        return
      }
      let formData = new FormData()
      formData.append('file', null)
      formData.append("email", null)
      formData.append("name", this.name)
      formData.append("telephone", this.phone)
      formData.append("type", 'backcall')
      formData.append("message", null)
      this.sentAppBackCall(formData)
        .then(() => {
          this.isSended = true
          this.name =  null
          this.phone = ''
          this.isAgree = false
          this.setSendedMessage('Ваше обращение успешно зарегистрировано!')
          this.setShowSendedModal(true)
        })
    },
    checkPhoneOnError() {
      if (this.phone.length < 10) {
        this.isPhoneError = true
      }else if (this.phone.length == 10 && this.isAgree) {
        this.isPhoneError = false
      }
    },
  }
}
</script>