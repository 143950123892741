<template>
    <section class="about_company-info_page-sp_cards">
        <div class="container container-nd">
            <p class="main_header">Сэндвич-панели специального назначения для чистых помещений</p>
            <div class="cards_description">
                <p>
                    Используются для пищевых производств, металлургии, химической промышленности и предприятий 
                    животноводства, птицефабрик, хранилищ зерна, овощей и кормов. Покрытие облицовки эффективно защищает 
                    панели от коррозии в условиях высокой концентрации химических веществ. Особое значение сэндвич-панели с 
                    облицовкой из нержавеющей стали имеют для пищевой и мясоперерабатывающей промышленности, для 
                    которых соблюдение гигиенической нормы является принципиальным моментом.
                </p>
                <p>
                    Нержавеющие стали разнообразны. Характеристики коррозионностойких сталей напрямую зависят от 
                    марок сталей. Чем больше легирующих элементов в стали – хром, никель, молибден, тем выше коррозионные 
                    свойства. Особенно важен в составе стали молибден - он значительно повышает устойчивость к коррозии.
                </p>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>