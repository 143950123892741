<template>
   <div class="section section--no-pt section--cards home_cards_block">
        <div class="container container-nd">
            <!-- <div>
                <swiper 
                    :options="swiperOption" 
                    :breakpoints="swiperOption.breakpoints"
                    ref="swiperThumbs"
                >
                    <swiper-slide 
                        v-for="(category , i) in uiCategories" 
                        :key="i"
                    >
                        <single-card :category="category" :number="i"/>
                    </swiper-slide>
                </swiper>
            </div> -->
            <div class="nd-cards">
                <div 
                    v-for="(category , i) in uiCategories" 
                    :key="i"    
                    class="nd-card"
                >
                    <single-card :category="category" :number="i"/>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from "@/utils/functions"
// import { Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'
import singleCard from './single-card.vue'

export default {
    mixins: [Functions],
    components: {
        // Swiper,
        // SwiperSlide,
        singleCard,
    },
    // directives: {
    //     swiper: directive
    // },
    data() {
        return {
            swiperOption: {
                spaceBetween: 0,
                slidesPerView: 1.3,
                slideToClickedSlide: true,
                breakpoints: {
                    768: {
                        slidesPerView: 3.1
                    },
                    1260: {
                        slidesPerView: 4.1
                    },
                    1680: {
                        slidesPerView: 4.1
                    },
                    1770: {
                        slidesPerView: 4.6
                    },
                },
            },
            categories: [],
            svgCode: null,
            categoryID1cs: [],
            sortedCategories: [],
        }
    },
    computed: {
        ...Vuex.mapGetters({
            main: 'app/main',
            currentWindowWidth: 'app/currentWindowWidth',
            region:'auth/city',
            uiCategories: 'ui_category/categories',
        }),
    },
    watch: {
        // region() {
        //     this.getCategories()
        // },
        // uiCategories() {
        //     this.setCategories()
        // },
    },
    mounted() {
        // this.setCategories()
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
            getSvgHtml: 'app/getSvgHtml',
        }),
        getImg(id_1c) {
            let img = null
            this.uiCategories.map((element) => {
                if (id_1c == element.id_1c) {
                    if (element.imgType == 'image/svg+xml') {
                        img = this.getSvg(element.img)
                    }else{
                        img = element.img
                    }
                }
            })
            return img
        },
        getSvg(img) {
            let code = null
            this.getSvgHtml(img)
                .then((response) => {
                    code = response
                })
            return code
        },
        getName(id_1c) {
            let name = null
            this.main.catalogCategories.map((element) => {
                if (id_1c == element.id_1c) {
                    name = element.name
                }
            })
            return name
        },
        setCategories() {
            let category_id1cs = []
            if (this.uiCategories) {
                this.uiCategories.sort((a, b) => a.sort - b.sort) 
                if (this.uiCategories && this.uiCategories.length != 0) {
                    this.uiCategories.map((element) => {
                        category_id1cs.push(element.id_1c)
                    })
                }
            }
            this.categoryID1cs = category_id1cs
            this.getCategories()
        },
        getLocalCategoryById1c(id_1c) {
            let elem = null
            this.categories.map((element) => {
                if (element.id_1c == id_1c) {
                    elem = element
                }
            })
            return elem
        },
        sortCategories() {
            let sortedCategories = []
            this.categoryID1cs.map((element) => {
                sortedCategories.push(this.getLocalCategoryById1c(element))
            })

            this.sortedCategories = sortedCategories
        },
        getCategories() {
            if (this.categoryID1cs.length == 0 || !this.region) {
                return
            }
            this.searchNavbar({id_1cs: this.categoryID1cs, root_id: this.region, componentFrom: 'home/cards'})
              .then((response) => {
                  this.categories = response.data
                  this.sortCategories()
              })
        }
    },
}
</script>
<style scoped src="../../../../css/castom.css"></style>
<style>
    .card {
        background-size: cover;
        background-repeat: no-repeat;
    }
    .card--media{
        margin: 0.5rem !important;
    }
</style>