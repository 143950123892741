<template>
    <section>
        <div class="nd-about-company_page">
            <div class="container container-nd">
                <bread-crumbs class="breadcrumbs" :pages-array="pages" :withNdProp="false" />
            </div>
            <div class="container container-nd">
                <div class="main_block">
                    <div class="main_block_img">
                        <img class="main_block_img" src="@/assets/img/nd-ac/main_img.png" alt="">
                    </div>
                    <div class="main_block_header">
                        <p>МаякМеталл - это уникальный партнер для всех, кто ищет комплексное решение для своих задач</p>
                    </div>
                    <div class="main_block_subheader">
                        <p>Мы предлагаем клиентам полный цикл услуг, начиная с поставки продукции и заканчивая проектированием и изготовлением конструкций</p>
                    </div>
                </div>
            </div>
            <div class="container container-nd">
                <div class="about_block">
                    <div class="left_block">
                        <p>О нас</p>
                    </div>
                    <div class="right_block">
                        <p>
                            Компания «МаякМеталл» более 34 лет является ведущим производителем и поставщиком строительных материалов: металлопроката 
                            (профнастила, металлочерепицы, стеновых и кровельных сэндвич-панелей, металлоконструкций), а также крепежных изделий.
                        </p>
                        <p>
                            Сегодня компания имеет 17 представительств по всей территории России: Самара, Тольятти, Пенза, Пермь, Красноярск, Канск, Иркутск, 
                            а также в Казахстане и планирует продолжить свое развитие в странах ближнего зарубежья.
                            Мы гордимся сотрудничеством с ведущими производителями и поставщиками сырья, которое позволяет нам гарантировать высочайшее качество 
                            нашей продукции и предлагать широкий ассортимент материалов, которые отличаются надежностью, прочностью и долговечностью.
                        </p>
                        <p>
                            МаякМеталл- уникальная компания с профессиональной командой, мы предлагаем клиентам полный цикл услуг, начиная с поставки 
                            профилированного листа и металлочерепицы для розничного сегмента и заканчивая проектированием, изготовлением и монтажом металлоконструкций. 
                            Каждый день мы трудимся, создавая новые идеи, внося свой вклад в развитие нашей великой страны и налаживая партнерские связи по всему миру. 
                            Мы делаем ставку на честные и открытые взаимоотношения, подкрепленные нашими ценностями и эмоциями.
                        </p>
                        <p>
                            В компании работает более 1000 профессионалов и, благодаря колоссальному опыту , мы гарантируем индивидуальный подход к каждому проекту. 
                            Мы постоянно развиваемся и совершенствуемся, чтобы предложить клиентам только лучшие и современные решения, учитывающие все предпочтения и 
                            потребности клиента.
                            Одним из наших ключевых преимуществ является использование современного оборудования и передовых технологий на всех 4 производственных 
                            площадках компании. Мы постоянно модернизируем нашу производственную базу, чтобы быть на шаг впереди конкурентов и предлагать нашим клиентам 
                            только лучшее качество продукции.
                        </p>
                        <p>
                            Компания «МаякМеталл»- сервисный металлоцентр, который отличается ответственным и профессиональным подходом к выполнению заказов. 
                            Мы строго соблюдаем сроки производства и доставки, чтобы обеспечить нашим клиентам возможность своевременно реализовывать свои проекты.
                            Мы гордимся своими достижениями и готовы помочь вам реализовать ваши проекты любой сложности. Обратитесь к нам сегодня, и наша команда 
                            профессионалов поможет вам выбрать наиболее подходящие решения для вашего строительного проекта. Вместе с нашей компанией вы получите 
                            надежного партнера, который способен предложить вам широкий выбор высококачественной продукции, индивидуальный подход и профессиональное 
                            обслуживание.
                        </p>
                        <p>
                            Мы будем рады сотрудничеству и готовы помочь вам реализовать любой проект!
                        </p>
                    </div>
                </div>
            </div>
            <div class="container container-nd">
                <div class="progress_block">
                    <div class="progress_list">
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/people.png" alt="">
                            <p class="progress_card_header">800+ сотрудников</p>
                            <p class="progress_card_description">На данный момент в штате компании – более 800 сотрудников. И мы продолжаем развиваться.</p>
                        </div>
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/idustrial.png" alt="">
                            <p class="progress_card_header">5 заводов</p>
                            <p class="progress_card_description">Расположенных в РФ и Казахстане</p>
                        </div>
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/office.png" alt="">
                            <p class="progress_card_header">16 офисов</p>
                            <p class="progress_card_description">Продаж в России и Казахстане</p>
                        </div>
                        <div  class="progress_card">
                            <img src="@/assets/img/vacancy/material.png" alt="">
                            <p class="progress_card_header">более 150 видов</p>
                            <p class="progress_card_description">Производимой продукции металлоконструкции, профлист, гладкий лист, сэндвич-панели и т.п.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container container-nd">
                <div class="img_cards">
                    <div class="img_card">
                        <div class="half_block">
                            <p class="half_block_header">Качество продукции</p>
                            <p class="half_block_subheader">
                                Вся наша продукция произведена с использованием передовых технологий и отвечает самым высоким стандартам качества
                            </p>
                        </div>
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block1.png" alt="">
                        </div>
                    </div>
                    <div class="img_card">
                        <div class="half_block">
                            <p class="half_block_header">Качество продукции</p>
                            <p class="half_block_subheader">
                                Вся наша продукция произведена с использованием передовых технологий и отвечает самым высоким стандартам качества
                            </p>
                        </div>
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block2.png" alt="">
                        </div>
                    </div>
                    <div class="img_card">
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block3.png" alt="">
                        </div>
                        <div class="half_block">
                            <p class="half_block_header">Качество продукции</p>
                            <p class="half_block_subheader">
                                Вся наша продукция произведена с использованием передовых технологий и отвечает самым высоким стандартам качества
                            </p>
                        </div>
                    </div>
                    <div class="img_card">
                        <div class="half_block">
                            <img class="half_block_image" src="@/assets/img/nd-ac/img_block4.png" alt="">
                        </div>
                        <div class="half_block">
                            <p class="half_block_header">Качество продукции</p>
                            <p class="half_block_subheader">
                                Вся наша продукция произведена с использованием передовых технологий и отвечает самым высоким стандартам качества
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container container-nd">
                <div class="dictionary_block">
                    <p class="dictionary_header">Направление деятельности</p>
                    <div class="dictionary_block_main">
                        <div class="left_block">
                            <ul class="dictionary_list">
                                <li>Сэндвич панели</li>
                                <li>Профлист</li>
                                <li>Металлочерепица</li>
                                <li>Фасадные системы</li>
                                <li>Профиль для ГКЛ</li>
                                <li>Крепежные элементы</li>
                                <li>Фасонные элементы</li>
                                <li>Быстровозводимые здания</li>
                            </ul>
                            <ul class="dictionary_list">
                                <li>Водосточная система</li>
                                <li>Металлопрокат</li>
                                <li>Сортовой и фасонный прокат</li>
                                <li>Ограждающие конструкции</li>
                                <li>Металлоконструкции</li>
                            </ul>
                        </div>
                        <div class="right_block">
                            <img class="dictionary_main_image" src="@/assets/img/nd-ac/dictionary_img.png" alt="">
                            <img class="dictionary_redpoint_big" src="@/assets/img/nd-ac/redpoint_big.png" alt="">
                            <img class="dictionary_redpoint_small" src="@/assets/img/nd-ac/redpoint_small.png" alt="">
                        </div>
                    </div>
                </div>
            </div>
            <div class="container container-nd">
                <div class="excursion_block">
                    <p class="excursion_block_header">3D-экскурсия по производству </p>
                    <div class="images">
                        <img src="@/assets/img/nd-ac/excursion_img.png" alt="">
                        <img class="icon_video_play" src="@/assets/img/vacancy/video_play_icon.png" alt="">
                    </div>
                </div>
            </div>
            
            <div class="container container-nd">
                <div class="history_block">
                    <p class="history_block_header">ИСТОРИЯ</p>
                    <div class="history_main_block">
                        <div class="left_block">
                            <p class="history_default_text">
                                Возрождение КЗЛМК началось в 2000-м, когда предприятие влилось в группу компаний
                            </p>
                            <p class="history_default_text">
                                «Маяк», имеющую десятилетний успешный опыт производства металлоконструкций в новых экономических условиях.
                            </p>
                            <p class="history_special_text">
                                В 2000-М БЫЛО ЗАРЕГИСТРИРОВАНО ООО
                                «КАНСКИЙ ЗАВОД ЛЕГКИХ МЕТАЛЛОКОН-СТРУКЦИЙ «МАЯК».
                            </p>
                        </div>
                        <div class="right_block">
                            <p class="history_year_number">2000</p>
                            <p class="history_year_text">год</p>
                        </div>
                    </div>
                    <div class="history_years_block">
                        <div class="arrow_left_block">
                            <img src="@/assets/img/nd-ac/history_arrow_left.png" alt="">
                        </div>
                        <div class="years">
                            <img class="year_line_img" src="@/assets/img/nd-ac/history_line_img.png" alt="">

                            <div class="years_list">
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">1979</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">1985</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">1986</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">1997</p>
                                </div>
                                <div class="single_year">
                                    <img class="red_eclipse" src="@/assets/img/nd-ac/history_red_eclipse.png" alt="">
                                    <p class="single_year_number single_year_number-active">2000</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">2009</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">2011</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">2013</p>
                                </div>
                                <div class="single_year">
                                    <img class="default_eclipse" src="@/assets/img/nd-ac/history_default_eclipse.png" alt="">
                                    <p class="single_year_number">2020</p>
                                </div>
                            </div>
                        </div>
                        <div class="arrow_right_block">
                            <img src="@/assets/img/nd-ac/history_arrow_right.png" alt="">
                        </div>
                    </div>
                </div>
            </div>

            <div class="container container-nd">
                <div class="comand_block">
                    <p class="comand_block_header">Команда</p>
                    <p class="comand_block_description">
                        В MayakMetall мы гордимся нашей командой профессионалов, которые с каждым днем вносят свой вклад в развитие компании. 
                        Мы объединяем людей с опытом и энтузиазмом, готовых решать самые сложные задачи и предоставлять клиентам лучшие решения.
                    </p>
                    <p class="comand_block_description">
                        Каждый член нашей команды - это истинный профессионал своего дела, обладающий глубокими знаниями и практическим опытом. 
                        Мы регулярно проводим обучение и совершенствуем навыки наших специалистов, чтобы они всегда были в курсе последних 
                        тенденций и технологий.
                    </p>
                    <p class="comand_block_description">
                        Наш коллектив отличается высокой мотивацией и командным духом. Мы работаем в тесной кооперации, что позволяет нам
                        достигать отличных результатов и решать задачи любой сложности.
                    </p>
                    <div class="images">
                        <img src="@/assets/img/nd-ac/comand_img1.png" alt="">
                        <img src="@/assets/img/nd-ac/comand_img2.png" alt="">
                    </div>
                </div>
            </div>

            <div class="container container-nd">
                <div class="news_block">
                    <div class="header_block">
                        <p class="news_header">НОВОСТИ И СТАТЬИ</p>
                        <button class="news_header_button">БОЛЬШЕ НОВОСТЕЙ</button>
                    </div>
                    <div class="news_list">
                        <div @click="push('nd-about-company/single')" class="single_news">
                            <img src="@/assets/img/nd-ac/news_img1.png" alt="">
                            <p class="single_news_data">06.09.2023</p>
                            <p class="single_news_name">Крупнейшая строительная выставка в Алматы, Казахстан.</p>
                        </div>
                        <div @click="push('nd-about-company/single')" class="single_news">
                            <img src="@/assets/img/nd-ac/news_img2.png" alt="">
                            <p class="single_news_data">11.05.2024</p>
                            <p class="single_news_name">Маяк Металл в Казахстане.</p>
                        </div>
                        <div @click="push('nd-about-company/single')" class="single_news">
                            <img src="@/assets/img/nd-ac/news_img3.png" alt="">
                            <p class="single_news_data">22.06.2024</p>
                            <p class="single_news_name">XXVI Годовой конгресс Российского союза поставщиков металлопродукции</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="container container-nd">
                <div class="slogan_block">
                    <p class="slogan_header">Сила в людях!</p>
                    <p class="slogan_subheader">Ждем вас в команде МаякМеталл!</p>

                    <button class="button button--primary" @click="openLink">Наши вакансии на hh.ru</button>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    components: {
        breadCrumbs,
    },
    data() {
        return {
            pages: [
                {id: 0, name: "О компании", url: "aboutcompany", fullUrl: 'aboutcompany'}
            ],
        }
    },
    methods: {
        openLink() {
            window.open('https://perm.hh.ru/search/vacancy?from=employerPage&employer_id=41770&hhtmFrom=employer', '_blank')
        },
    },
}
</script>