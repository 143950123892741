<template>
    <div class="section home_excursion_block">
        <div class="container container-nd">
            <div
                class="modal-iframe"
            >
                <!-- <iframe 
                    width="560" 
                    height="315" 
                    src="https://www.youtube.com/embed/9QI5--Y0X-U?si=hduanGSM3mYLHvKn" 
                    title="YouTube video player" 
                    frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowfullscreen>
                </iframe> -->

                <iframe 
                    src="https://vk.com/video_ext.php?oid=238807615&id=456239019&hd=2" 
                    width="853" 
                    height="480" 
                    allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" 
                    frameborder="0" 
                    allowfullscreen>
                </iframe>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {
            isShowVideo: false,
        }
    }
}
</script>