<template>
    <section class="about_company-info_page-sp_advantages">
        <div class="container container-nd">
            <p class="main_header">Преимущества</p>
            <div class="main_block">
                <div class="left_part">
                    <ul class="list-advantage">
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 1 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/4.png" alt="">
                            <p @click="setNumActiveAdvantage(1)">Превосходная прочность</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 2 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/2.png" alt="">
                            <p @click="setNumActiveAdvantage(2)">Отличные тепловые характеристики</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 3 }">
                            <div class="advantage_icon-div" />
                            <p @click="setNumActiveAdvantage(3)">Низкие эксплуатационные расходы</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 4 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/1.png" alt="">
                            <p @click="setNumActiveAdvantage(4)">Огнестойкость</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 5 }">
                            <div class="advantage_icon-div" />
                            <p @click="setNumActiveAdvantage(5)">Гашение вибрации</p> 
                        </li>
                        <li class="li-advantage" :class="{ '-active' : numActiveAdvantage == 6 }">
                            <img class="advantage_icon" src="@/img/info-sp/icons/8.png" alt="">
                            <p @click="setNumActiveAdvantage(6)">Простота монтажа</p> 
                        </li>
                    </ul>
                </div>
                <div class="right_part">
                    <img class="main_img" src="@/img/info-sp/advantage.png" alt="advantage main">
                    <div v-if="numActiveAdvantage != 0" class="text_block">
                        <p class="advantage_header mt-40">- Почему это так?</p>
                        <p>Сэндвич-панели имеют низкий коэффициент теплопроводности, который обеспечивает 
                            высокую теплоизоляцию.
                        </p>
                        <p class="advantage_header">- Что из этого следует?</p>
                        <p>Сэндвич-панели помогают сохранять тепло в здании, уменьшая энергопотребление в 
                            системах отопления и кондиционирования воздуха. Погода не влияет на 
                            температуру воздуха в здании.
                        </p>
                        <p class="advantage_header">- Какая от этого выгода?</p>
                        <p>
                            Более эффективное использование энергии и снижение затрат на отопление и 
                            кондиционирование воздуха. Постоянная комфортная температура, отсутствие 
                            перепадов температур и возникновения конденсата и плесени.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            numActiveAdvantage: 0,
        }
    },
    methods: {
        setNumActiveAdvantage(num) {
            if (num == this.numActiveAdvantage) {
                this.numActiveAdvantage = 0
            }else{
                this.numActiveAdvantage = num
            }
        },
    },
}
</script>