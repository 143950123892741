<template>
    <section>
        <div class="nd-about-company_page nd_single_news_page">
            <div class="container container-nd">
                <bread-crumbs class="breadcrumbs" :pages-array="pages" :withNdProp="false" />
            </div>
            <div class="container container-nd">
                <div class="single_main_block">
                    <img src="@/assets/img/nd-ac/single_news_img.png" alt="">

                    <p class="single_news_header">XXVI Годовой конгресс Российского союза поставщиков металлопродукции</p>
                    <p class="single_news_description">
                        22 июня в Самаре прошел XXVI Годовой конгресс Российского союза поставщиков металлопродукции и компания МаякМеталл, конечно же 
                        приняла в нем участие.
                    </p>
                    <p class="single_news_description">
                        Также в работе Конгресса приняли участие и приглашенные гости, а именно: Северсталь Дистрибуция, ТК Новосталь-М, Инокстрейд, и др.
                        В своих выступлениях, в ходе Конгресса, представители компаний подвели итоги работы за год, обсудили текущие вопросы деятельности в 
                        различных регионах и сегментах рынка металлов, наметили планы на предстоящий год.
                        От нашей компании с сообщением выступил Сергей Моров
                    </p>
                    <p class="single_news_description">
                        Вечером первого дня состоялась речная прогулка по Волге, где общение продолжилось в
                        неформальной обстановке.
                    </p>
                    <p class="single_news_description">
                        А 23 июня участники Конгресса посетили производственно-складской комплекс ОП Алексеевка,
                        где для них была проведена ознакомительная экскурсия.
                    </p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'

export default {
    components: {
        breadCrumbs,
    },
    data() {
        return {
            pages: [
                {id: 0, name: "О компании", url: "nd-about-company", fullUrl: 'nd-about-company'},
                {id: 10, name: "XXVI Годовой конгресс Российского союза поставщиков металлопродукции", url: "single", fullUrl: 'nd-about-company/single'},
            ],
        }
    },
}
</script>
<style lang="scss">
    .nd_single_news_page{
        .single_main_block{
            margin-top: 60px;
            margin-bottom: 250px;

            .single_news_header{
                margin-top: 77px;
                margin-bottom: 60px;
                font-weight: 700;
                font-size: 2.3rem;
                line-height: 2.8rem;  
                color: #353B48;              
            }

            .single_news_description{
                font-weight: 400;
                font-size: 1.8rem;
                line-height: 2.3rem;
                margin-bottom: 25px;
            }
        }
    }
</style>