<template>
    <div class="calculator-page-nd">
        <div class="section section--no-pt section--no-pb section--house">
            <div class="container container-nd calculator-nd">
                <div class="left_part">
                    <img class="main_img" src="@/img/nd-imgs/calculator.png" alt="">
                    <div class="block_for_first">
                        <div v-if="numActiveIcon == 1" class="icon_card">
                            <p>Кровельные системы</p>
                        </div>
                        <img @click="numActiveIcon = 1" src="@/img/nd-imgs/calc_1.png" alt="">
                    </div>
                    <div class="block_for_two">
                        <img @click="numActiveIcon = 2" src="@/img/nd-imgs/calc_2.png" alt="">
                        <div v-if="numActiveIcon == 2" class="icon_card">
                            <p>Водосточные системы</p>
                        </div>
                    </div>
                    <div class="block_for_three">
                        <img @click="numActiveIcon = 3" src="@/img/nd-imgs/calc_3.png" alt="">
                        <div v-if="numActiveIcon == 3" class="icon_card">
                            <p>Системы ограждений</p>
                        </div>
                    </div>
                </div>
                <div class="right_part">
                    <p class="main_header">Калькуляторы</p>
                    <p class="main_description">
                        Выберите нужный вам продукт: кровлю, фасад, водосточную и оградительную систему. 
                        После перейдите в интерактивный калькулятор, где сможете посчитать приблизительную стоимость.
                    </p>


                    <p v-if="numActiveIcon == 1" class="sub_header">Кровельная система</p>
                    <p v-if="numActiveIcon == 1" class="sub_description">
                        Кровля состоит из несущего слоя, который держится на несущей конструкции крыши (стропила), 
                        слоев изоляции и покрытия, охраняющего изоляцию от воздействия окружающей среды.
                    </p>

                    <p v-if="numActiveIcon == 2" class="sub_header">Водосточная система</p>
                    <p v-if="numActiveIcon == 2" class="sub_description">
                        Водосточная система производства Группы Компаний «Маяк» очень легка в монтаже, 
                        выдерживает большие нагрузки и обладает защитой от коррозии. Водосточная система не требует 
                        постоянного обслуживания и долговечна. Обеспечивая своевременный отвод воды, внешняя водосточная система кровли 
                        существенно увеличивает срок службы покрытия и фасада здания.
                    </p>

                    <p v-if="numActiveIcon == 3" class="sub_header">Система ограждений</p>
                    <p v-if="numActiveIcon == 3" class="sub_description">
                        Водосточная система производства Группы Компаний «Маяк» очень легка в монтаже, выдерживает большие 
                        нагрузки и обладает защитой от коррозии. Водосточная система не требует постоянного обслуживания и 
                        долговечна. Обеспечивая своевременный отвод воды, внешняя водосточная система кровли существенно 
                        увеличивает срок службы покрытия и фасада здания.
                    </p>

                    <button 
                        @click="pushToCalc()"
                        class="button button--primary"
                    >
                        <p v-if="numActiveIcon == 1">Рассчитать кровлю</p>
                        <p v-if="numActiveIcon == 2">Рассчитать водосток</p>
                        <p v-if="numActiveIcon == 3">Рассчитать забор</p>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    data() {
        return {
            numActiveIcon: 1,
        }
    },
    methods: {
        pushToCalc() {
            if (this.numActiveIcon == 1) {
                this.push('calculators/roof')
            } else if (this.numActiveIcon == 2) {
                this.push('calculators/drainage')
            } else if (this.numActiveIcon == 3) {
                this.push('calculators/fencing')
            }
        },
    },
}
</script>