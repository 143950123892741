<template>
    <div class="section about_company_page-nd">
        <div class="container container-nd">
            <div class="company-nd">
                <div class="left_part">
                    <p v-if="main.aboutCompany" class="header">{{ main.aboutCompany.header }}</p>
                    <div v-if="main.aboutCompany" class="subheader" v-html="main.aboutCompany.description"></div>

                    <!-- результат от сотрудничества с нами.
                    Компания «МаякМеталл» не только изготавливает профлист сэндвич-панели и другую продукцию, но и выполняет проектирование 
                    зданий и сооружений с применением данных материалов.
                    Наша миссия заключается в том, что бы предоставить заказчику комплексный подход в решении его задач, взяв на себя ответственность на 
                    всех этапах реализации проекта.
                    Используя передовые технологии и конструктивные решения, мы помогаем клиентам ощутимо снизить затраты на строительство. -->
    
                    <button class="button" @click="push('aboutcompany')">Подробнее о компании</button>
                </div>
                <div class="right_part">
                    <img src="@/img/nd-imgs/company_2.png" alt="">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            main: 'app/main',
        }),
  },
}
</script>