<template>
  <main class="main">
        <div class="section section--breadcrumbs">
          <div class="container">
            <div class="inner">
              <ul class="list list--flex breadcrumbs">
                <li class="list-item"><a class="list-link" href="#">
                    <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                      <use xlink:href="@/assets/img/icons.svg#home"></use>
                    </svg></a></li>
                <li class="list-item"><a class="list-link" href="#">Каталог</a></li>
                <li class="list-item"><a class="list-link" href="#">Кровля</a></li>
                <li class="list-item"><span class="list-current">Металлочерепица</span></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="section section--no-pt">
          <div class="container">
            <div class="inner">
              <div class="heading">
                <div class="heading-section">
                  <h2>Металлочерепица</h2>
                </div>
              </div>
            </div>
            <div class="layout layout--catalog">
              <div class="layout-aside">
                <div class="layout-group">
                  <div class="menu">
                    <div class="menu-toggle">
                      <div class="menu-toggle-section"><span>Каталог товаров</span><span>Свернуть каталог</span></div>
                      <button class="menu-toggle-button"></button>
                    </div>
                    <div class="menu-title">Продукция</div>
                    <ul class="list menu-list">
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Кровельные системы</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Металочерепица</a></li>
                          <li class="list-item"><a class="list-link" href="#">Профлист для кровли</a></li>
                          <li class="list-item"><a class="list-link" href="#">Водосточные системы</a></li>
                          <li class="list-item"><a class="list-link" href="#">Элементы безопасности кровли</a></li>
                          <li class="list-item"><a class="list-link" href="#">Кровельная вентиляция</a></li>
                          <li class="list-item"><a class="list-link" href="#">Мансардные окна</a></li>
                          <li class="list-item"><a class="list-link" href="#">Элементы отделочные кровли</a></li>
                        </ul>
                      </li>
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Фасадные системы</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Металлический сайдинг</a></li>
                          <li class="list-item"><a class="list-link" href="#">Фасадная панель</a></li>
                          <li class="list-item"><a class="list-link" href="#">Стальная рейка</a></li>
                          <li class="list-item"><a class="list-link" href="#">Профлист стеновой</a></li>
                          <li class="list-item"><a class="list-link" href="#">Подсистема для вентилируемых фасадов</a></li>
                          <li class="list-item"><a class="list-link" href="#">Отделочные окна</a></li>
                          <li class="list-item"><a class="list-link" href="#">Изоляционные материалы для фасада</a></li>
                        </ul>
                      </li>
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Системы ограждений</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Панельные ограждения</a></li>
                          <li class="list-item"><a class="list-link" href="#">Модульные ограждения</a></li>
                          <li class="list-item"><a class="list-link" href="#">Профилированный лист для заборов и ограждений</a></li>
                          <li class="list-item"><a class="list-link" href="#">Штакетник металлический</a></li>
                          <li class="list-item"><a class="list-link" href="#">Временные ограждения</a></li>
                          <li class="list-item"><a class="list-link" href="#">Габион</a></li>
                          <li class="list-item"><a class="list-link" href="#">Рулонная сетка</a></li>
                          <li class="list-item"><a class="list-link" href="#">Парапетные крыши</a></li>
                          <li class="list-item"><a class="list-link" href="#">Вертикальные опоры</a></li>
                          <li class="list-item"><a class="list-link" href="#">Горизонтальные прожилины</a></li>
                          <li class="list-item"><a class="list-link" href="#">Крепеж для забора</a></li>
                        </ul>
                      </li>
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Сопутствующие товары</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Кровельные инструменты</a></li>
                          <li class="list-item"><a class="list-link" href="#">Гидроизоляционные и пароизоляционные мембраны для фасадов и кровли</a></li>
                          <li class="list-item"><a class="list-link" href="#">Проходки кровли</a></li>
                          <li class="list-item"><a class="list-link" href="#">Краска кровельная</a></li>
                          <li class="list-item"><a class="list-link" href="#">Герметики</a></li>
                          <li class="list-item"><a class="list-link" href="#">Уплотнители</a></li>
                          <li class="list-item"><a class="list-link" href="#">Утеплители</a></li>
                          <li class="list-item"><a class="list-link" href="#">Крепежные элементы</a></li>
                          <li class="list-item"><a class="list-link" href="#">Инструменты</a></li>
                        </ul>
                      </li>
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Сэндвич-панели</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Стеновые сэндвич-панели</a></li>
                          <li class="list-item"><a class="list-link" href="#">Кровельные сэндвич-панели</a></li>
                          <li class="list-item"><a class="list-link" href="#">Отделочные элементы</a></li>
                          <li class="list-item"><a class="list-link" href="#">Доборные элементы для сэндвич-панелей</a></li>
                          <li class="list-item"><a class="list-link" href="#">Крепеж для сэндвич-панелей</a></li>
                        </ul>
                      </li>
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Металлопрокат</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Гладкий лист</a></li>
                          <li class="list-item"><a class="list-link" href="#">Штрипс</a></li>
                          <li class="list-item"><a class="list-link" href="#">Рулонная сталь</a></li>
                        </ul>
                      </li>
                      <li class="list-item -opened"><a class="list-link" href="#"><span>Прочее</span>
                          <div class="list-button"></div></a>
                        <ul class="list sub">
                          <li class="list-item"><a class="list-link" href="#">Сортовой и фасонный прокат</a></li>
                          <li class="list-item"><a class="list-link" href="#">Профили для монтажа гипсокартона</a></li>
                          <li class="list-item"><a class="list-link" href="#">Внутренняя отделка</a></li>
                          <li class="list-item"><a class="list-link" href="#">Быстровозводимые здания</a></li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="layout-group">
                  <div class="message">
                    <h4 class="message-title">Калькулятор кровли</h4>
                    <p class="message-description">Специальный сервис позволит самостоятельно рассчитать количество и стоимость материалов</p><a class="message-button button button--primary" href="#">Перейти в конструктор</a>
                    <div class="note message-note"><span class="note-required">*</span>Точную стоимость проекта узнавайте у наших менеджеров по телефону:</div><a class="message-phone" href="tel:#">8 800 770 01 98</a>
                  </div>
                  <!-- message-->
                </div>
              </div>
              <div class="layout-section">
                <div class="section section--no-pt">
                  <div class="catalog-controls">
                    <div class="catalog-controls-inner">
                      <div class="catalog-control">
                        <div class="catalog-control-label">Выберите тип кровли:</div>
                        <div class="catalog-control-values">
                          <select data-select-float>
                            <option value="">Monterrey</option>
                            <option value="">Monterrey</option>
                            <option value="">Monterrey</option>
                          </select>
                        </div>
                      </div>
                      <div class="catalog-control">
                        <div class="catalog-control-label">Сортировать по:</div>
                        <div class="catalog-control-values">
                          <select data-select-float>
                            <option value="">Названию (А -> Я)</option>
                            <option value="">Названию (А -> Я)</option>
                            <option value="">Названию (А -> Я)</option>
                          </select>
                        </div>
                      </div>
                      <div class="catalog-control">
                        <div class="catalog-control-label">Показывать по:</div>
                        <div class="catalog-control-values">
                          <select data-select-float>
                            <option value="">12</option>
                            <option value="">24</option>
                            <option value="">36</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ul class="list list--flex layout-products -col-3">
                    <li class="list-item" v-for="product in products" :key="product.id">
                      <div class="product">
                        <div class="product-header">
                          <div class="product-label">Акция</div>
                          <div class="product-image"><a class="product-link" href="#"><img src="@/assets/img/product.svg" alt=""/></a></div>
                        </div>
                        <div class="product-body">
                          <div class="product-title"><a class="product-link" href="#">{{ product.name }}</a></div>
                          <div class="product-price">от 404 руб/м2</div>
                        </div>
                        <div class="product-footer">
                          <button class="button button--primary button--cart">
                            <svg class="icon" width="25" height="25" viewBox="0 0 25 25">
                              <use xlink:href="@/assets/img/icons.svg#cart"></use>
                            </svg>В корзину
                          </button>
                        </div>
                      </div>
                    </li>
                   
                  </ul>
                  <ul class="list list--flex pagination">
                    <li class="list-item -prev"><a class="list-link" href="#">
                        <svg class="icon" width="16" height="12" viewBox="0 0 16 12">
                          <use xlink:href="@/assets/img/icons.svg#arrow-left"></use>
                        </svg></a></li>
                    <li class="list-item -active"><a class="list-link" href="#">1</a></li>
                    <li class="list-item"><a class="list-link" href="#">2</a></li>
                    <li class="list-item"><a class="list-link" href="#">3</a></li>
                    <li class="list-item"><a class="list-link" href="#">4</a></li>
                    <li class="list-item"><a class="list-link" href="#">5</a></li>
                    <li class="list-item -next"><a class="list-link" href="#">
                        <svg class="icon" width="16" height="12" viewBox="0 0 16 12">
                          <use xlink:href="@/assets/img/icons.svg#arrow-right"></use>
                        </svg></a></li>
                  </ul>
                </div>
                <!-- section-->
                <div class="section section--no-pt">
                  <div class="inner">
                    <div class="download">
                      <div class="download-title">
                        <h3>Инструкции</h3>
                      </div>
                      <div class="download-section">
                        <div class="download-image"><img src="@/assets/img/download.png" alt=""></div>
                        <div class="download-content">
                          <p>Скачать каталог<br> <b>«Кровельная система»</b><br> в формате PDF</p><a class="button button--primary" href="#">Скачать</a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- section-->
                <div class="section section--no-pt">
                  <div class="inner">
                    <div class="heading">
                      <div class="heading-section">
                        <h3>Наши<br> преимущества</h3>
                      </div>
                    </div>
                  </div>
                  <div class="list-offset -col-1">
                    <ul class="list list--flex layout-advantages -col-3">
                      <li class="list-item">
                        <div class="advantage">
                          <div class="advantage-icon"><img src="@/assets/img/advantages/1.svg" alt=""></div>
                          <div class="advantage-title">Собственное производство</div>
                          <div class="advantage-description">Группа компаний «Маякметалл» уже более 25 лет является ведущим российским производителем и поставщиком строительных металлоконструкций, металлопроката и крепежных изделий на территории России и стран СНГ.</div>
                        </div>
                      </li>
                      <li class="list-item">
                        <div class="advantage">
                          <div class="advantage-icon"><img src="@/assets/img/advantages/2.svg" alt=""></div>
                          <div class="advantage-title">Гарантия и контроль качества</div>
                          <div class="advantage-description">Мы уделяем максимум внимания качеству сырья и выпускаемой продукции, совершенствованию сервисного обслуживания клиентов и усилению конкурентных преимуществ компании в целом. Все это позволяет предоставить нашим клиентам продукцию высшего качества с длительными гарантийными обязательствами.</div>
                        </div>
                      </li>
                      <li class="list-item">
                        <div class="advantage">
                          <div class="advantage-icon"><img src="@/assets/img/advantages/3.svg" alt=""></div>
                          <div class="advantage-title">Доставка от производства</div>
                          <div class="advantage-description">Продавец с согласия  Покупателя может организовать доставку путем привлечения услуг транспортной организации. Стоимость доставки не входит в стоимость Товара. Сумма доставки рассчитывается с учетом одного адреса доставки.</div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- section-->
                <div class="section section--no-pt">
                  <div class="inner">
                    <div class="heading">
                      <div class="heading-section">
                        <h3>Стоимость</h3>
                      </div>
                    </div>
                    <div class="table">
                      <div class="table-head">
                        <div class="table-row">
                          <div class="table-cell">Название</div>
                          <div class="table-cell">Цена за м2, руб.</div>
                          <div class="table-cell">Сроки службы</div>
                        </div>
                      </div>
                      <div class="table-body">
                        <div class="table-row">
                          <div class="table-cell table-cell--th" data-label="Название">Эконом металлочерепица</div>
                          <div class="table-cell" data-label="Цена за м2, руб.">от 255 руб за м2</div>
                          <div class="table-cell" data-label="Сроки службы">от 1 до 5 лет</div>
                        </div>
                        <div class="table-row">
                          <div class="table-cell table-cell--th" data-label="Название">Бизнес металлочерепица</div>
                          <div class="table-cell" data-label="Цена за м2, руб.">от 370 руб за м2</div>
                          <div class="table-cell" data-label="Сроки службы">от 10 до 20 лет</div>
                        </div>
                        <div class="table-row">
                          <div class="table-cell table-cell--th" data-label="Название">Премиум металлочерепица</div>
                          <div class="table-cell" data-label="Цена за м2, руб.">от 490 руб за м2</div>
                          <div class="table-cell" data-label="Сроки службы">от 20 до 40 лет</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- section-->
                <div class="section section--no-pt">
                  <div class="inner">
                    <div class="heading">
                      <div class="heading-section">
                        <h3>Наши объекты</h3>
                      </div>
                    </div>
                  </div>
                  <ul class="list list--flex layout-objects">
                    <li class="list-item"><a class="object" href="#">
                        <div class="object-image"><img src="@/assets/img/objects/1.jpg" alt=""/></div>
                        <div class="object-title">Здание склада<br> п.Новосемейкино</div></a>
                    </li>
                    <li class="list-item"><a class="object" href="#">
                        <div class="object-image"><img src="@/assets/img/objects/2.jpg" alt=""/></div>
                        <div class="object-title">Паровая котельная<br> г.Аксай</div></a>
                    </li>
                  </ul>
                </div>
                <!-- section-->
                <div class="section section--no-pt section--no-pb">
                  <div class="inner">
                    <div class="heading">
                      <div class="heading-section">
                        <h3>Купить<br> металлочерепицу<br> в Самаре</h3>
                      </div>
                    </div>
                  </div>
                  <div class="layout-content">
                    <p>Металлочерепица - это кровельный материал, который является на сегодняшний день, пожалуй, самым популярным среди строителей-профессионалов и частных застройщиков.</p>
                    <p>Металлочерепица, производимая группой компаний «Маяк», выпускается в виде листов фиксированной длины, либо произвольной длины по индивидуальным размерам кровли заказчика, изготавливается из рулонной окрашенной стали методом проката с использованием высокотехнологичного оборудования, которое создает на стальном листе профиль, имитирующий сложенную рядами натуральную черепицу.</p>
                  </div>
                </div>
                <!-- section-->
              </div>
            </div>
          </div>
        </div>
        <!-- section-->
      </main>
</template>
<script>
import Vuex from 'vuex'

export default {
    computed: {
        ...Vuex.mapGetters({
            products: 'catalog/products',
        }),
    }
}
</script>