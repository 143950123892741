<template>
    <div class="section section--no-pt top_level_category_block">
        <div class="container container-nd">
            <ul class="list list--flex layout-cards -col-3">
                <!-- uiCategories -->
                <!-- secondLevelCategory -->
                <li 
                    class="list-item" 
                    v-for="product in secondLevelCategory" 
                    :key="product.id" 
                    @click="pushToNextCategory(product.id_1c)"
                >
                    <a 
                        class="card-catalog-nd"
                    >
                        <div class="card-heading">
                            <div class="card-icon">
                                <img 
                                    v-if="imagesCategories[product.id_1c]"
                                    class="product-preview-img"
                                    :src="imagesCategories[product.id_1c]" 
                                    alt=""
                                >
                                <img 
                                    v-else
                                    class="product-preview-img"
                                    :src="yandexCloud + product.preview" 
                                    style="width: 90px;"
                                    alt=""
                                >
                            </div>
                            <div class="card-title">{{ product.name.toUpperCase() }}</div>
                            <ul class="card-list">
                                <!-- @click="push('catalog/' + cardLi.id_1c)" -->
                                <li 
                                    class="card-li"
                                    v-tooltip="cardLi.product_description"
                                    v-for="cardLi in listOfParent(product.id_1c).slice(0, 6)"
                                    :key="cardLi.id"
                                    @mouseenter="isCanClickCard = false"
                                    @mouseleave="isCanClickCard = true"
                                    @click="push('catalog/' + cardLi.id_1c)"
                                >
                                    {{ cardLi.name }}
                                </li>
                            </ul>
                            <div class="link_more">
                                <div @click="push('catalog/' + product.id_1c)" class="local_block">
                                    <p>Показать ещё</p>
                                    <img src="@/img/info-sp/icons/more.png" alt="">
                                </div>
                            </div>
                        </div>
                    </a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Vue from 'vue'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

export default {
    mixins: [Functions],
    data() {
        return {
            arrayAllChildrens: [],
            isCanClickCard: true,
        }
    },
    watch: {
        region() {
            this.search()
        },
    },
    computed: {
        ...Vuex.mapGetters({
            uiCategories: 'ui_category/categories',
            region: 'auth/city',
            yandexCloud: 'app/yandexCloud',
            secondLevelCategory: 'dictionary/secondLevelCategory',
        }),
        imagesCategories() {
            let obj = {}
            this.uiCategories.map((element) => {
                obj[element.id_1c] = element.link
            })
            return obj
        },
    },
    mounted() {
        this.search()
    },
    methods: {
        ...Vuex.mapActions({
            searchNavbar: 'catalog/searchNavbar',
        }),
        ...Vuex.mapMutations({
            setProducts: 'catalog/setProducts',
        }),
        findDescription(product) {
            console.log(product)
        },
        pushToNextCategory(id) {
            // console.log(this.isCanClickCard)
            if (this.isCanClickCard == false) {
                return
            }
            this.setProducts([])
            this.push('catalog/' + id)
        },
        listOfParent(id_1c) {
            let list = []

            this.arrayAllChildrens.map((element) => {
                if (element.parent_id == id_1c) {
                    list.push(element)
                }
            })

            return list
        },
        getProducts(product) {
            if (product.is_group && !product.is_sub_sign) {
                this.push('catalog/' + product.id_1c)   
            }else{
                this.push('cardProduct/' + product.id)
            }
        },
        search() {
            let parentIds = []

            this.secondLevelCategory.map((element) => {
                parentIds.push(element.id_1c)
            })


            this.searchNavbar({
                parent_ids: parentIds,
                root_id: this.region,
                is_deleted: false,
            }).then((response) => {
                this.arrayAllChildrens = response.data
            })
        },
        pushToNextLevel(id) {
            setTimeout(() => {
                this.push('catalog/' + id)
            }, 5000)
        },
    },
}
</script>
<style lang="scss">
    .vue-tooltip-theme{
        background-color: #000000 !important;
        color: #ffffff !important;
        border-radius: 15px;
        max-width: 500px !important;
        max-height: 240px !important;
        overflow: hidden;
    }
</style>