import Vue from 'vue'
import Router from 'vue-router'
import store from "../store/index"
import multiguard from "vue-router-multiguard"
import Home from '@/components/pages/home'
import Login from '@/components/pages/login'

import nlk from '@/components/pages/nlk/nlk.vue'
import lk from '@/components/pages/lk/lk.vue'

// import Registration from '@/components/pages/registration'
import RegistrationNew from '@/components/pages/registration/new.vue'
import AdminMain from '@/components/admin/pages/main/main.vue'
import AdminCompany from '@/components/admin/pages/company/company.vue'
import AdminUserCompanies from '@/components/admin/pages/userCompanies/userCompanies.vue'
import AdminContacts from '@/components/admin/pages/contacts/contacts.vue'
import AdminDeliverys from '@/components/admin/pages/deliverys/deliverys.vue'
import AdminDocuments from '@/components/admin/pages/documents/documents.vue'
import AdminOffers from '@/components/admin/pages/offers/offers.vue'
import Documents from '@/components/pages/documents/documents.vue'
import Offers from '@/components/pages/offers/new-offers.vue'
import AboutCompany from '@/components/pages/aboutCompany/aboutCompany.vue'
import Contacts from '@/components/pages/contacts/contacts.vue'
import AdminSupport from '@/components/admin/pages/support/support.vue'
import Support from '@/components/pages/support/support.vue'
import Faq from '@/components/pages/support/faq.vue'
import Technicals from '@/components/pages/support/technicals.vue'
import AdminOurObjects from '@/components/admin/pages/ourObjects/ourObjects.vue'
import OneOffer from '@/components/pages/offers/oneOffer.vue'
import AdminBecomePartner from '@/components/admin/pages/becomePartner/becomePartner.vue'
import Privacy from '@/components/privacy/privacy.vue'
import Objects from '@/components/pages/objects/index.vue'
import OneObject from '@/components/pages/objects/indexObj.vue'
import BecomePartner from '@/components/pages/becomePartner/becomePartner.vue'
import AdminServises from '@/components/admin/pages/servises/servises.vue'
import Services from '@/components/pages/services/services.vue'
import AdminLegals from '@/components/admin/pages/legals/legals.vue'
import Legals from '@/components/pages/legals/legals.vue'
import AdminBasketReport from '@/components/admin/pages/basketReport/basketReport.vue'
import AdminProfile from '@/components/admin/pages/profile/profile.vue'
import AdminSettings from '@/components/admin/pages/settings/settings.vue'
import AdminCardProduct from '@/components/admin/pages/cardproduct/cardproduct.vue'
import AdminWidget from '@/components/admin/pages/widgetCode/widget-code.vue'
import AdminLogs from '@/components/admin/pages/logs'
import AdminBanners from '@/components/admin/pages/banners/banners.vue'
import AdminUtm from '@/components/admin/pages/utm/utm.vue'
import Catalog from '@/components/pages/catalog/index.vue'
import Basket from '@/components/pages/basket/basket.vue'
import Checkout from '@/components/pages/checkout/checkout.vue'
import checkoutFinish from '@/components/pages/checkout/finish.vue'
import cardProduct from '@/components/pages/catalog/cardProduct.vue'
import CatalogProducts from '@/components/pages/catalog/products.vue'
import calculatorRoof from '@/components/pages/calculators/calculatorRoof.vue'
import calculatorDrainage from '@/components/pages/calculators/calculatorDrainage.vue'
import calculatorFencing from '@/components/pages/calculators/calculatorFencing.vue'
import search from '@/components/pages/catalog/search.vue'
import metalloconstructions from '@/components/pages/metalloconstructions/metalloconstructions.vue'
import building from '@/components/pages/building/building.vue'
import permissions from '@/components/admin/pages/permissions/permissions.vue'
// import pageNotFound from '@/components/pages/page-not-found.vue'
import payDelivery from '@/components/pages/payDelivery/pay-delivery.vue'
import clients from '@/components/pages/clients/clients.vue'
import forCompanies from '@/components/pages/forCompanies/forCompanies.vue'
import finishing from '@/components/pages/finishing/finishing.vue'
import recovery from '@/components/pages/recovery/recovery'
import activate from '@/components/pages/activate/activate.vue'
import users from '@/components/admin/pages/users/users.vue'
import AdminCalculators from '@/components/admin/pages/calculators/calculators'
import testSkat from '@/components/pages/testSkat/testSkat.vue'
import AboutCompanyInfoSp from '@/components/pages/aboutCompany/info/sp/sp.vue'
import AboutCompanyInfoSpEvosteel from '@/components/pages/aboutCompany/info/sp-evosteel/sp-evosteel.vue'
import AboutCompanyInfoProflist from '@/components/pages/aboutCompany/info/proflist/proflist.vue'

import Coatings from '@/components/pages/coatings/coatings.vue'
import SingleCoating from '@/components/pages/coatings/single.vue'

import AdminCoatings from '@/components/admin/pages/coatings/coatings.vue'
import AdminCoatingsColors from '@/components/admin/pages/coatings/colors.vue'
import AdminCoatingsChars from '@/components/admin/pages/coatings/characteristics.vue'
import AdminCoatingMain from '@/components/admin/pages/coatings/main.vue'

import AdminVacancy from '@/components/admin/pages/vacancy/vacancy.vue'
import Vacancy from '@/components/pages/vacancy/vacancy.vue'
import SingleVacancy from '@/components/pages/vacancy/single_page.vue'
import Vacancies from '@/components/pages/vacancy/vacancies.vue'
import NdAboutCompany from '@/components/pages/aboutCompany/nd/index.vue'
import NdAboutCompanySingle from '@/components/pages/aboutCompany/nd/single.vue'

Vue.use(Router)

const check = (to, from, next) => {
  store.dispatch("auth/check")
    .then(() => {
      next()
    })
    .catch(() => {
      next()
    })
}

const checkLK = (to, from, next) => {
  store.dispatch("auth/check")
    .then(() => {
      next()
    })
    .catch(() => {
      next('/')
    })
}

const checkAdmin = (to, from, next) => {
  store.dispatch("auth/check")
    .then((response) => {
      let roles = response.data.roles
      let can = false
      if (roles) {
        roles.map(element => {
          if (element == 'admin') {
            can = true
          }
        })
      }
      if (can) {
        next()
      }else{
        next('/')
      }
    })
    .catch(() => {
      next('/')
    })
}

const setAdminLayout = (to, from, next) => {
  store.commit('app/setLayout', 'admin')
  next()
}

const setMainLayout = (to, from, next) => {
  store.commit('app/setLayout', 'main')
  next()
}

// const setVacancyLayout = (to, from, next) => {
//   store.commit('app/setLayout', 'vacancy')
//   next()
// }

export default new Router({
  mode: "history",
  routes: [
      {
        path: "/",
        name: "Home",
        component: Home,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/vacancy",
        name: "Vacancy",
        component: Vacancy,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/vacancies",
        name: "Vacancies",
        component: Vacancies,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/vacancy/:id",
        name: "SingleVacancy",
        component: SingleVacancy,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/nd-about-company",
        name: "NdAboutCompany",
        component: NdAboutCompany,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/nd-about-company/single",
        name: "NdAboutCompanySingle",
        component: NdAboutCompanySingle,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/coatings",
        name: "Coatings",
        component: Coatings,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/coatings/:id",
        name: "SingleCoating",
        component: SingleCoating,
        beforeEnter: multiguard([check, setMainLayout])
      },
      {
        path: "/testSkat",
        name: "testSkat",
        component: testSkat,
        beforeEnter: multiguard([setMainLayout])
      },
      {
        path: "/login",
        name: "Login",
        component: Login,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/lk",
        name: "lk",
        component: lk,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/lk/:key",
        name: "lk",
        component: lk,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/nlk",
        name: "nlk",
        component: nlk,
        beforeEnter: multiguard([setMainLayout, checkLK])
      },
      {
        path: "/nlk/:key",
        name: "nlk",
        component: nlk,
        beforeEnter: multiguard([setMainLayout, checkLK])
      },
      {
        path: "/registration",
        name: "Registration",
        component: RegistrationNew,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/recovery",
        name: "Recovery",
        component: recovery,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/activate/:key",
        name: "Activate",
        component: activate,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/admin/index",
        name: "AdminMain",
        component: AdminMain,
        beforeEnter: multiguard([checkAdmin, setAdminLayout])
      },
      {
        path: "/admin/widget",
        name: "AdminWidget",
        component: AdminWidget,
        beforeEnter: multiguard([checkAdmin, setAdminLayout])
      },
      {
        path: "/admin/delivery",
        name: "AdminDeliverys",
        component: AdminDeliverys,
        beforeEnter: multiguard([checkAdmin, setAdminLayout])
      },
      {
        path: "/admin/utm",
        name: "AdminMain",
        component: AdminUtm,
        beforeEnter: multiguard([checkAdmin, setAdminLayout])
      },
      {
        path: "/admin/company",
        name: "AdminCompany",
        component: AdminCompany,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/companies",
        name: "AdminUserCompanies",
        component: AdminUserCompanies,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/vacancy",
        name: "AdminVacancy",
        component: AdminVacancy,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/contacts",
        name: "AdminContacts",
        component: AdminContacts,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/documents",
        name: "AdminDocuments",
        component: AdminDocuments,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/offers",
        name: "AdminOffers",
        component: AdminOffers,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/banners",
        name: "AdminBanners",
        component: AdminBanners,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/support",
        name: "AdminSupport",
        component: AdminSupport,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/ourobjects",
        name: "AdminOurObjects",
        component: AdminOurObjects,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/permissions",
        name: "Permissions",
        component: permissions,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/users",
        name: "Users",
        component: users,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/calculators",
        name: "AdminCalculators",
        component: AdminCalculators,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/documents",
        name: "Documents",
        component: Documents,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/clients",
        name: "Clients",
        component: clients,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/forcompanies",
        name: "forCompanies",
        component: forCompanies,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/finishing",
        name: "Finishing",
        component: finishing,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/documents/:key",
        name: "Documents",
        component: Documents,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/offers",
        name: "Offers",
        component: Offers,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/offers/:key",
        name: "OneOffer",
        component: OneOffer,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/aboutcompany",
        name: "NdAboutCompany",
        component: AboutCompany,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/aboutcompany/info/sp",
        name: "AboutCompanyInfoSp",
        component: AboutCompanyInfoSp,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/aboutcompany/info/sp-evosteel",
        name: "AboutCompanyInfoSpEvosteel",
        component: AboutCompanyInfoSpEvosteel,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/aboutcompany/info/proflist",
        name: "AboutCompanyInfoProflist",
        component: AboutCompanyInfoProflist,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/contacts",
        name: "Contacts",
        component: Contacts,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/support",
        name: "Support",
        component: Support,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/support/technicals",
        name: "Technicals",
        component: Technicals,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/support/faq",
        name: "Faq",
        component: Faq,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/admin/becomepartner",
        name: "AdminBecomePartner",
        component: AdminBecomePartner,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/privacy",
        name: "Privacy",
        component: Privacy,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/partner",
        name: "BecomePartner",
        component: BecomePartner,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/objects/",
        name: "Objects",
        component: Objects,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/objects/:cat",
        name: "Objects",
        component: Objects,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/objects/:cat/:key",
        name: "OneObject",
        component: OneObject,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/admin/servises",
        name: "AdminServises",
        component: AdminServises,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/services",
        name: "Services",
        component: Services,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/admin/legals",
        name: "AdminLegals",
        component: AdminLegals,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/legals",
        name: "Legals",
        component: Legals,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/admin/basketreport",
        name: "AdminBasketReport",
        component: AdminBasketReport,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/profile",
        name: "AdminProfile",
        component: AdminProfile,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/settings",
        name: "AdminSettings",
        component: AdminSettings,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/cardproduct",
        name: "AdminCardProduct",
        component: AdminCardProduct,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
          path: "/admin/logs",
          name: "AdminLogs",
          component: AdminLogs,
          beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/catalog",
        name: "Catalog",
        component: Catalog,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/catalog/:id_1c_category",
        name: "Catalog",
        component: Catalog,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/calculators/roof",
        name: "calculatorRoof",
        component: calculatorRoof,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/calculators/drainage",
        name: "calculatorDrainage",
        component: calculatorDrainage,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/calculators/fencing",
        name: "calculatorFencing",
        component: calculatorFencing,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/basket",
        name: "Basket",
        component: Basket,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/basket/checkout",
        name: "Checkout",
        component: Checkout,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/basket/checkout/:key",
        name: "Checkout",
        component: Checkout,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/basket/checkout_finish",
        name: "CheckoutFinish",
        component: checkoutFinish,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/cardProduct/:id",
        name: "cardProduct",
        component: cardProduct,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/catalog/products",
        name: "CatalogProducts",
        component: CatalogProducts,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/search",
        name: "Search",
        component: search,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/paydelivery",
        name: "PayDelivery",
        component: payDelivery,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/metalloconstructions",
        name: "Metalloconstructions",
        component: metalloconstructions,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/building",
        name: "Building",
        component: building,
        beforeEnter: multiguard([setMainLayout, check])
      },
      {
        path: "/admin/coatings",
        name: "AdminCoatings",
        component: AdminCoatings,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/coatings/colors",
        name: "AdminCoatingsColors",
        component: AdminCoatingsColors,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/coatings/characteristics",
        name: "AdminCoatingsChars",
        component: AdminCoatingsChars,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
      {
        path: "/admin/coatings/main",
        name: "AdminCoatingMain",
        component: AdminCoatingMain,
        beforeEnter: multiguard([setAdminLayout, checkAdmin])
      },
  ],
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})