<template>
    <div class="section home_objects_block">
        <div class="container container-nd">
            <p class="header">Галерея наших объектов</p>
            <div class="objects_imgs">
                <img 
                    v-for="one_object, idx in objects.slice(0, 6)"
                    :key="idx"
                    :src="one_object.mainImg"
                >
            </div>
            <button class="button" @click="push('objects')">Посмотреть больше</button>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'

export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            our_objects: 'app/our_objects',
        }),
        objects(){
            var dict =[]
            
            if (this.our_objects.categories) {
                this.our_objects.categories.map((el)=>{
                    if(el != null){
                        el.objects.map((ell)=>{
                            dict.push(ell)
                        })
                    }
                })
            }

            
            return dict
        }
    },
}
</script>