<template>
    <section class="about_company-info_page">
        <banner-block />
        <bread-crumbs :pages-array="pagesAboutCompany" />
        <advantage-block />
        <type-profiles-block />
        <form-call-back />
        <popular-products :title="'Каталог'" />
        <calculatorBlock />
        <clients-block />
        <excursion-block />
        <map-block />
    </section>
</template>
<script>
import Vuex from 'vuex'

import bannerBlock from './banner.vue'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import advantageBlock from './advantages.vue'
import formCallBack from '@/components/form-callback.vue'
import typeProfilesBlock from './type-profiles.vue'
import popularProducts from '@/components/pages/home/popular-products/popular-products.vue'
import calculatorBlock from '@/components/pages/home/calculators/new-calculator.vue'
import clientsBlock from '@/components/pages/home/brands/brands.vue'
import excursionBlock from '@/components/pages/home/excursion/excursion.vue'
import mapBlock from './map.vue'

export default {
    components: {
        bannerBlock,
        breadCrumbs,
        advantageBlock,
        formCallBack,
        typeProfilesBlock,
        popularProducts,
        calculatorBlock,
        clientsBlock,
        excursionBlock,
        mapBlock,
    },
    computed: {
        ...Vuex.mapGetters({
            pagesAboutCompany: 'dictionary/pagesAboutCompany',
        }),
    },
}
</script>