<template>
    <section class="about_company-info_page-sp_banner proflist_banner">
        <div class="container container-nd">
            <div class="banner_block">
                <img class="main_banner" src="@/img/info-sp/banner2.png" alt="">
                <div class="text_block">
                    <p class="main_header">{{ mainHeader.toUpperCase() }}</p>  
                    <p class="subheader">{{ subHeader.toUpperCase() }}</p>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            mainHeader: 'профилированный лист',
            subHeader: 'Напрямую от завода изготовителя по вашим размерам',
        }
    },
}
</script>