<template>
  <main class="main">
    <bread-crumbs :pages-array="pagesBasket" />
    <div class="section section--no-pt">
      <div class="container">
        <div class="inner">
          <div class="heading">
            <div class="heading-section">
              <h2>Корзина</h2>
            </div>
          </div>
        </div>
        <empty v-if="order.positions.length == 0"/>
        <full v-else />
      </div>
    </div>
  </main>
</template>
<script>
import Vuex from 'vuex'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import Functions from '@/utils/functions'
import Empty from './basketEmpty.vue'
import Full from './basketFull.vue'
export default {
  mixins: [Functions],
  data () {
    return {
      
    }
  },
  components: {
        breadCrumbs,
        Empty,
        Full,
    },
  computed: {
        ...Vuex.mapGetters({
            pagesBasket: 'dictionary/pagesBasket',
            basket: 'basket/basket',
            order: 'basket/order',
        }),
    },
  methods: {
    ...Vuex.mapMutations({
      setBasket: 'basket/setBasket',
    }),
  },
}
</script>