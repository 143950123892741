<template>
    <section class="about_company-info_page-sp-evosteel_banner">
        <div class="container container-nd">
            <div class="banner_block">
                <img class="main_banner" src="@/img/info-sp/banner3.png" alt="">
                <img class="sub_banner" src="@/img/info-sp/banner3-1.png" alt="">
            </div>
        </div>
    </section>
</template>
<script>
export default {
    data() {
        return {
            mainHeader: 'Сэндвич-панели',
            subHeader: 'Напрямую от завода изготовителя по вашим размерам',
        }
    },
}
</script>