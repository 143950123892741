<template>
    <main class="admin-index index__admin admin-contacts" style="display: block;">
        <div class="admin-index_header ">
            <h3>Контакты</h3>
            <!-- <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button> -->
        </div>
        <!-- // {{ ui_contacts }} -->
        <div class="index__admin admin-contacts-content">
            <div class="index__admin addresses">
                <span>Адреса</span>
                <button v-if="!isShowCreateForm" @click="isShowCreateForm = true">
                    Добавить подразделение
                </button>
                
                <div class="forms">
                    <div
                        v-if="isShowCreateForm" 
                        class="singleAddress"
                    >
                        <div class="checkboxes">
                            <div>
                                <label class="checkbox-plus">
                                <input 
                                    v-model="typesAddress.isMainOffice"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span style="margin-top: 0;">Головной офис</span>
                                </label>
                            </div>
                            <div>
                                <label class="checkbox-plus">
                                <input
                                    v-model="typesAddress.isOffice"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span style="margin-top: 0;">Офис продаж</span>
                                </label>
                            </div>
                            <div>
                                <label class="checkbox-plus">
                                <input 
                                    v-model="typesAddress.isProduction"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span>Производство</span>
                                </label>
                            </div>
                            <div>
                                <label class="checkbox-plus">
                                <input 
                                    v-model="typesAddress.isWarehouse"
                                    type="checkbox" 
                                    name="privacy"
                                >
                                <b />
                                <span>Склад</span>
                                </label>
                            </div>
                        </div>
                        <div
                            class="region index__admin" 
                        >
                            <select 
                                v-model="createForm.region" 
                            >
                                <option value="">Выберите регион</option>
                                <option 
                                    v-for="(category, idx) in topLevelCategory"
                                    :key="idx"
                                    :value="category.id_1c"
                                >
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                        <div
                            class="index__admin" 
                        >
                            <input 
                                v-model="createForm.sort"
                                type="text" 
                                placeholder="Сортировка"
                            >
                            <input 
                                v-model="createForm.link"
                                type="text" 
                                placeholder="Ссылка на карту"
                            >
                            <input 
                                v-model="createForm.city"
                                type="text"
                                placeholder="Город"
                            >
                            <input 
                                v-model="createForm.street"
                                type="text"
                                placeholder="Улица"
                            >
                        </div>
                        <div 
                            class="index__admin"
                        >
                            <input 
                                v-model="createForm.lat"
                                type="text" 
                                placeholder="Координаты: Широта"
                                :class="requireElementsValueError.lat ? 'error_input' : ''"
                                @keyup="validation"
                            >
                            <p class="error_label" v-if="requireElementsValueError.lat && !requireElementsValueError.lng">Пожалуйста заполните поле "широта"</p>
                            <input 
                                v-model="createForm.lng"
                                type="text" 
                                placeholder="Координаты: Долгота"
                                :class="requireElementsValueError.lng ? 'error_input' : ''"
                                @keyup="validation"
                            >
                            <p class="error_label" v-if="requireElementsValueError.lng && !requireElementsValueError.lat">Пожалуйста заполните поле "широта"</p>
                            <p class="error_label" v-if="requireElementsValueError.lat && requireElementsValueError.lng">Пожалуйста заполните координаты</p>
                            <input 
                                v-model="createForm.working_hours"
                                type="text"
                                placeholder="Режим работы"
                            >
                            <input 
                                v-model="createForm.email"
                                type="text"
                                placeholder="Почта"
                            >
                            <input 
                                v-model="createForm.phone"
                                type="text"
                                placeholder="Телефон"
                            >
                        </div>
                        <div class="buttons">
                            <button 
                                class="delete_button"
                                @click="closeCreateForm"
                            >
                                Отменить
                            </button>
                            <button 
                                class="delete_button"
                                @click="create"
                            >
                                Добавить
                            </button>
                        </div>
                    </div>
                    <div v-if="!isShowCreateForm" class="admin-contacts-list-block">
                        <single-address
                            v-for="(address, idx) in ui_contacts"
                            :key="address.id"
                            :index="idx"
                            :element="address"
                        />
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import Vuex from 'vuex'
import SingleAddress from './singleAddress.vue'

export default {
    components: {
        SingleAddress,
    },
    data() {
        return {
            map: {},
            markers: [],
            amoutChangeCotacts: 0,

            isShowCreateForm: false,
            typesAddress: {
                isMainOffice: false,
                isOffice: false,
                isProduction: false,
                isWarehouse: false,
            },
            createForm: {
                sort: null,
                is_type: [],
                region: "",
                link: null,
                city: null,
                street: null,
                lat: null,
                lng: null,
                working_hours: null,
                email: null,
                phone: null,
            },

            center: [ 52.56543518842296, 70.05213952539054 ],
            zoom: 4,

            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [-20, -20],
            },
            requireElementsValueError: {
                lat: false,
                lng: false,
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            // contacts: 'app/contacts',
            ui_contacts: 'ui_contacts/contacts',
            indexEditedContactAddress: 'app/indexEditedContactAddress',
            topLevelCategory: 'dictionary/topLevelCategory',
        }),
    },
    mounted() {
        this.getContacts()
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            getContacts: 'ui_contacts/getContacts',
            createContact: 'ui_contacts/createContact',
        }),
        ...Vuex.mapMutations({
            pushContactAddress: 'app/pushContactAddress',
            updateCoordinateAddress: 'app/updateCoordinateAddress',
        }),
        validation() {
            var value = true
            if (!this.createForm.lat) {
                this.requireElementsValueError.lat = true
                value = false
            }else{
                this.requireElementsValueError.lat = false
            }
            if (!this.createForm.lng) {
                this.requireElementsValueError.lng = true
                value = false
            }else{
                this.requireElementsValueError.lng = false
            }
            return value
        },
        updateData() {
            this.updateJson()
        },
        closeCreateForm() {
            this.typesAddress.isMainOffice = false
            this.typesAddress.isOffice = false
            this.typesAddress.isProduction = false
            this.typesAddress.isWarehouse = false

            this.createForm = {
                sort: null,
                is_type: [],
                region: null,
                link: null,
                city: null,
                street: null,
                lat: null,
                lng: null,
                working_hours: null,
                email: null,
                phone: null,
            }

            this.isShowCreateForm = false
        },
        create() {
            if (!this.validation()) {
                return
            }
            var copyForm = this.createForm
            if (this.typesAddress.isMainOffice) {
                copyForm.is_type.push('isMainOffice')
            }
            if (this.typesAddress.isOffice) {
                copyForm.is_type.push('isOffice')
            }
            if (this.typesAddress.isProduction) {
                copyForm.is_type.push('isProduction')
            }
            if (this.typesAddress.isWarehouse) {
                copyForm.is_type.push('isWarehouse')
            }
            if (!this.createForm.sort) {
                this.copyForm.sort = 0
            }
            this.createContact(copyForm)
                .then(() => {
                    this.closeCreateForm()
                })
        },
        addAddress() {
            this.pushContactAddress({
                isOffice: false,
                isProduction: false,
                isWarehouse: false,
                region: null,
                city: null,
                street: null,
                working_hours: null,
                lat: 57.974887,
                lng: 56.263805,
                visible: true,
                region_id_1c: null,
                email: null,
                phone: null,
            })
        },
        setPoint(event) {
            let coords = []
            
            if (event.get('coords')) {
                coords = event.get('coords')
            }
            if (coords.length != 2) {
                return
            }
            this.updateCoordinateAddress({
                idx: this.indexEditedContactAddress,
                lat: coords[0],
                lng: coords[1],
            })
        },
    },
}
</script>
<style lang="scss">
    .admin-contacts-list-block{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
</style>