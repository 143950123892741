<template>
    <div class="container-fluid mt-5 about_company_nd">
        <bread-crumbs :pages-array="pagesAboutCompany" />
        <div>
            <div class="col-12">
                <div class="section section--no-pt main_block">
                    <div class="container container-nd">
                        <div class="post">
                            <div class="post-image">
                                <img :src="company.imgCompany" alt="">
                            </div>
                            <div class="post-title">
                                <p>{{company.headingCompany}}</p>
                            </div>
                            <div class="post-text">
                                <p v-html="company.descriptionCompany"></p>
                            </div>
                        </div>
                    </div>
                </div>


                <!-- <div class="section section--no-pt history_block">
                    <div class="container container-nd">
                        <div class="inner">
                            <div class="heading">
                                <div class="heading-section">
                                    <p>История компании в датах</p>
                                </div>
                            </div>
                        </div>
                        <div class="tabs tabs--buttons tabs--history">
                            <div class="tabs-title">
                                <div class="tabs-item" :class="{'-active' : currentType == 2}" @click="currentType = 2">90-ыe</div>
                                <div class="tabs-item" :class="{'-active' : currentType == 1}" @click="currentType = 1">2000-ныe</div>
                            </div>
                            <div class="tabs-content">
                                <div class="tabs-item -active">
                                    <div class="accordion history">
                                        <single-history class="single_history" v-for="(obj, a) in company.dataHistory" :key="a" :obj="obj" :type="currentType" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->

                <!-- <excursion-block /> -->

                <div class="section home_excursion_block">
                    <div class="container container-nd">
                        <div
                            class="modal-iframe"
                        >
                            <iframe 
                                src="https://vk.com/video_ext.php?oid=238807615&id=456239022&hd=2" 
                                width="853" 
                                height="480" 
                                allow="autoplay; encrypted-media; fullscreen; picture-in-picture; screen-wake-lock;" 
                                frameborder="0" 
                                allowfullscreen>
                            </iframe>
                        </div>
                    </div>
                </div>

                <info-block />
                <callback-block />
                <video-block />
                <map-block />
            </div>
        </div>
    </div>
</template>
<script>
import Functions from '@/utils/functions'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
// import singleHistory from './singleHistory.vue'
import Vuex from 'vuex'
// import excursionBlock from '@/components/pages/home/excursion/excursion.vue'
import callbackBlock from '@/components/form-callback.vue'
import mapBlock from './map.vue'
import videoBlock from './videoBlock.vue'
import infoBlock from './infoBlock.vue'

export default {
    mixins: [Functions],
    components:{
        breadCrumbs,
        // singleHistory,
        // excursionBlock,
        callbackBlock,
        mapBlock,
        videoBlock,
        infoBlock,
    },
    data() {
        return {
            currentType: 2,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            company: 'app/company',
            pagesAboutCompany: 'dictionary/pagesAboutCompany',
        }),
    },
    methods: {
        // changeActiveCategory(num) {
        //     this.activeCategory = num
        // },
    },
}
</script>