<template>
	<div class="wrapper wrapper-no-hidden front_calculatorRoof_page">
		<main class="main">
			<div class="section section--no-pt section--no-pb section-nlk section-cl">
				<div class="container">
					<div class="inner">
						<ul class="list list--flex breadcrumbs cl-breadcrumbs">
							<li class="list-item" @click="push('')">
								<a class="list-link">
									<svg class="icon" width="16" height="16" viewBox="0 0 16 16">
										<use xlink:href="@/assets/img/icons.svg#home"></use>
									</svg>
								</a>
							</li>
							<li class="list-item"><span class="list-current">Калькулятор кровли</span></li>
						</ul>
						<form onsubmit="return false">
							<h2 class="cl__h2-title"><span>1</span> Выберите тип кровли</h2>
							<!-- <p 
								class="fs-14 max-1180 mb-30"
								v-if="adminCalculatorRoof.step1" 
								v-html="adminCalculatorRoof.step1.description"
							/> -->

							<div class="cl-top-option nlk-horizontal-scroll mb-70">
								<label 
									class="cl-icon-radio"
									v-for="roofType in roofTypes"
									:key="roofType.id"
									@click="setRoofType(roofType.id)"
								>
									<input 
										type="radio" 
										name="option1" 
										:checked="(calculateForm.roof_type == roofType.id ? true : false)"
									>
									<div class="cl-icon-radio__icon">
										<img :src="require('@/assets/img/option1/o' + roofType.num + '.svg')" class="cl-icon-radio__img-1">
										<img :src="require('@/assets/img/option1/o' + roofType.num + '-a.svg')" alt="" class="cl-icon-radio__img-2">
									</div>
									<div class="cl-icon-radio__btn">{{ roofType.name }}</div>
								</label>
							</div>
							<div class="cl-row mobil-colum">
								<div class="cl-row-group cl-row-group_mr-90 cl-row-flex">
									<h2 class="cl__h2-title">
										<span>1А</span> 
										Планируете ли утеплять кровлю?
									</h2>
									<div class="cl-row-btns">
										<label class="cl-rowbtn-radio">
											<input 
												type="radio"
												name="btn1"
												:checked="(calculateForm.is_heat == true ? true : false)"
												@click="setIsHeat(true)"
											>
											<span>Да</span>
										</label>
										<label class="cl-rowbtn-radio">
											<input 
												type="radio" 
												name="btn1"
												:checked="(calculateForm.is_heat == false ? true : false)"
												@click="setIsHeat(false)"
											>
											<span>Нет</span>
										</label>
									</div>
								</div>
								<div 
									v-if="calculateForm.is_heat"
									class="cl-row-group cl-row-group_mr-90 mw-25"
								>
									<div class="cl-row-stapper">
										<span>Утеплитель</span>
										<choices-select
											data-select
											:elements="insulationArray" 
											v-model="calculateForm.insulation_thickness" 
											:defaultValue="defaultValue" 
											:typeSelect="'default'"
											@change="updateInsulation" 
										/>
									</div>
									
								</div>
							</div>

							<!-- <p 
								class="fs-14 max-1180 mb-30"
								v-if="adminCalculatorRoof.step1A" 
								v-html="adminCalculatorRoof.step1A.description"
							/> -->

							<div class="cl-hr"></div>

							<div class="cl-grid">
								<div class="cl-grid__left">
									<h2 class="cl__h2-title"><span>2</span> Введите размеры вашей крыши</h2>

									<!-- <p>Произведите замер и внесите данные. Если конструкция кровли сложная, то ее разбивают на отдельные  и замеряют все расстояния для раскроя материала и геометрического построения. При расчете особое внимание следует уделить не площади крыши, а размеру каждого ската.</p> -->

									<!-- <p 
										v-if="adminCalculatorRoof.step2" 
										v-html="adminCalculatorRoof.step2.description"
									/> -->

									<label class="cl-input-group mb-30">
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.width_1"
											@keyup="update"
											@focus="inputFocusName = 'w1'"
											required
										>
										<span v-if="calculateForm.roof_type != 30 && calculateForm.roof_type != 40" data-title="Ширина ската, м">W</span>
										<span v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40" data-title="Ширина ската 1, м">W1</span>
									</label>

									<label 
										class="cl-input-group mb-30"
										v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40"
									>
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.width_2"
											@keyup="update"
											@focus="inputFocusName = 'w2'"
											required
										>
										<span data-title="Ширина ската 2, м">W2</span>
									</label>

									<label 
										class="cl-input-group mb-30"
										v-if="calculateForm.roof_type == 30"
									>
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.width_3"
											@keyup="update"
											@focus="inputFocusName = 'w3'"
											required
										>
										<span data-title="Длина конька, м">W3</span>
									</label>

									<label class="cl-input-group mb-30">
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.height_1"
											@keyup="update"
											@focus="inputFocusName = 'h1'"
											required
										>
										<span v-if="calculateForm.roof_type == 10" data-title="Длина ската, м">H</span>
										<span v-else data-title="Длина ската 1, м">H1</span>
									</label>

									<label 
										class="cl-input-group mb-50"
										v-if="calculateForm.roof_type != 10"
									>
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.height_2"
											@keyup="update"
											@focus="inputFocusName = 'h2'"
											required
										>
										<span data-title="Длина ската 2, м">H2</span>
									</label>

									<div 
										class="cl-row-btns mb-30"
									>
										<label 
											class="cl-rowbtn-radio w-180"
											v-for="mounting in mountingArray"
											:key="mounting.id"
											@click="setMountingType(mounting.id)"
										>
											<input 
												type="radio" 
												name="btn2" 
												:checked="(calculateForm.mounting_type == mounting.id ? true : false)"
											>
											<span>{{ mounting.name }}</span>
										</label>
									</div>

									<label class="cl-check mb-30">
										<input 
											type="checkbox" 
											v-model="calculateForm.is_cornice"
											@change="update"
										>
										<span>Сделать расчет cофита</span>
									</label>

									<label 
										v-if="calculateForm.is_cornice"
										class="cl-input-group mb-30" 
									>
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.k"
											@keyup="update"
											required
										>
										<span data-title="Ширина карнизного свеса, м">k	</span>
									</label>

									<label 
										v-if="calculateForm.is_cornice"
										class="cl-input-group mb-30"
									>
										<input 
											type="number" 
											step="0.01"
											v-model="calculateForm.m"
											@keyup="update"
											required
										>
										<span data-title="Ширина лобовой доски , м">m</span>
									</label>
									
									<div 
										v-if="calculateForm.is_cornice"
										class="cl-row-btns"
									>
										<label 
											v-if="calc_roof_body.soffit_corrug"
											class="cl-rowbtn-radio w-180"
										>
											<input 
												@click="setCorniceType(calc_roof_body.soffit_corrug, 'profnastil')"
												type="radio" 
												name="btn1" 
												:checked="(cornice == 'profnastil' ? true : false)"
											>
											<span>Профнастил</span>
										</label>

										<label 
											v-if="calc_roof_body.soffit"
											class="cl-rowbtn-radio w-180"
										>
											<input 
												@click="setCorniceType(calc_roof_body.soffit, 'soffit')"
												type="radio" 
												name="btn1" 
												:checked="(cornice == 'soffit' ? true : false)"
											>
											<span>Софит</span>
										</label>

										<label 
											v-if="calc_roof_body.soffit_metall"
											class="cl-rowbtn-radio w-180"
										>
											<input 
												@click="setCorniceType(calc_roof_body.soffit_metall, 'soffit-metall')"
												type="radio" 
												name="btn1" 
												:checked="(cornice == 'soffit-metall' ? true : false)"
											>
											<span>Софит-металл</span>
										</label>

										<label 
											v-if="calc_roof_body.soffit_pvh"
											class="cl-rowbtn-radio w-180"
										>
											<input 
												@click="setCorniceType(calc_roof_body.soffit_pvh, 'soffit-pvh')"
												type="radio" 
												name="btn1" 
												:checked="(cornice == 'soffit-pvh' ? true : false)"
											>
											<span>Софит-ПВХ</span>
										</label>
									</div>
									<div 
										v-if="parameters.length != 0"
										class="position_parameters mb-10"
									>
										<single-parameter 
											v-for="parameter in parameters" 
											:key="parameter.sort" 
											v-show="parameter.value == true && parameter.name != 'Длина'"
											:parameter="parameter" 
											:product="soffit_products[0]"
											:form="form"
											:typeCard="'category'"
											:isSubSign="true"
											:subElementsProuducts="soffit_products"
											:localArrayColors="soffitColorsArray"
											:numBlockParameters="1"
											:parameterErrorArray="parameterSoffitErrorArray"
											@getSubElements="getSoffitProducts()"
											@updateCalculatorPosition="updateCalculatorPosition"
											@setPositionColor="setColorSoffit"
										/>
									</div>
								</div>
								<div class="cl-grid__right pt-50">
									<img :src="require('@/assets/img/calculators/roof/' + getCalculatorImgFullName)" alt="">
								</div>
								
							</div>

							<div class="cl-hr mt-0"></div>

							<h2 class="cl__h2-title mb-3"><span>3</span> Выберите материал для расчёта</h2>

							<!-- <p 
								class="fs-14 max-1180 mb-30"
								v-if="adminCalculatorRoof.step3" 
								v-html="adminCalculatorRoof.step3.description"
							/> -->

							<div class="cl-row mb-60">
								<label 
									class="cl-img-radio"
									@click="material = 10"
								>
									<input 
										type="radio" 
										name="img" 
										:checked="(material == 10 ? true : false)"
									>
									<span>
										<span class="cl-img-radio__img"><img src="@/assets/img/img1.png" alt=""></span>
										<span>Металлочерепица</span>
									</span>
								</label>
								<label 
									class="cl-img-radio"
									@click="material = 20"
								>
									<input 
										type="radio" 
										name="img"
										:checked="(material == 20 ? true : false)"
									>
									<span>
										<span class="cl-img-radio__img"><img src="@/assets/img/img2.png" alt=""></span>
										<span>Профнастил</span>
									</span>
								</label>
							</div>

							<div class="cl-hr"></div>

							<h2 
								class="cl__h2-title"
							>
								<span>4</span> 
								Выберите вид профиля и размер листов
							</h2>

							<!-- <p 
								class="fs-14 max-1180 mb-30"
								v-if="adminCalculatorRoof.step4" 
								v-html="adminCalculatorRoof.step4.description"
							/> -->

							<!-- <p 
								class="fs-14 max-1180 mb-30"
							>
								Над раскладкой кровли находятся закладки профилей изделия, они позволят вам выбрать необходимый профиль с учетом его рабочей ширины и запретных длин. Для просмотра раскладки листов на скате выберите скат, нажав на него. С помощью стрелок изменяйте длину листов в соответствии с вашей потребностью для выбранного ската.
							</p> -->

							<div 
								class="cl-wrapper__gray cl-wrapper-check cl-wrapper-check_gap-60 mb-80"
							>
								<span v-if="material == 10">Металлочерепица:</span>
								<span v-if="material == 20">Профнастил:</span>
								<label 
									class="cl-check cl-check_gray"
									v-for="element in currentArrayProfile"
									:key="element.id"
								>
									<input 
										type="checkbox" 
										:checked="(calculateForm.view_material == element.id_1c ? true : false)"
										@click="setViewMaterial(element)"
									>
									<span>{{ element.name }}</span>
								</label>
							</div>

							<!-- <div 
								class="cl-wrapper__gray cl-wrapper-check cl-wrapper-check_gap-60 mb-80"
								v-if="material == 20"
							>
								<span>Профнастил:</span>
								{{ calculateForm.view_material  }}
								<label 
									class="cl-check cl-check_gray"
									v-for="profnastil in profnastilArray"
									:key="profnastil.id"
								>
									<input 
										type="checkbox" 
										:checked="(calculateForm.view_material == profnastil.id_1c ? true : false)"
										@click="setViewMaterial(profnastil)"
									>
									<span>{{ profnastil.name }}</span>
								</label>
							</div> -->
							<div 
								v-if="parameters_products.length != 0"
								class="position_parameters mb-10"
							>
								<single-parameter 
									v-for="parameter in parameters_products" 
									:key="parameter.sort + 'b'" 
									v-show="parameter.value == true && parameter.name != 'Длина'"
									:parameter="parameter" 
									:product="products[0]"
									:form="formProducts"
									:typeCard="'category'"
									:isSubSign="true"
									:subElementsProuducts="products"
									:localArrayColors="productColorsArray"
									:numBlockParameters="2"
									:parameterErrorArray="parameterProductsErrorArray"
									@getSubElements="getProducts"
									@updateCalculatorPosition="updateCalculatorPosition"
									@setPositionColor="setColorPosition"
								/>
							</div>
							
							<div class="cl-hr"></div>

							

							<div 
								class="craf-grid"
							>
								<div class="craf-grid__left skat_left_block">
									<div 
										class="block_skat mb-30"
										:class="{'block_skat_active' : numActiveSkat == 1}"
										@click="changeActiveSkat(1)"
									>
										<h3>Скат 1</h3>
										<div class="cl-row-stapper flex_jcsb mb-30">
											<span>Максимальная длина листа, м</span>
											<div class="stepper">
												<button 
													type="button" 
													class="stepper-btn stepper-minusbtn"
													@click="changeAmountMaxLengthList('minus', 1)"
												>
													<svg 
														width="7" 
														height="4" 
														viewBox="0 0 7 4" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M0.144 3.182H6.37V0.432H0.144V3.182Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>

												<input 
													type="number"
													step="0.01"
													class="stepper-input input_skats" 
													v-model="calculateForm.length_list_1"
													@keyup="update"
												>
												
												<button 
													type="button" 
													class="stepper-btn stepper-plus"
													@click="changeAmountMaxLengthList('plus', 1)"
												>
													<svg 
														width="11" 
														height="11" 
														viewBox="0 0 11 11" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M10.836 6.642H7.008V10.382H4.17V6.642H0.364V3.958H4.17V0.217999H7.008V3.958H10.836V6.642Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>
											</div>
										</div>

										<div 
											v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40" 
											class="cl-row-stapper flex_jcsb"
										>
											<span>Смещение листов, м</span>
											<div class="stepper">
												<button 
													type="button" 
													class="stepper-btn stepper-minusbtn"
													@click="changeAmountShiftSkat('minus', 1)"
												>
													<svg 
														width="7" 
														height="11" 
														viewBox="0 0 7 11" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M5.38976 2.35594e-07L-2.40417e-07 5.50009L5.38976 11L7 9.28281L3.29311 5.50009L7 1.71719L5.38976 2.35594e-07Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>
												<input 
													type="number"
													step="0.01" 
													class="stepper-input input_skats" 
													v-model="calculateForm.left_shift_1"
													@keyup="update"
												>
												
												<button 
													type="button" 
													class="stepper-btn stepper-plus"
													@click="changeAmountShiftSkat('plus', 1)"
												>
													<svg 
														width="7" 
														height="11" 
														viewBox="0 0 7 11" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M1.61024 11L7 5.49991L1.61024 -6.42722e-08L-8.42978e-07 1.71719L3.70689 5.49991L-9.33197e-07 9.28281L1.61024 11Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>
											</div>
										</div>
									</div>
									<div 
										v-if="calculateForm.roof_type != 10"
										class="block_skat"
										:class="{'block_skat_active' : numActiveSkat == 2}"
										@click="changeActiveSkat(2)"
									>
										<h3>Скат 2</h3>
										<div class="cl-row-stapper flex_jcsb mb-30">
											<span>Максимальная длина листа, м</span>
											<div class="stepper">
												<button 
													type="button" 
													class="stepper-btn stepper-minusbtn"
													@click="changeAmountMaxLengthList('minus', 2)"
												>
													<svg 
														width="7" 
														height="4" 
														viewBox="0 0 7 4" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M0.144 3.182H6.37V0.432H0.144V3.182Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>

												<input 
													type="number"
													step="0.01" 
													class="stepper-input input_skats"  
													v-model="calculateForm.length_list_2"
													@keyup="update"
												>
												
												<button 
													type="button" 
													class="stepper-btn stepper-plus"
													@click="changeAmountMaxLengthList('plus', 2)"
												>
													<svg 
														width="11" 
														height="11" 
														viewBox="0 0 11 11" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M10.836 6.642H7.008V10.382H4.17V6.642H0.364V3.958H4.17V0.217999H7.008V3.958H10.836V6.642Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>
											</div>
										</div>
										<div 
											v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40"
											class="cl-row-stapper flex_jcsb"
										>
											<span>Смещение листов, м</span>
											<div class="stepper">
												<button 
													type="button" 
													class="stepper-btn stepper-minusbtn"
													@click="changeAmountShiftSkat('minus', 2)"
												>
													<svg 
														width="7" 
														height="11" 
														viewBox="0 0 7 11" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M5.38976 2.35594e-07L-2.40417e-07 5.50009L5.38976 11L7 9.28281L3.29311 5.50009L7 1.71719L5.38976 2.35594e-07Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>
												<input 
													type="number"
													step="0.01" 
													class="stepper-input input_skats" 
													v-model="calculateForm.left_shift_2"
													@keyup="update"
												>
												<button 
													type="button" 
													class="stepper-btn stepper-plus"
													@click="changeAmountShiftSkat('plus', 2)"
												>
													<svg 
														width="7" 
														height="11" 
														viewBox="0 0 7 11" 
														fill="none" 
														xmlns="http://www.w3.org/2000/svg"
													>
														<path 
															d="M1.61024 11L7 5.49991L1.61024 -6.42722e-08L-8.42978e-07 1.71719L3.70689 5.49991L-9.33197e-07 9.28281L1.61024 11Z" 
															fill="#353B48"
														></path>
													</svg>
												</button>
											</div>
										</div>
									</div>
								</div>
								
								<div 
									v-if="!isEmptyObject(skat_1)"
									class="craf-grid__right"
								>
									<svg 
										version="1.1" 
										width="598" 
										:style="'width: 600px; height: ' + heightraskladka + 'px'"
									>
										<g 
											stroke="gray" 
											stroke-width="1"
										></g>
										<g 
											stroke="blue" 
											stroke-width="1"
										>
											<line 
												v-for="(bluline, i) in bluelines"
												:key="i"
												:x1="bluline.x1" 
												:y1="bluline.y1" 
												:x2="bluline.x2" 
												:y2="bluline.y2"
											/>
										</g>
										<g 
											stroke="#CA0D27" 
											stroke-width="4"
										>
											<line 
												v-for="(redline, i) in redlines"
												:key="i"
												:x1="redline.x1" 
												:y1="redline.y1" 
												:x2="redline.x2" 
												:y2="redline.y2"
											></line>
										</g>
										<g 
											stroke="black" 
											stroke-width="1"
										>
											<line 
												v-for="(line, idx) in structure"
												:key="idx"
												:x1="line.x1" 
												:y1="line.y1" 
												:x2="line.x2" 
												:y2="line.y2"
											/>
										</g>
										<g 
											font-weight="normal" 
											font-size="12" 
											fill="blue"
										>
											<text
												v-for="(text, idx) in arrayTexts"
												:key="idx" 
												:x="text.x" 
												:y="text.y"
											>
												{{ text.text }}
											</text>
										</g>
									</svg>
								</div>
							</div>

							<div class="cl-hr"></div>
							
							<button 
								class="button button--primary w-205"
								type="button"
								@click="save"
							>
								Рассчитать
							</button>

							<div class="cl-hr"></div>

							<h2 class="cl__h2-title" v-if="!isEmptyObject(calculateResponse)"><span>5</span> Выберите толщину металла, покрытие и цвет</h2>

						</form>
						
						<div 
							class="sticky-grid mt-40"
							v-if="!isEmptyObject(calculateResponse)"
						>
							<div class="sticky-grid__content">
								<single-position 
									v-for="(position, idx) in calculateResponse.order.positions"
									:key="idx"
									:index="idx"
									:position="position"
									:arrayNomenclaturesResult="arrayNomenclaturesResult"
								/>

								<div class="cal-seo mt-70">
									<p>Качественно выполненные работы по устройству кровли являются одним из условий последующего комфортного проживания в доме. В установки кровли используются самые разные материалы: древесина для стропильной системы, специальные пленки и мембраны для пароизоляции и гидрозащиты, утеплитель, кровельное покрытие. Чтобы не ошибиться при их покупке, необходимо правильно подсчитать требуемое количество материала. Для этого мы предлагаем воспользоваться онлайн-калькулятором.</p>
									<br>
									<h3 class="cal-seo__h3">Расчет покрытия для кровли</h3>
									<p>Удобный в использовании калькулятор кровли предназначен для определения конкретного количества материала, необходимого для устройства кровли, в том числе доборных и комплектующих элементов. Если при выборе материала возникли затруднения – закажите обратный звонок, наш менеджер перезвонит и ответит на ваши вопросы, предоставив подробную техническую информацию о различных типах кровельных покрытий, ценах, условиях оплаты и доставки. По вашему желанию может быть составлена точная смета расходов на устройство кровли.</p>
									<p>Для расчета кровли нужно выполнить ряд замеров, результаты которых необходимо будет внести в соответствующие поля калькулятора. Использовать для этого планы здания и отдельные чертежи надо с пониманием того, что некоторые указанные на них размеры могут отличаться от фактических. Кроме того, замеры следует производить, учитывая следующие факторы:</p>
									<ul>
										<li>угол наклона и геометрию крыши, которая может быть односкатной, двускатной, шатровой, мансардной, вальмовой;</li>
										<li>необходимость устройства на крыше люков и других элементов, оставляемых без кровельного покрытия;</li>
										<li>будет ли в составе конструкции кровли теплоизоляция и какой утеплитель выбран.</li>
									</ul>
									<p>Работа с калькулятором начинается с выбора типа кровли и материала, который будет использован в качестве кровельного покрытия – металлочерепица, профнастил или фальцевая кровля. Для крыш с небольшим уклоном используют фальцевую кровлю. Фальц – специальный шов, с помощью которого металлические листы соединяются между собой. Следующим шагом необходимо внести результаты ваших замеров в соответствующие поля и выбрать – делать расчёт с утеплителем, софитами или без.</p>
									<p>Каждый вид покрытия отличается особенностями монтажных работ, доборными элементами, крепежами. Программа калькулятора позволяет выбрать толщину металла и цвет покрытия. Благодаря широкой цветовой гамме можно подобрать оптимальный вариант для вашего дома.</p>
									<p>При расчете сложной кровли крышу разбивают на отдельные плоскости и производят замер каждого элемента, уделяя особое внимание размерам скатов. Однако максимально точный расчет поможет выполнить только высококвалифицированный специалист.</p>
									<br>
									<h3 class="cal-seo__h3">Как рассчитать софиты для крыши?</h3>
									<p>Софиты используются для обшивки карнизов и фронтонных свесов. Калькулятор позволит рассчитать необходимое количество в режиме онлайн. Расчет софитов производится автоматически с учетом материала (металл или ПВХ), запретных длин и перехлестов листов.</p>
									<p>При необходимости, вы можете воспользоваться расчетом необходимого количества саморезов, утеплителя, доборных элементов и комплектующих. После завершения расчета добавьте заказ в корзину и приступайте к оформлению заказа. Мы доставим кровельный материал в любой регион Российской Федерации.</p>
								</div>
							</div>
							<div class="sticky-grid__aside">
								<div class="sticky-box">
									<div class="sticky-box__title">Счет / заказ</div>
									<ul class="sticky-box__list">
										<li>Площадь кровли <span>{{ calculateResponse.roof_response.roof_area }}</span></li>
										<li>Площадь листов полная, м2 <span>{{ getFixedPrice(calculateResponse.roof_response.sheet_area_total) }}</span></li>
										<li>Площадь листов полезная, м2 <span>{{ getFixedPrice(calculateResponse.roof_response.useful_sheet_area) }}</span></li>
									</ul>
	
									<div class="sticky-box__bottom">
										Общая стоимость <b>{{ fullCost }} {{ regionCurrency[regionCode].symbol }}</b>
									</div>
	
									<button 
										class="button button--primary"
										type="button"
										:disabled="!isCanBasket"
										@click="addInBasket"
									>
										Добавить в корзину
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</main>
	</div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import choicesSelect from '@/components/choices-select/choices-select.vue'
import singleParameter from '@/components/pages/catalog/single-parameter.vue'
import singlePosition from './singlePosition.vue'
export default {
	mixins: [Functions],
	components: {
		choicesSelect,
		singleParameter,
		singlePosition,
	},
	data() {
		return {
			roofTypes: [
				{id: 10, name: 'Односкатная', num: 1},
				{id: 20, name: 'Двускатная', num: 2},
				{id: 30, name: 'Вальмовая', num: 3},
				{id: 40, name: 'Шатровая', num: 4},
				{id: 50, name: 'Мансардная', num: 5},
			],
			corniceTypes: [
				{id: 10, name: 'Профнастил'},
				{id: 20, name: 'Софит'},
				{id: 30, name: 'Софит-Металл'},
				{id: 40, name: 'Софит-ПВХ'},
			],
			material: 10,
			profnastilArray: [],
			metallArray: [],
			insulationArray: [
				{id: 50, name: '50'},
				{id: 100, name: '100'},
				{id: 150, name: '150'},
				{id: 200, name: '200'},
			],
			mountingArray: [
				{id: 10, name: "Дерево"},
				{id: 20, name: "Металл"},
			],
			numActiveSkat: 1,
			changeElementIndex: null,
			inputFocusName: null,
			bluelines: [],
			redlines: [],
			structure: [],
			arrayTexts: [],
			skat_1: {},
			skat_2: {},

			cornice: '',
			corniceId: null,

			soffit_elements: [],
			soffit_products: [],

			elements: [],
			products: [],

			parameters: [],
			parameters_products: [],

			isShowList: false,
			changeParameter: null,

			heightraskladka: null,
			defaultValue: 'Выберете толщину утеплитель',
			// childInsulationArray: [],
			currentInsulation: null,

			form: {
				parent_id: null,
                coating: null,
                colors: "",
                thickness: null,
                is_show_zero_price: false,
                is_group: false,
				root_id: null
            },

			formProducts: {
				parent_id: null,
                coating: null,
                colors: "",
                thickness: null,
                is_show_zero_price: false,
                is_group: false,
				root_id: null
            },

			soffit_position: {
				id_1c: null,
				name: null,
				coating: '',
				color: null,
				color_external: null,
				color_inner: null,
				external_cladding_thickness: null,
				geometry: null,
				insulation_thickness: null,
				internal_knurling: null,
				internal_thickness: null,
				internal_lining_thickness: null,
				internal_surface_coating: null,
				knurling_on_the_outer_surface: null,
				length: null,
				maximum_length: null,
				metal_thickness: null,
				minimum_length: null,
				mounting_width: null,
				overall_width: null,
				price_group: null,
				price_unit: null,
				price: null,
				profile: null,
				rolling_side: null,
				rolling_the_inner_lining: null,
				rolling_the_outer_cladding: null,
				texture: null,
				thickness: null,
				type_item: null,
				wave_height: null,
				wave_length: null,
				width: null,
				count_base_unit_of_measure: 1,
				base_unit_of_measure: null,
				amount: 1,
				sell: 0,
				amount_in_progress: null,
				amount_in_stock: null,
				amount_shipped: null,
				amount_to_work: null,
				amount_balance: null,
				root_id: null,
				diameter: null,
				head_type: null,
				shelf_a: null,
				shelf_b: null,
				tip_type: null,
			},

			position: {
				id_1c: null,
				name: null,
				coating: '',
				color: null,
				color_external: null,
				color_inner: null,
				external_cladding_thickness: null,
				geometry: null,
				insulation_thickness: null,
				internal_knurling: null,
				internal_thickness: null,
				internal_lining_thickness: null,
				internal_surface_coating: null,
				knurling_on_the_outer_surface: null,
				length: null,
				maximum_length: null,
				metal_thickness: null,
				minimum_length: null,
				mounting_width: null,
				overall_width: null,
				price_group: null,
				price_unit: null,
				price: null,
				profile: null,
				rolling_side: null,
				rolling_the_inner_lining: null,
				rolling_the_outer_cladding: null,
				texture: null,
				thickness: null,
				type_item: null,
				wave_height: null,
				wave_length: null,
				width: null,
				count_base_unit_of_measure: 1,
				base_unit_of_measure: null,
				amount: 1,
				sell: 0,
				amount_in_progress: null,
				amount_in_stock: null,
				amount_shipped: null,
				amount_to_work: null,
				amount_balance: null,
				root_id: null,
				diameter: null,
				head_type: null,
				shelf_a: null,
				shelf_b: null,
				tip_type: null,
			},

			soffitColorsArray: [],
			productColorsArray: [],

			parameterSoffitErrorArray: [],
			parameterProductsErrorArray: [],

			arrayId1cFromResultPositions: [],
			arrayNomenclaturesResult: [],
		}
	},	
	computed: {
		...Vuex.mapGetters({
			formCalculatorRoof: 'calculator/formCalculatorRoof',
			calculateResponse: 'calculator/calculateResponse',
			yandexCloud: 'app/yandexCloud',
			regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
			calculators: 'app/calculators',
			regionAdresses: 'app/regionAdresses',
			calc_roof_body: 'calculator/calc_roof_body',
			region: 'auth/city',
			filters: 'catalog/filters',
			userCompanyId: 'auth/userCompanyId',
		}),
		currentArrayProfile() {
			let array = []
			if (this.material == 10) {
				array = this.metallArray
			}else if (this.material == 20) {
				array = this.profnastilArray
			}
			return array
		},
		calculateForm() {
			return Object.assign({}, this.formCalculatorRoof)
		},
		formIsHeat() {
			return this.calculateForm.is_heat
		},
		formIsCornice() {
			return this.calculateForm.is_cornice
		},
		formRoofType() {
			return this.calculateForm.roof_type
		},
		fullCost() {
			let cost = 0
			if (this.calculateResponse.order.positions) {
				this.calculateResponse.order.positions.map((element) => {
					if (element.sell) {
						cost += Number(element.sell)
					}
				})
			}
			return cost.toFixed(2)
		},
		isCanBasket() {
			let can = true
			if (this.calculateResponse.order.positions.length != 0) {
				this.calculateResponse.order.positions.map((element) => {
					if (element.price == null || element.price == 0) {
						can = false
					}
				})
			}else{
				can = false
			}
			return can
		},
		getCalculatorImgFullName(){
			let name = null
			name = this.calculateForm.roof_type + '/' 
			if (this.inputFocusName) {
				name += this.inputFocusName + '.svg' 
			}else{
				name += 'w1.svg'
			}
			return name
		},
		adminCalculatorRoof() {
			return this.calculators.calculatorRoof
		},
		objectCurrentRegion() {
            let current_region = {}
            this.regionAdresses.map((element) => {
                if (element.id_1c == this.region) {
                    current_region = element
                }
            })
            return current_region
        },
	},
	watch: {
		calculateForm() {
			this.setCalculateResponse({})
		},
		formIsHeat(val) {
			if (val == false) {
				this.calculateForm.insulation_thickness = null
				this.update()
			}
		},
		formIsCornice(val) {
			if (val == true) {
				this.setCorniceType(this.calc_roof_body.soffit_corrug, 'profnastil')
			}else{
				this.cornice = null
				this.corniceId = null
				this.parameters = []
			}

			this.calculateForm.k = null
			this.calculateForm.m = null
			this.update()
		},
		material() {
			this.setDefaultViewMaterial()
		},
		formRoofType(val) {
			this.updateRaskladka()

			this.inputFocusName = null

			this.bluelines = []
			this.redlines = []
			this.structure = []
			this.arrayTexts = []

			this.setCalculateResponse({})
			this.setDefaultFormRoof(val)
			this.setPositionsDefault()
		},
		skat_1() {
			this.updateRaskladka()
		},
		region(val) {
			this.bluelines = []
			this.redlines = []
			this.structure = []
			this.arrayTexts = []

			this.setCalculateResponse({})
			this.setDefaultFormRoof()
			this.setPositionsDefault()
			this.getValues()
			this.form.root_id = val
			this.form.rolling_side = null
			this.form.coating = null
			this.form.thickness = null
			this.formProducts.root_id = val
			this.soffit_position.root_id = val
			this.position.root_id = val
		},
	},
	destroyed() {
		this.setCalculateResponse({})
		this.setDefaultFormRoof()
		this.setPositionsDefault()
	},
	mounted() {
		this.drawLists()
		this.getValues()
		this.form.root_id = this.region
		this.formProducts.root_id = this.region
		this.soffit_position.root_id = this.region
		this.position.root_id = this.region
	},
	methods: {
		...Vuex.mapMutations({
			setFormCalculatorRoof: 'calculator/setFormCalculatorRoof',
			deletePosition: 'calculator/deletePosition',
			savePosition: 'calculator/savePosition',
			setCalculateResponse: 'calculator/setCalculateResponse',
			setDefaultFormRoof: 'calculator/setDefaultFormRoof',
			setArrayCardProductColors: 'catalog/setArrayCardProductColors',
		}),
		...Vuex.mapActions({
			calculateRoof: 'calculator/calculateRoof',
			calculatePosition: 'calculator/calculatePosition',
			createPosition: 'basket/create',
			getStoreCalcRoof: 'calculator/getCalcRoof',
			searchNavbar: 'catalog/searchNavbar',
			getCardParameters: 'calculator/getCardParameters',
			getColors: 'catalog/getColors',
			getFilters: 'catalog/getFilters',
		}),
		checkSoffitParameters() {
            let ready = true
            let parameters = this.parameters
            let error_array = []
            if (parameters && parameters.length != 0) {
                parameters.map((element) => {
                    let name = element.base_name
                    let lower_name = name[0].toLowerCase() + name.substring(1)
    
                    lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    
                    lower_name = lower_name.trim()
                    if (lower_name == 'color') {
                        lower_name = 'colors'
                    }
                    if (element.value == true) {
                        if (!this.form[lower_name] && lower_name != 'colors' && (this.filters[lower_name] && !this.isEmptyObject(this.filters[lower_name])) ) {
                            ready = false
                            error_array.push(lower_name)
                        }
                        if (lower_name == 'colors') {
                            if (!this.form[lower_name] && this.form.coating == 'Оцинкованное' && ready == true) {
                                ready = true
                            }else if (!this.form[lower_name] && this.form.coating != 'Оцинкованное'){
                                error_array.push(lower_name)
                                ready = false
                            }
                        }
                    }
                })
            }else{
                ready = false
            } 
			this.parameterSoffitErrorArray = error_array
        },
		checkProductParameters() {
            let ready = true
            let parameters = this.parameters_products
            let error_array = []
            if (parameters && parameters.length != 0) {
                parameters.map((element) => {
                    let name = element.base_name
                    let lower_name = name[0].toLowerCase() + name.substring(1)
    
                    lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    
                    lower_name = lower_name.trim()
                    if (lower_name == 'color') {
                        lower_name = 'colors'
                    }
                    if (element.value == true) {
                        if (!this.formProducts[lower_name] && lower_name != 'colors' && (this.filters[lower_name] && !this.isEmptyObject(this.filters[lower_name])) ) {
                            ready = false
                            error_array.push(lower_name)
                        }
                        if (lower_name == 'colors') {
                            if (!this.formProducts[lower_name] && this.formProducts.coating == 'Оцинкованное' && ready == true) {
                                ready = true
                            }else if (!this.formProducts[lower_name] && this.formProducts.coating != 'Оцинкованное'){
                                error_array.push(lower_name)
                                ready = false
                            }
                        }
                    }
                })
            }else{
                ready = false
            } 
			this.parameterProductsErrorArray = error_array
        },
		getFixedPrice(price) {
            let fixedPrice = 0
            if (price) {
                fixedPrice = ( (price.toString().includes('.')) ? (price.toString().split('.').pop().length) : (0) )
            }
            return (fixedPrice >= 2 ? price.toFixed(2) : price)
        },
		save() {
			this.calculateForm.position = this.position
			this.calculateForm.soffit_position = this.soffit_position
			this.calculateForm.region_code = this.objectCurrentRegion.region_code
			this.calculateForm.region_description = this.objectCurrentRegion.region_description
			this.calculateForm.company_id = Number(this.userCompanyId)
			this.calculateForm.root_1c_id = this.region
			this.update()
			this.calculateRoof()
				.then((response) => {
					this.skat_1 = response.data.roof_response.skat_1
					this.skat_2 = response.data.roof_response.skat_2
					this.setArrayId1cFromResultPositions(response.data.order.positions)
				})
		},
		setArrayId1cFromResultPositions(array) {
			let current_array = []
			array.map((element) => {
				current_array.push(element.id_1c)
			})
			this.arrayId1cFromResultPositions = current_array
			this.setNomenclaturePositionsFromResult()
		},
		setNomenclaturePositionsFromResult() {
			let parent_idcs = []
			this.searchNavbar({root_id: this.region, id_1cs: this.arrayId1cFromResultPositions})
				.then((response) => {
					let array = response.data
					this.setOnPositionsBasketParentId(array)
					array.map((element) => {
						parent_idcs.push(element.parent_id)
					})
					this.searchNavbar({root_id: this.region, id_1cs: parent_idcs})
						.then((resp) => {
							this.arrayNomenclaturesResult = resp.data
						})
				})
		},
		setOnPositionsBasketParentId(array) {
			this.calculateResponse.order.positions.map((element) => {
				array.map((object) => {
					if (element.id_1c == object.id_1c) {
						element.parent_id = object.parent_id
					}
				})
			})
		},
		getCardStructure(id_1c) {
			let card_structure = {}
			this.arrayNomenclaturesResult.map((element) => {
				if (element.id_1c == id_1c) {
					card_structure = JSON.parse(element.card_structure)
				}
			})
			return card_structure
		},
		setPositionsDefault() {
			this.cornice = ''
			this.corniceId = null
			this.parameters = []
			this.parameters_products = []
			this.soffit_position = {
				id_1c: null,
				name: null,
				coating: '',
				color: null,
				color_external: null,
				color_inner: null,
				external_cladding_thickness: null,
				geometry: null,
				insulation_thickness: null,
				internal_knurling: null,
				internal_thickness: null,
				internal_lining_thickness: null,
				internal_surface_coating: null,
				knurling_on_the_outer_surface: null,
				length: null,
				maximum_length: null,
				metal_thickness: null,
				minimum_length: null,
				mounting_width: null,
				overall_width: null,
				price_group: null,
				price_unit: null,
				price: null,
				profile: null,
				rolling_side: null,
				rolling_the_inner_lining: null,
				rolling_the_outer_cladding: null,
				texture: null,
				thickness: null,
				type_item: null,
				wave_height: null,
				wave_length: null,
				width: null,
				count_base_unit_of_measure: 1,
				base_unit_of_measure: null,
				amount: 1,
				sell: 0,
				amount_in_progress: null,
				amount_in_stock: null,
				amount_shipped: null,
				amount_to_work: null,
				amount_balance: null,
				root_id: null,
				diameter: null,
				head_type: null,
				shelf_a: null,
				shelf_b: null,
				tip_type: null,
			}

			this.position = {
				id_1c: null,
				name: null,
				coating: '',
				color: null,
				color_external: null,
				color_inner: null,
				external_cladding_thickness: null,
				geometry: null,
				insulation_thickness: null,
				internal_knurling: null,
				internal_thickness: null,
				internal_lining_thickness: null,
				internal_surface_coating: null,
				knurling_on_the_outer_surface: null,
				length: null,
				maximum_length: null,
				metal_thickness: null,
				minimum_length: null,
				mounting_width: null,
				overall_width: null,
				price_group: null,
				price_unit: null,
				price: null,
				profile: null,
				rolling_side: null,
				rolling_the_inner_lining: null,
				rolling_the_outer_cladding: null,
				texture: null,
				thickness: null,
				type_item: null,
				wave_height: null,
				wave_length: null,
				width: null,
				count_base_unit_of_measure: 1,
				base_unit_of_measure: null,
				amount: 1,
				sell: 0,
				amount_in_progress: null,
				amount_in_stock: null,
				amount_shipped: null,
				amount_to_work: null,
				amount_balance: null,
				root_id: null,
				diameter: null,
				head_type: null,
				shelf_a: null,
				shelf_b: null,
				tip_type: null,
			}
		},
		setChangeElementIndex(val) {
			this.changeElementIndex = val
		},
		setDefaultViewMaterial() {
			if (this.material == 10) {
				this.calculateForm.view_material = this.metallArray[0].id_1c
			}else if (this.material == 20){
				this.calculateForm.view_material = this.profnastilArray[0].id_1c
			} 
			this.update() 
		},
		validate() {
			if (this.calculateForm.k) {
				this.calculateForm.k = Number(this.calculateForm.k)
			}
			if (this.calculateForm.m) {
				this.calculateForm.m = Number(this.calculateForm.m)
			}
			if (this.calculateForm.height_1) {
				this.calculateForm.height_1 = Number(this.calculateForm.height_1)
			}
			if (this.calculateForm.height_2) {
				this.calculateForm.height_2 = Number(this.calculateForm.height_2)
			}
			if (this.calculateForm.width_1) {
				this.calculateForm.width_1 = Number(this.calculateForm.width_1)
			}
			if (this.calculateForm.width_2) {
				this.calculateForm.width_2 = Number(this.calculateForm.width_2)
			}
			if (this.calculateForm.width_3) {
				this.calculateForm.width_3 = Number(this.calculateForm.width_3)
			}
			if (this.calculateForm.length_list_1) {
				this.calculateForm.length_list_1 = Number(this.calculateForm.length_list_1)
			}
			if (this.calculateForm.length_list_2) {
				this.calculateForm.length_list_2 = Number(this.calculateForm.length_list_2)
			}
			if (this.calculateForm.left_shift_1) {
				this.calculateForm.left_shift_1 = Number(this.calculateForm.left_shift_1)
			}
			if (this.calculateForm.left_shift_2) {
				this.calculateForm.left_shift_2 = Number(this.calculateForm.left_shift_2)
			}
		},
		setSoffitPositionParameters(product) {
			this.soffit_position.id_1c = product.id_1c
			this.soffit_position.name = product.name
			this.soffit_position.price = product.price
			this.soffit_position.overall_width = product.overall_width
			this.soffit_position.length = null
			this.soffit_position.count_base_unit_of_measure = 1
			this.soffit_position.sell = product.sell
			this.soffit_position.amount = 1
			this.soffit_position.root_id = this.region

			if (product.max_normative_length) {
				this.soffit_position.length = product.max_normative_length
			}else{
				this.soffit_position.length = 0
			}
		},
		setPositionParameters(product) {
			this.position.id_1c = product.id_1c
			this.position.name = product.name
			this.position.price = product.price
			this.position.overall_width = product.overall_width
			this.position.length = null
			this.position.count_base_unit_of_measure = 1
			this.position.sell = product.sell
			this.position.amount = 1
			this.position.root_id = this.region

			if (product.max_normative_length) {
				this.position.length = product.max_normative_length
			}else{
				this.position.length = 0
			}
		},
		update() {
			this.validate()
			this.setFormCalculatorRoof(this.calculateForm)
		},
		setRoofType(id) {
			this.calculateForm.roof_type = id
			this.update() 
		},
		setCorniceType(id, type) {
			this.cornice = type
			this.corniceId = id 
			this.setParameters(id)
			this.form.parent_id = id
			this.form.rolling_side = null
			this.form.coating = null
			this.form.thickness = null
			this.form.colors = null
			this.searchNavbar({root_id: this.region, parent_id: this.corniceId})
				.then((response) => {
					this.soffit_products = response.data
					this.getColorsProduct()
					this.getFilters(this.form)
						.then(() => {
							this.setColorsArray('soffit')
							this.checkSoffitParameters()
						})
				})
		},
		setMountingType(id) {
			this.calculateForm.mounting_type = id
			this.update() 
		},
		setViewMaterial(object) {
			this.calculateForm.view_material = object.id_1c
			this.setParametersProducts(object.id_1c)
			this.formProducts.parent_id = object.id_1c
			this.formProducts.rolling_side = null
			this.formProducts.coating = null
			this.formProducts.thickness = null
			this.formProducts.colors = null
			this.searchNavbar({root_id: this.region, parent_id: object.id_1c})
				.then((response) => {
					this.products = response.data
					this.getFilters(this.formProducts)
						.then(() => {
							this.setColorsArray('product')
							this.checkProductParameters()
						})
				})
			this.update() 
		},
		// searchChildInsulationArray() {
		// 	this.searchNavbar({root_id: this.region, parent_id: this.currentInsulation})
		// 		.then((response) => {
		// 			this.childInsulationArray = response.data
		// 			if (this.childInsulationArray.length != 0) {
		// 				this.calculateForm.heater = this.childInsulationArray[0].id_1c 
		// 			}else{
		// 				this.calculateForm.heater = this.currentInsulation
		// 			}
		// 		})
		// },
		setIsHeat(val) {
			this.calculateForm.is_heat = val
			this.update()
		},
		updateInsulation() {
			// this.calculateForm.heater = this.currentInsulation
			// this.searchChildInsulationArray()
			this.update()
		},
		updateCalculatorPosition() {

		},
		changeAmountInsulationThickness(type) {
			this.calculateForm.insulation_thickness = Number(this.calculateForm.insulation_thickness)
			if (type == 'plus') {
				this.calculateForm.insulation_thickness += 1
			}else if (type == 'minus') {
				if (this.calculateForm.insulation_thickness == 1) {
					return
				}
				this.calculateForm.insulation_thickness -= 1
			}
			this.update()
		},
		updatePosition(element, index, color, elementName) {
			if (elementName) {
				this.savePosition(
				{
					index: index,
					id_1c: element.id_1c,
					coating: element.coating,
					thickness: element.thickness,
					overall_width: element.overall_width,
					images: element.links,
					color: color,
					name: elementName,
				})
			}else{
				this.savePosition(
					{
						index: index,
						id_1c: element.id_1c,
						coating: element.coating,
						thickness: element.thickness,
						overall_width: element.overall_width,
						images: element.links,
						color: color,
					})
			}
			this.calculate(index)
		}, 
		calculate(index) {
			this.calculateResponse.order.positions.map((el, idx) => {
				if (idx == index) {	
					this.calculatePosition({position: el, index: index})
						.then((response) => {
							el.price = response.data.positions[0].price
							el.sell = response.data.positions[0].sell
						})
				}
			})
		},
		addInBasket() {
			let array = []
			this.calculateResponse.order.positions.map((element) => {
				if (element.id_1c) {
					array.push(element)
				}
			})
			
			let source_utm = JSON.parse(localStorage.getItem("utm_source"))
			this.createPosition(
				{
					positions: array,
					region_code: this.objectCurrentRegion.region_code, 
					region_description: this.objectCurrentRegion.region_description,
					utm: source_utm,
				})
				.then((response) => {
					localStorage.setItem('mayakOrderId', JSON.stringify(response.data.id))
					let that = this
					setTimeout(() => {
						that.push('basket')
					}, 1000)
				})
		},
		getValues() {
			this.getStoreCalcRoof(this.region)
				.then(() => {
					if (this.calc_roof_body.metal_tile.length != 0) {
						this.searchNavbar({root_id: this.region, id_1cs: this.calc_roof_body.metal_tile, componentFrom: 'calculator roof, metallArray'})
							.then((response) => {
								this.metallArray = response.data
							})
					}else{
						this.metallArray = []
					}
					if (this.calc_roof_body.corrugated_board.length != 0) {
						this.searchNavbar({root_id: this.region, id_1cs: this.calc_roof_body.corrugated_board})
							.then((response) => {
								this.profnastilArray = response.data
							})
					}else{
						this.profnastilArray = []
					}
				})
		}, 
		setParameters(id_1c) {
            this.parameters = []
            this.getCardParameters({ root_id: this.region, id_1c: id_1c})
                .then((response) => {
					if (response.data[0]) {
						this.parameters = JSON.parse(response.data[0].card_structure)
					}
                })
        },
		setParametersProducts(id_1c) {
            this.parameters_products = []
            this.getCardParameters({ root_id: this.region, id_1c: id_1c})
                .then((response) => {
					if (response.data[0]) {
						this.parameters_products = JSON.parse(response.data[0].card_structure)
					}
                })
        },
		setColorSoffit(cardColor) {
			this.form.colors = cardColor.id_1c
			this.soffit_position.color = cardColor.id_1c
		},
		setColorPosition(cardColor) {
			this.formProducts.colors = cardColor.id_1c
			this.position.color = cardColor.id_1c
		},
		changeAmountMaxLengthList(type, num) {
			if (num == 1) {	
				if (type == 'plus') {
					if (this.calculateForm.length_list_1 == 0 || this.calculateForm.length_list_1 == null) {
						this.calculateForm.length_list_1 = 1
					}else{
						this.calculateForm.length_list_1 += 1
					}
				}else if (type == 'minus') {
					if (this.calculateForm.length_list_1 == 0 || this.calculateForm.length_list_1 == null || this.calculateForm.length_list_1 == 1) {
						this.calculateForm.length_list_1 = 1
					}else{
						this.calculateForm.length_list_1 -= 1
					}
				}
			}else if (num == 2) {
				if (type == 'plus') {
					if (this.calculateForm.length_list_2 == 0 || this.calculateForm.length_list_2 == null) {
						this.calculateForm.length_list_2 = 1
					}else{
						this.calculateForm.length_list_2 += 1
					}
				}else if (type == 'minus') {
					if (this.calculateForm.length_list_2 == 0 || this.calculateForm.length_list_2 == null || this.calculateForm.length_list_2 == 1) {
						this.calculateForm.length_list_2 = 1
					}else{
						this.calculateForm.length_list_2 -= 1
					}
				}
			}
			this.update() 
		},
		changeAmountShiftSkat(type, num) {
			if (num == 1) {	
				if (type == 'plus') {
					if (this.calculateForm.left_shift_1 == 0 || this.calculateForm.left_shift_1 == null) {
						this.calculateForm.left_shift_1 = 0.01
					}else{
						this.calculateForm.left_shift_1 += 0.01
					}
				}else if (type == 'minus') {
					if (this.calculateForm.left_shift_1 == 0 || this.calculateForm.left_shift_1 == null || this.calculateForm.left_shift_1 == 1) {
						this.calculateForm.left_shift_1 = 0.01
					}else{
						this.calculateForm.left_shift_1 -= 0.01
					}
				}
				this.calculateForm.left_shift_1 = this.calculateForm.left_shift_1.toFixed(2)
			}else if (num == 2) {
				if (type == 'plus') {
					if (this.calculateForm.left_shift_2 == 0 || this.calculateForm.left_shift_2 == null) {
						this.calculateForm.left_shift_2 = 0.01
					}else{
						this.calculateForm.left_shift_2 += 0.01
					}
				}else if (type == 'minus') {
					if (this.calculateForm.left_shift_2 == 0 || this.calculateForm.left_shift_2 == null || this.calculateForm.left_shift_2 == 1) {
						this.calculateForm.left_shift_2 = 0.01
					}else{
						this.calculateForm.left_shift_2 -= 0.01
					}
				}
				this.calculateForm.left_shift_2 = this.calculateForm.left_shift_2.toFixed(2)
			}
			this.update() 
		},
		drawRaskladka() {
			this.setRedLines()
			this.setBlueLines()
		},
		getSoffitProducts(str) {
			if (str) {
				if (str == 'color') {
					this.form.colors = this.newPosition[str]
				}else{
					this.form[str] = this.newPosition[str]
				}
				this.changeParameter = str
            }else{
                this.form.parent_id = this.corniceId
                this.form.root_id = this.region
                this.form.is_show_zero_price = false
                this.form.is_group = false
            }

			this.searchNavbar(this.form)
				.then((response) => {
					this.soffit_products = response.data
					this.setSoffitPositionParameters(response.data[0])
					if (response.data.length != 0) {
                        this.getColorsProduct()
						this.getFilters(this.form)
							.then(() => {
								this.setColorsArray('soffit')
								this.checkSoffitParameters()
							})
                    }
                    this.isShowList = true
				})
		},
		setColorsArray(str) {
            let array = []
            let local_filters = this.filters['colors']
            for (let property in local_filters) {
                array.push(local_filters[property])
            }
			if (str == 'soffit') {
				this.soffitColorsArray = array
			}else if (str == 'product') {
				this.productColorsArray = array
			}
        },
		getColorsProduct() {
            let colors = ["0"]
			this.soffit_products.map((element) => {
				if (Array.isArray(element.colors)) {
					element.colors.map((el) => {
						if (!this.isIssetColor(colors, el)) {
							colors.push(el)
						}
					})
				}
			})
            this.getColors(colors)
        },
		isIssetColor(array, value) {
            let response = false
            array.map((element) => {
                if (element == value) {
                    response = true
                }
            })
            return response
        },
		getProducts() {
			this.searchNavbar(this.formProducts)
				.then((response) => {
					this.products = response.data
					this.setPositionParameters(response.data[0])
					this.getFilters(this.formProducts)
						.then(() => {
							this.setColorsArray('product')
							this.checkProductParameters()
						})
				})
		},
		changeActiveSkat(num) {
			this.numActiveSkat = num
			this.updateRaskladka()
		},
		updateRaskladka() {
            this.redlines = [] 
            this.bluelines = []
            this.structure = []
            this.arrayTexts = []

            this.drawLists()
            this.drawRaskladka()
        },
		setRedLines() {
			let xcellcount = this.calculateForm.width_1 + 3
			let ycellcount = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2) + 2

			// if (this.calculateForm.roof_type == 50 || this.calculateForm.roof_type == 20 || this.calculateForm.roof_type == 30) {
			// 	ycellcount = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2) + 2
			// }else{
			// 	if (this.numActiveSkat == 1) {
			// 		ycellcount = this.calculateForm.height_1 + 2
			// 	}else if (this.numActiveSkat == 2) {
			// 		ycellcount = this.calculateForm.height_2 + 2
			// 	}
			// }

			let widthforraskladka = 600
			let cellwh = widthforraskladka / xcellcount
			let CentrOsX = 1.5 * cellwh
			let CentrOsY = (ycellcount - 1)*cellwh

			this.redlines = []
			if (this.calculateForm.roof_type == 10) {
				this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
				this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
				this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
				this.redlines.push({x1: CentrOsX, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY})
			}else if (this.calculateForm.roof_type == 20) {
				if (this.numActiveSkat == 1) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY})
				}else if (this.numActiveSkat == 2) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY - this.calculateForm.height_2 * cellwh, x2: CentrOsX, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY - this.calculateForm.height_2 * cellwh, x2: CentrOsX, y2: CentrOsY})
				}
			}else if (this.calculateForm.roof_type == 30) {
				let trap_a1 = (this.calculateForm.width_1 - this.calculateForm.width_3) * 500
				let tr_a1 = this.calculateForm.width_2 * 500
				// let valm_height = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2)
				if (this.numActiveSkat == 1) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + trap_a1 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX + trap_a1 / 1000 * cellwh, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX + trap_a1 / 1000 * cellwh + this.calculateForm.width_3 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + trap_a1 / 1000 * cellwh + this.calculateForm.width_3 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
				}else if (this.numActiveSkat == 2) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_2 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + tr_a1 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_2 * cellwh, y1: CentrOsY, x2: CentrOsX + tr_a1 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
				}
			}else if (this.calculateForm.roof_type == 40) {
				let tr_a1_1 = this.calculateForm.width_1 * 500
				let tr_a1_2 = this.calculateForm.width_2 * 500
				if (this.numActiveSkat == 1) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + tr_a1_1 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + tr_a1_1 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
				}else if (this.numActiveSkat == 2) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_2 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + tr_a1_2 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_2 * cellwh, y1: CentrOsY, x2: CentrOsX + tr_a1_2 / 1000 * cellwh, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
				}
			}else if (this.calculateForm.roof_type == 50) {
				// ycellcount = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2) + 2

				if (this.numActiveSkat == 1) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY - this.calculateForm.height_1 * cellwh , x2: CentrOsX, y2: CentrOsY - this.calculateForm.height_1 * cellwh})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY - this.calculateForm.height_1 * cellwh, x2: CentrOsX, y2: CentrOsY})
				}else if (this.numActiveSkat == 2) {
					this.redlines.push({x1: CentrOsX, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY, x2: CentrOsX + this.calculateForm.width_1 * cellwh, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
					this.redlines.push({x1: CentrOsX + this.calculateForm.width_1 * cellwh, y1: CentrOsY - this.calculateForm.height_2 * cellwh, x2: CentrOsX, y2: CentrOsY - this.calculateForm.height_2 * cellwh})
					this.redlines.push({x1: CentrOsX, y1: CentrOsY - this.calculateForm.height_2 * cellwh, x2: CentrOsX, y2: CentrOsY})
				}
			}
		},
		setBlueLines() {
			let xcellcount = this.calculateForm.width_1 + 3
			let ycellcount = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2) + 2
			// let ycellcount = this.calculateForm.height_1 + 2

			let widthforraskladka = 600
			let cellwh = widthforraskladka / xcellcount
			let CentrOsX = 1.5 * cellwh
			let CentrOsY = (ycellcount - 1) * cellwh
			let ysmeshenie = 0

			this.bluelines = []
			this.bluelines.push({x1: CentrOsX, y1: 0 + ysmeshenie, x2: CentrOsX, y2: ycellcount * cellwh + ysmeshenie})
			this.bluelines.push({x1: 0, y1: CentrOsY + ysmeshenie, x2: xcellcount * cellwh, y2: CentrOsY + ysmeshenie})
		},
		drawLists() {
			// let xcellcount = (this.numActiveSkat == 1 ? this.calculateForm.width_1 : this.calculateForm.width_2) + 3
			let xcellcount = this.calculateForm.width_1 + 3
			// let ycellcount = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2) + 2
			let ycellcount = this.calculateForm.height_1 + 2
			// if (this.numActiveSkat == 1) {
			// 	ycellcount = this.calculateForm.height_1 + 2
			// }else if (this.numActiveSkat == 2) {
			// 	ycellcount = this.calculateForm.height_2 + 2
			// }

			if (this.calculateForm.roof_type != 10) {
				ycellcount = (this.calculateForm.height_1 > this.calculateForm.height_2 ? this.calculateForm.height_1 : this.calculateForm.height_2) + 2
			}

			let widthforraskladka = 600
			let cellwh = widthforraskladka / xcellcount
			let CentrOsX = 1.5 * cellwh
			let CentrOsY = (ycellcount - 1)*cellwh
			this.heightraskladka = ycellcount * cellwh - cellwh + 70
			var x1,y1,x2,y2
			if (this.numActiveSkat == 1) {
				if(this.skat_1.arr_listy_classic != null) {
					for (let r = 0; r < this.skat_1.arr_listy_classic.length; r++)
					{
						let mj_arlisty = this.skat_1.arr_listy_classic[r]
						let mj_arlisty_len = this.skat_1.arr_listy_classic_len[r]
	
						let lcounter = 0
						for (let i = 0; i < mj_arlisty.length; i++) {
							x1 = CentrOsX + mj_arlisty[i] * cellwh;
							y1 = CentrOsY + mj_arlisty[i+1]* cellwh;
							x2 = CentrOsX + mj_arlisty[i+2]* cellwh;
							y2 = CentrOsY + mj_arlisty[i+3]* cellwh;
	
							this.structure.push({x1: x1, y1: y1, x2: x2, y2: y1})
							this.structure.push({x1: x2, y1: y1, x2: x2, y2: y2})
							this.structure.push({x1: x2, y1: y2, x2: x1, y2: y2})
							this.structure.push({x1: x1, y1: y2, x2: x1, y2: y1})
	
							this.arrayTexts.push({ x: x1 - (x1-x2)/1.5, y: y1 - (y1-y2)/2, text: mj_arlisty_len[lcounter] + ""});
							i+=3;
							lcounter ++;
	
						}
					}
				}
			}else if (this.numActiveSkat == 2) {

				if(this.skat_2.arr_listy_classic != null) {
					for (let r = 0; r < this.skat_2.arr_listy_classic.length; r++)
					{
						let mj_arlisty = this.skat_2.arr_listy_classic[r]
						let mj_arlisty_len = this.skat_2.arr_listy_classic_len[r]
	
						let lcounter = 0
						if (mj_arlisty.length != 0) {
							for (let i = 0; i < mj_arlisty.length; i++) {
								x1 = CentrOsX + mj_arlisty[i] * cellwh;
								y1 = CentrOsY + mj_arlisty[i+1]* cellwh;
								x2 = CentrOsX + mj_arlisty[i+2]* cellwh;
								y2 = CentrOsY + mj_arlisty[i+3]* cellwh;
		
								this.structure.push({x1: x1, y1: y1, x2: x2, y2: y1})
								this.structure.push({x1: x2, y1: y1, x2: x2, y2: y2})
								this.structure.push({x1: x2, y1: y2, x2: x1, y2: y2})
								this.structure.push({x1: x1, y1: y2, x2: x1, y2: y1})
		
								this.arrayTexts.push({ x: x1 - (x1-x2)/1.5, y: y1 - (y1-y2)/2, text: mj_arlisty_len[lcounter] + ""});
								i+=3;
								lcounter ++;
		
							}
						}
					}
				}
			}
		},
		currentNameParameter(name) {
            let lower_name = name[0].toLowerCase() + name.substring(1)

            lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)

            lower_name = lower_name.trim()
			if (lower_name == 'color') {
				lower_name = 'color_external'
			}
            return lower_name
        },
	}	
}
</script>
<style scoped lang="css">
	.cl-wrapper__gray{
		flex-direction: column;
		align-items: flex-start !important;
	}
	.cl-wrapper__gray > *{
		margin-bottom: 1.5rem;
	}
	.block_skat{
		border: 1px solid #d3d4d9;
		border-radius: 5px;
		padding: 3.5rem;
	}
	.block_skat_active{
		border: 1px solid #EA292E;
	}

	.input_skats::-webkit-outer-spin-button,
	.input_skats::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
	}
	.skat_left_block{
		width: 55rem !important;
	}
</style>
<style lang="scss">
	.mw-25{
		.choices__inner{
			width: 35rem;

			.choices__list--single{
				.choices__item {
					max-width: 34rem;

					&:after{
						right: 17px !important;
					}
				}
			}
		}
	}
	.front_calculatorRoof_page{
		.cl-grid__left{
			max-width: 46rem !important;
		}
	}
</style>
<style scoped src="../../../css/nlk-style.css"></style>