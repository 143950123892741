<template>
    <section class="about_company-info_page-sp_map">
        <div class="map_block">
            <!-- <img src="@/img/info-sp/map.png" alt=""> -->

            <!-- <yandex-map
                id="map"
                :coords="center"
                :settings="settings"
                :zoom="zoom"
                :scroll-zoom="false"
            >
            <ymapMarker 
                    v-for="(gmp, index) in currentAddresses"
                    :key="index"
                    :coords="[gmp.lat, gmp.lng]"
                    :markerId="index"
                    :options="{ visible: gmp.visible }" 
                    @click="center = [gmp.lat, gmp.lng]"
                />
            </yandex-map> -->
        </div>
    </section>
</template>
<script>
import Vuex from 'vuex'
// import { yandexMap, ymapMarker } from 'vue-yandex-maps'

export default {
    components: {
        // yandexMap, 
        // ymapMarker,
    },
    data () {
        return {
            center: [ 52.56543518842296, 70.05213952539054 ],
            zoom: 4,
            settings: {
                apiKey: '72b24e1d-06ad-424f-af9b-44d28d93d4d6',
                lang: 'ru_RU',
                coordorder: 'latlong',
                enterprise: false,
                version: '2.1'
            },
            markerIcon: {
                layout: 'default#imageWithContent',
                imageHref: require('@/assets/img/map-marker.svg'),
                imageSize: [43, 43],
                imageOffset: [0, 0],
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            // contacts: 'app/contacts',
        }),
        // currentAddresses() {
        //     let addresses = []
        //     if (this.contacts.addresses) {
        //         addresses = this.contacts.addresses
        //     }
        //     return addresses
        // },
    },
}
</script>