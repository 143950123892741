<template>
    <section class="about_company-video_block">
        <div class="container container-nd">
            <p class="main_header">Видео</p>
            <div class="videos">
                <div
                    class="modal-iframe"
                >
                    <iframe  
                        src="https://www.youtube.com/embed/qp-I67_JKGY?si=mloipZBbu2lYFylf" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>

                <div
                    class="modal-iframe"
                >
                    <iframe  
                        src="https://www.youtube.com/embed/gdTAHhrTlJk?si=hkDyg5PceYVxCmqw" 
                        title="YouTube video player" 
                        frameborder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>