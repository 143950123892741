<template>
	<main class="main">
		<div class="section section--no-pt section--no-pb section-nlk section-cl">
			<div class="container">
				<div class="inner">
					<ul class="list list--flex breadcrumbs cl-breadcrumbs">
						<li class="list-item" @click="push('')">
							<a class="list-link">
								<svg class="icon" width="16" height="16" viewBox="0 0 16 16">
									<use xlink:href="@/assets/img/icons.svg#home"></use>
								</svg>
							</a>
						</li>
						<li class="list-item"><span class="list-current">Калькулятор ограждения</span></li>
					</ul>
					<form
						onsubmit="return false"
					>
						<h2 class="cl__h2-title"><span>1</span> Выберите тип забора</h2>
						
						<!-- <p 
							class="fs-14 max-1180 mb-30"
							v-if="adminCalculatorFence.step1" 
							v-html="adminCalculatorFence.step1.description"
						/> -->

						<div class="cl-top-option nlk-horizontal-scroll mb-70">
							<label 
								class="cl-icon-radio"
								v-for="fenceType in fenceTypes"
								:key="fenceType.id"
								@click="setFenceType(fenceType.id)"
							>
								<input 
									type="radio" 
									name="option1" 
									:checked="(calculateForm.fence_type == fenceType.id ? true : false)"
								>
								<div class="cl-icon-radio__icon">
									<img :src="require('@/assets/img/option2/o' + fenceType.num + '.svg')" class="cl-icon-radio__img-1">
									<img :src="require('@/assets/img/option2/o' + fenceType.num + '-a.svg')" alt="" class="cl-icon-radio__img-2">
								</div>
								<div class="cl-icon-radio__btn">{{ fenceType.name }}</div>
							</label>
						</div>

						<h2 class="cl__h2-title"><span>2</span> Выберите профиль для расчёта</h2>

						<!-- <p 
							class="fs-14 max-1180 mb-30"
							v-if="adminCalculatorFence.step2" 
							v-html="adminCalculatorFence.step2.description"
						/> -->

						<div 
							class="cl-car mb-60"
							v-if="calculateForm.fence_type == 10"
						>
							<div class="cl-car__wrapper">
								<div 
									class="cl-car-item"
									:class="{'active_card': choosenMaterial && choosenMaterial.id_1c == profnastil.id_1c}"
									v-for="profnastil in profnastilArray"
									:key="profnastil.id"
									@click="setViewMaterial(profnastil)"
								>
									<div class="cl-car-item__img">
										<img 
											:src="yandexCloud + profnastil.preview" 
											:style="choosenMaterial && choosenMaterial.id_1c == profnastil.id_1c && currentColor? 'background-color: ' + currentColor.code : 'background-color: rgb(199, 199, 197);'"
											alt=""
										>
									</div>
									<span>{{ profnastil.name }}</span>
								</div>
							</div>
						</div>
						
						<div 
							class="cl-car mb-60"
							v-if="calculateForm.fence_type == 20"
						>
							<div class="cl-car__wrapper">
								<div 
									class="cl-car-item"
									:class="{'active_card': choosenMaterial && choosenMaterial.id_1c == stack.id_1c}"
									v-for="stack in stackArray"
									:key="stack.id"
									@click="setViewMaterial(stack)"
								>
									<div class="cl-car-item__img">
										
										<img 
											:src="yandexCloud + stack.preview" 
											:style="choosenMaterial && choosenMaterial.id_1c == stack.id_1c && currentColor ? 'background-color: ' + currentColor.code : 'background-color: rgb(199, 199, 197);'" 
											alt=""
										>
									</div>
									<span>{{ stack.name }}</span>
								</div>
							</div>
						</div>
						
						<div 
							v-if="parameters.length != 0 && choosenMaterial"
							class="position_parameters mb-10"
						>
							<single-parameter 
								v-for="parameter in parameters" 
								:key="parameter.sort" 
								v-show="parameter.value == true && parameter.name != 'Длина'"
								:parameter="parameter" 
								:product="products[0]"
								:form="form"
								:typeCard="'category'"
								:isSubSign="choosenMaterial.is_sub_sign"
								:subElementsProuducts="products"
								:localArrayColors="productColorsArray"
								:parameterErrorArray="parameterErrorArray"
								@getSubElements="getProducts()"
								@updateCalculatorPosition="updateCalculatorPosition"
								@setPositionColor="setColor"
							/>
						</div>

						<div class="cl-grid">
							<div class="cl-grid__left">
								<h2 class="cl__h2-title"><span>3</span> Введите размеры вашего забора</h2>

								<!-- <p 
									class="fs-14 max-1180 mb-30"
									v-if="adminCalculatorFence.step3" 
									v-html="adminCalculatorFence.step3.description"
								/> -->

								<label class="cl-input-group mb-30 overh">
									<input 
										type="number" 
										step="0.01"
										required
										v-model="calculateForm.perimeter"
										@keyup="update"
										@focus="inputFocusName = 'p'"
									>
									<span data-title="Периметр участка с воротами и калиткой, м" class="max_width_span_text">p</span>
								</label>

								<label class="cl-input-group mb-50">
									<input 
										type="number" 
										step="0.01"
										required
										v-model="calculateForm.height"
										@keyup="update"
										@focus="inputFocusName = 'h'"
									>
									<span data-title="Высота забора, м">h</span>
								</label>

								<label 
									class="cl-check mb-20"
									v-if="calculateForm.fence_type != 10"
								>
									<input 
										type="checkbox" 
										v-model="calculateForm.is_spacing_panks"
										@change="update"
									>
									<span>Указать расстояние между планками штакетника?</span>
								</label>
								
								<label 
									class="cl-input-group cl-input-group_no mb-80"
									v-if="calculateForm.is_spacing_panks && calculateForm.fence_type == 20"
								>
									<input 
										type="number"
										step="0.01" 
										min="0.01"
										required
										v-model="calculateForm.spacing_panks"
										@change="update()"
									>
									<span data-title="Расстояние между планками штакетника, м">a</span>
								</label>
								
								<h2 
									v-if="calculateForm.fence_type == 20"
									class="cl__h2-title mb-50"
								>
									Тип установки
								</h2>

								<div 
									v-if="calculateForm.fence_type == 20"
									class="cl-row mb-50"
								>
									<label 
										class="cl-rowbtn-radio cl-rowbtn-radio_medium cl-row-group_mr-23"
										@click="setDouble(false)"
									>
										<input 
											type="radio" 
											name="btn2" 
											:checked="(calculateForm.double == false ? true : false)"
										>
										<span>
											С одной стороны
										</span>
									</label>
									<label 
										class="cl-rowbtn-radio cl-rowbtn-radio_medium cl-row-group_mr-23"
										@click="setDouble(true)"
									>
										<input 
											type="radio" 
											name="btn2"
											:checked="(calculateForm.double == true ? true : false)"
										>
										<span>
											С двух сторон
										</span>
									</label>
								</div>
								
								<label 
									class="cl-input-group cl-input-group_no mb-80"
								>
									<input 
										type="number"
										step="1"
										min="2"
										max="6" 
										required
										v-model="calculateForm.amount_vein"
										@change="update"
									>
									<span data-title="Количество прожилин на пролет шт"></span>
								</label>

								<h2 class="cl__h2-title mb-50">Нужны ли металлические столбы</h2>
								<div class="cl-row mb-50">
									<label 
										class="cl-rowbtn-radio cl-rowbtn-radio_medium cl-row-group_mr-23"
										@click="setIsMetalPoles(true)"
									>
										<input 
											type="radio" 
											name="btn3" 
											:checked="(calculateForm.is_metal_poles == true ? true : false)"
										>
										<span>
											Да
										</span>
									</label>
									<label 
										class="cl-rowbtn-radio cl-rowbtn-radio_medium cl-row-group_mr-23"
										@click="setIsMetalPoles(false)"
									>
										<input 
											type="radio" 
											name="btn3"
											:checked="(calculateForm.is_metal_poles == false ? true : false)"
										>
										<span>
											Нет
										</span>
									</label>
								</div>

								<label
									v-if="calculateForm.is_metal_poles" 
									class="cl-input-group cl-input-group_no mb-30"
								>
									<input 
										type="number"
										step="0.1" 
										min="2"
										max="3"
										required
										v-model="calculateForm.distance_pole"
										@change="update"
									>
									<span data-title="Расстояние между столбами, м"></span>
								</label>

								<label 
									v-if="calculateForm.is_metal_poles"
									class="cl-input-group cl-input-group_no"
								>
									<input 
										type="number"
										step="0.1"
										min="1"
										max="6"
										required
										v-model="calculateForm.height_pole"
										@change="update"
									>
									<span data-title="Высота столба, м"></span>
								</label>
							</div>
							<div class="cl-grid__right pt-160">
								<img :src="require('@/assets/img/calculators/fence/' + getCalculatorImgFullName)" alt="">
							</div>
							
						</div>

						<div class="cl-hr"></div>

						<button 
							class="button button--primary w-205"
							type="button"
							@click="save"
						>
							Рассчитать
						</button>

						<div class="cl-hr"></div>
					</form>
					<div 
						class="sticky-grid mt-80"
						v-if="!isEmptyObject(calculateResponse)"
					>
						<div class="sticky-grid__content">
							<div 
								class="cl-prod-item nlk-horizontal-scroll"
								v-for="(position, idx) in calculateResponse.positions"
								:key="idx"	
							>
								<div class="cl-prod-item-row cl-prod-item-row_full p-40">
									
									<div class="cl-prod-item__img mr-70">
										
									</div>
									<div class="cl-prod-item__text">
										<div class="cl-prod-item__title">{{ position.name }}</div>
										<ul class="cl-prod-item__list">
											<li v-if="position.coating">Покрытие: {{ position.coating }}</li>
											<li v-if="position.thickness">Толщина, мм: {{ position.thickness }}</li>
											<li v-if="position.length">Длина листа: {{ getTrueBigSum(position.length / 1000) }} м.пог</li>
											<li v-if="position.color_external">Цвет: {{ position.color_external }}</li>
										</ul>
									</div>
									<div class="cl-prod-item__last pr-40">
										<div class="cl-prod-item__price">{{ position.price || 0 }} {{ regionCurrency[regionCode].symbol }}</div>
										<button
											class="btn-close"
											@click="deletePosition(idx)" 
										/>
									</div>
								</div>
								<div class="cl-prod-item-row cl-prod-item-row_bottom flex_jcsb p-32">
									<div class="cl-prod-item__col">Количество, шт: {{ position.amount }}</div>
									<!-- <div class="cl-prod-item__col">Площадь, м2: 38.35</div> -->
									<div class="cl-prod-item__all">
										<div class="cl-prod-item__price fs-18">{{ position.sell || 0 }} {{ regionCurrency[regionCode].symbol }}</div>
									</div>
								</div>

								<!-- <div class="cl-prod-item-row cl-prod-item-row_bottom flex_jcsb">
									<div class="cl-prod-item__col"></div>
									<div class="cl-prod-item__all">
										<div class="cl-prod-item__lab">Итого:</div>
										<div class="cl-prod-item__price">50 871.57 {{ regionCurrency[regionCode].symbol }}</div>
									</div>
								</div> -->
							</div>

							<div class="cal-seo mt-120">
								<h3 class="cal-seo__h3">Как посчитать погонный метр забора?</h3>
								<p>Мы рекомендуем ответственно подойти к выбору водосточной системы. Наш удобный в использовании калькулятор водостока позволит вам рассчитать в режиме онлайн стоимость материала, узнать виды и количество элементов, которые понадобятся для организации дождевого стока.</p>
								<p>Если вы затрудняетесь в проведении расчетов или хотите уточнить цены, закажите обратный звонок. Опытные менеджеры Grand Line предоставят профессиональную консультацию по всем интересующим вопросам.</p>
								<p>Программа дополнительно автоматически рассчитывает схему резки желобов по всему периметру крыши. Широкая цветовая гамма позволит подобрать идеальный цвет для вашего водостока.</p>
								<p>Перед началом работы с калькулятором проведите замер кровли (укажите ширину ската, высоту от грунта до карниза, длину карнизного свеса). Любые замеры (вне зависимости от сложности геометрии) следует производить высококвалифицированному специалисту.</p>
								<p>Геометрия кровли влияет на количество элементов водостока. Grand Line осуществляет продажу надежных и высококачественных водостоков для:</p>
								<p>скатной кровли (односкатная / двускатная, вальмовая, шатровая, мансардная); <br>плоской кровли. <br>Ливневая система в плоской крыше, как и в скатной, снабжена водосточными трубами, соединительными элементами, воронками. В частных домах, коттеджах, многоэтажных домах устанавливают наружный водосток (вдоль одной стороны фасада или точечный).</p>
								<p>Для дачных домов, небольших коттеджей подходят водостоки 125х90. Для средних и крупных коттеджей используют системы водостоков 150х100.</p>
								<p>Расчет осуществляется с учетом коротких обрезков или без них.</p>
							</div>
						</div>
						<div class="sticky-grid__aside">
							<div class="sticky-box">
								<div class="sticky-box__title">Счет / заказ</div>
								<ul class="sticky-box__list">
									<li>Площадь ограждения <span>{{ calculateResponse.fence_option.fencing_area }}</span></li>
									<li>Площадь листов полная, м2 <span>{{ calculateResponse.fence_option.sheet_area_total }}</span></li>
									<li>Площадь листов полезная, м2 <span>{{ calculateResponse.fence_option.useful_sheet_area }}</span></li>
									<li>Ширина листа полная, мм <span>{{ calculateResponse.fence_option.full_sheet_width }}</span></li>
									<li>Ширина листа полезная, мм <span>{{ calculateResponse.fence_option.useful_sheet_width }}</span></li>
								</ul>

								<div class="sticky-box__bottom">
									Общая стоимость <b>{{ fullCost }} {{ regionCurrency[regionCode].symbol }}</b>
								</div>

								<button 
									class="button button--primary"
									type="button"
									:disabled="!isCanBasket"
									@click="addInBasket"
								>
									Добавить в корзину
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
// import singlePosition from './singlePosition.vue'
import singleParameter from '@/components/pages/catalog/single-parameter.vue'

export default {
	mixins: [Functions],
	components: {
		// singlePosition,
		singleParameter,
	},
	data() {
		return {
			fenceTypes: [
				{id: 10, name: 'Профнастил', num: 1},
				{id: 20, name: 'Штакетник', num: 2},
			],
			profnastilAltArray: [
				{id: 10, name: 'classic'},
				{id: 20, name: 'modern'},
			],
			material: 10,
			profnastilArray: [],
			stackArray: [],
			choosenMaterial: null,
			metallArray: [
				{id: 10, name: "monterrey classic (шаг 350 высота 15)"},
				{id: 20, name: "monterrey ultra (шаг 200 высота 15)"},
				{id: 30, name: "monterrey max (шаг 400 высота 15)"},
				{id: 40, name: "supermonterrey classic (шаг 350 высота 21)"},
				{id: 50, name: "supermonterrey max (шаг 400 высота 21)"},
				{id: 60, name: "supermonterrey ultra (шаг 200 высота 21)"}
			],
			mountingArray: [
				{id: 10, name: "2 j-профиль"},
				{id: 20, name: "Угол"},
			],
			changeElementIndex: null,
			inputFocusName: null,

			products: [],
			parameters: [],

			form: {
				parent_id: null,
                coating: null,
                colors: "",
                thickness: null,
                is_show_zero_price: false,
                is_group: false,
				root_id: null
            },
			currentSpasingSpanks: null,

			isShowList: false,
			changeParameter: null,
			productColorsArray: [],
			currentColor: {},

			parameterErrorArray: [],
		}
	},	
	computed: {
		...Vuex.mapGetters({
			formCalculatorFence: 'calculator/formCalculatorFence',
			calculateResponse: 'calculator/calculateResponse',
			order: 'basket/order',
			yandexCloud: 'app/yandexCloud',
			regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
			calculators: 'app/calculators',
			region: 'auth/city',
			calc_fence_body: 'calculator/calc_fence_body',
			newPosition: 'basket/newPosition',
			regionAdresses: 'app/regionAdresses',
			filters: 'catalog/filters',
			userCompanyId: 'auth/userCompanyId',
		}),
		calculateForm() {
			return Object.assign({}, this.formCalculatorFence)
		},
		calculatorFenceType() {
			return this.calculateForm.fence_type
		},
		fullCost() {
			let cost = 0
			if (this.calculateResponse.positions) {
				this.calculateResponse.positions.map((element) => {
					if (element.sell) {
						cost += Number(element.sell)
					}
				})
			}
			cost = cost.toFixed(2)
			return cost
		},
		isCanBasket() {
			let can = true
			if (this.calculateResponse.positions.length != 0) {
				this.calculateResponse.positions.map((element) => {
					if (element.price == null || element.price == 0) {
						can = false
					}
				})
			}else{
				can = false
			}
			return can
		},
		positionsTest() {
			let array = []
			if (this.calculateResponse.positions) {
				array = this.calculateResponse.positions
			}
			return array 
		},
		getCalculatorImgFullName(){
			let name = ''
			if (this.inputFocusName) {
				name += this.inputFocusName + '.svg' 
			}else{
				name += 'p.svg'
			}
			return name
		},
		adminCalculatorFence() {
			return this.calculators.calculatorFence
		},
		objectCurrentRegion() {
            let current_region = {}
            this.regionAdresses.map((element) => {
                if (element.id_1c == this.region) {
                    current_region = element
                }
            })
            return current_region
        },
	},
	watch: {
		material() {
			this.calculateForm.view_material = null
			this.update()
		},
		calculateForm() {
			this.setCalculateResponse({})
		},
		// form: {
        //     handler: function () {
        //         this.getProducts()
        //     },
        //     deep: true
        // },
		calculatorFenceType() {
			this.choosenMaterial = null
			this.calculateForm.height = null
			this.calculateForm.perimeter = null
			this.calculateForm.amount_vein = null
			this.calculateForm.spacing_panks = null
			this.calculateForm.height_pole = null
			this.calculateForm.distance_pole = null

			this.calculateForm.double = null
		},
		region() {
			this.setCalculateResponse({})
			this.setDefaultFormFence()
			this.setPositionsDefault()
			this.form.root_id = this.region
			this.form.rolling_side = null
			this.form.coating = null
			this.form.thickness = null
			this.getValues()
		},
	},
	destroyed() {
		this.setCalculateResponse({})
		this.setDefaultFormFence()
		this.setPositionsDefault()
	},
	mounted() {
		this.getValues()
		this.form.root_id = this.region
	},
	methods: {
		...Vuex.mapMutations({
			setFormCalculatorFence: 'calculator/setFormCalculatorFence',
			deletePosition: 'calculator/deletePosition',
			savePosition: 'calculator/savePosition',
			setDefaultFormFence: 'calculator/setDefaultFormFence',
			setCalculateResponse: 'calculator/setCalculateResponse',
			changeNewPosition: 'basket/changeNewPosition',
		}),
		...Vuex.mapActions({
			calculateFence: 'calculator/calculateFence',
			createPosition: 'basket/create',
			calculatePosition: 'calculator/calculatePosition',
			getStoreCalcFence: 'calculator/getCalcFence',
			searchNavbar: 'catalog/searchNavbar',
			getCardParameters: 'calculator/getCardParameters',
			getColors: 'catalog/getColors',
			getFilters: 'catalog/getFilters',
		}),
		setCardProductIsReady() {
            let ready = true
            let parameters = this.parameters
            let error_array = []
            if (parameters && parameters.length != 0) {
                parameters.map((element) => {
                    let name = element.base_name
                    let lower_name = name[0].toLowerCase() + name.substring(1)
    
                    lower_name = lower_name.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
    
                    lower_name = lower_name.trim()
                    if (lower_name == 'color') {
                        lower_name = 'colors'
                    }
                    if (element.value == true) {
                        if (!this.form[lower_name] && lower_name != 'colors' && (this.filters[lower_name] && !this.isEmptyObject(this.filters[lower_name])) ) {
                            ready = false
                            error_array.push(lower_name)
                        }
                        if (lower_name == 'colors') {
                            if (!this.form[lower_name] && this.form.coating == 'Оцинкованное' && ready == true) {
                                ready = true
                            }else if (!this.form[lower_name] && this.form.coating != 'Оцинкованное'){
                                error_array.push(lower_name)
                                ready = false
                            }
                        }
                    }
                })
            }else{
                ready = false
            } 
			this.parameterErrorArray = error_array
        },
		changeSpacingSpanks(type) {
			if (type == 'click') {
				this.calculateForm.spacing_panks = this.currentSpasingSpanks
			}else if (type == 'notclick') {
				this.currentSpasingSpanks = this.calculateForm.spacing_panks
			}
			this.update()
		},
		save() {
			this.calculateForm.position = this.newPosition, 
			this.calculateForm.region_code = this.objectCurrentRegion.region_code, 
			this.calculateForm.region_description = this.objectCurrentRegion.region_description
			this.calculateForm.company_id = Number(this.userCompanyId)
			this.update()
			this.calculateFence()
		},
		setPositionsDefault() {
			this.products = []
			this.parameters = []
			this.choosenMaterial = null
		},
		setChangeElementIndex(val) {
			this.changeElementIndex = val
		},
		validate() {
			if (this.calculateForm.amount_vein) {
				this.calculateForm.amount_vein = Number(this.calculateForm.amount_vein)
				if (this.calculateForm.amount_vein > 6) {
					this.calculateForm.amount_vein = 6
				}else if (this.calculateForm.amount_vein < 2) {
					this.calculateForm.amount_vein = 2
				}
			}
			if (this.calculateForm.distance_pole) {
				this.calculateForm.distance_pole = Number(this.calculateForm.distance_pole)
				if (this.calculateForm.distance_pole > 3) {
					this.calculateForm.distance_pole = 3
				}else if (this.calculateForm.distance_pole < 2) {
					this.calculateForm.distance_pole = 2
				}
			}
			if (this.calculateForm.height) {
				this.calculateForm.height = Number(this.calculateForm.height)
			}
			if (this.calculateForm.height_pole) {
				this.calculateForm.height_pole = Number(this.calculateForm.height_pole)
				if (this.calculateForm.height_pole > 6) {
					this.calculateForm.height_pole = 6
				}
			}
			if (this.calculateForm.perimeter) {
				this.calculateForm.perimeter = Number(this.calculateForm.perimeter)
			}
			if (this.calculateForm.spacing_panks) {
				this.calculateForm.spacing_panks = Number(this.calculateForm.spacing_panks)
			}
		},
		update() {
			this.validate()
			this.setFormCalculatorFence(this.calculateForm)
		},
		setFenceType(id) {
			this.calculateForm.fence_type = id
			if (id != 10) {
				this.calculateForm.view_material = 'classic'
			}
			this.update() 
		},
		setCorniceType(id) {
			this.calculateForm.cornice_type = id
			this.update() 
		},
		setMountingType(id) {
			this.calculateForm.mounting_type = id
			this.update() 
		},
		setDouble(val) {
			this.calculateForm.double = val
			this.update()
		},
		setIsMetalPoles(val) {
			this.calculateForm.is_metal_poles = val
			if (val == false) {
				this.calculateForm.distance_pole = null
				this.calculateForm.height_pole = null
			}
			this.update()
		},
		setViewMaterial(element) {
			this.currentColor = {}
			this.choosenMaterial = element
			this.form.parent_id = element.id_1c
			this.form.rolling_side = null
			this.form.coating = null
			this.form.thickness = null
			this.setParameters(element.id_1c)
			this.getProducts()
		},
		setNewPositionParameters(product) {
			this.changeNewPosition({type: 'id_1c', value: product.id_1c})
			this.changeNewPosition({type: 'name', value: product.name})
			this.changeNewPosition({type: 'price', value: product.price})
			this.changeNewPosition({type: 'overall_width', value: product.overall_width})
			this.changeNewPosition({type: 'length', value: null})
			this.changeNewPosition({type: 'count_base_unit_of_measure', value: 1})
			this.changeNewPosition({type: 'sell', value: product.sell})
			this.changeNewPosition({type: 'amount', value: 1})
			this.changeNewPosition({type: 'root_id', value: this.region})
			if (product.max_normative_length) {
				this.changeNewPosition({type: 'length', value: product.max_normative_length})
			}else{
				this.changeNewPosition({type: 'length', value: 0})
			}
		},
		setColor(cardColor) {
			this.currentColor = cardColor
			this.form.colors = cardColor.id_1c
			this.changeNewPosition({type: 'color', value: cardColor.id_1c})
		},
		getProducts(str) {
			if (str) {
				if (str == 'color') {
					this.form.colors = this.newPosition[str]
				}else{
					this.form[str] = this.newPosition[str]
				}
				this.changeParameter = str
            }else{
				this.form.parent_id = this.choosenMaterial.id_1c
				this.form.root_id = this.region
				this.form.is_show_zero_price = false
				this.form.is_group = false
			}

			this.searchNavbar(this.form)
				.then((response) => {
					this.products = response.data
					this.setNewPositionParameters(response.data[0])
					this.getFilters(this.form)
						.then(() => {
							this.setColorsArray()
							this.setCardProductIsReady()
						})
                    this.isShowList = true
				})
		},
		getColorsProduct() {
            let colors = ["0"]
			this.products.map((element) => {
				if (Array.isArray(element.colors)) {
					element.colors.map((el) => {
						if (!this.isIssetColor(colors, el)) {
							colors.push(el)
						}
					})
				}
			})
            this.getColors(colors)
        },
		isIssetColor(array, value) {
            let response = false
            array.map((element) => {
                if (element == value) {
                    response = true
                }
            })
            return response
        },
		changeAmountInsulationThickness(type) {
			this.calculateForm.insulation_thickness = Number(this.calculateForm.insulation_thickness)
			if (type == 'plus') {
				this.calculateForm.insulation_thickness += 1
			}else if (type == 'minus') {
				if (this.calculateForm.insulation_thickness == 1) {
					return
				}
				this.calculateForm.insulation_thickness -= 1
			}
			this.update()
		},
		addInBasket() {
			let array = []
			this.calculateResponse.positions.map((element) => {
				if (element.id_1c) {
					array.push(element)
				}
			})
			
			let source_utm = JSON.parse(localStorage.getItem("utm_source"))
			this.createPosition({
				positions: array, 
				region_code: this.objectCurrentRegion.region_code, 
				region_description: this.objectCurrentRegion.region_description,
				utm: source_utm,
			})
				.then((response) => {
					localStorage.setItem('mayakOrderId', JSON.stringify(response.data.id))
					let that = this
					setTimeout(() => {
						that.push('basket')
					}, 1000)
				})
		},  
		updatePosition(element, index, color, elementName) {
			if (elementName) {
				this.savePosition(
				{
					index: index,
					id_1c: element.id_1c,
					coating: element.coating,
					thickness: element.thickness,
					overall_width: element.overall_width,
					images: element.links,
					color: color,
					name: elementName,
				})
			}else{
				this.savePosition(
					{
						index: index,
						id_1c: element.id_1c,
						coating: element.coating,
						thickness: element.thickness,
						overall_width: element.overall_width,
						images: element.links,
						color: color,
					})
			}
			this.calculate(index)
		}, 
		calculate(index) {
			this.calculateResponse.positions.map((el, idx) => {
				if (idx == index) {	
					this.calculatePosition({position: el, index: index})
						.then((response) => {
							el.price = response.data.positions[0].price
							el.sell = response.data.positions[0].sell
						})
				}
			})
		},     
		getValues() {
			this.getStoreCalcFence(this.region)
				.then(() => {
					if (this.calc_fence_body.prof.length != 0) {
						this.searchNavbar({root_id: this.region, id_1cs: this.calc_fence_body.prof})
							.then((response) => {
								this.profnastilArray = response.data
							})
					}
					if (this.calc_fence_body.stack.length != 0) {
						this.searchNavbar({root_id: this.region, id_1cs: this.calc_fence_body.stack})
							.then((response) => {
								this.stackArray = response.data
							})
					}
				})
		}, 
		setParameters(id_1c) {
            this.parameters = []
            this.getCardParameters({ root_id: this.region, id_1c: id_1c})
                .then((response) => {
                    if (response.data[0]) {
						this.parameters = JSON.parse(response.data[0].card_structure)
					}
                })
        },
		setColorsArray() {
            let array = []
            let local_filters = this.filters['colors']
            for (let property in local_filters) {
                array.push(local_filters[property])
            }
			this.productColorsArray = array
        },
		updateCalculatorPosition() {

		},
	}	
}
</script>
<style lang="scss">
	.max_width_span_text{
		&:before{
			max-width: 22rem;
			overflow: hidden;
		}
	}
	.max_width_span_text_with_arrows{
		&:before{
			max-width: 20rem;
			overflow: hidden;
		}
	}
	.cl-car-item{
		border: 2px solid #fff;
		margin-bottom: 8rem;
	}
	.cl-car-item:hover{
		border: 2px solid #EA292E;
		cursor: pointer;
	}
	.active_card{
		border: 2px solid #EA292E;
	}
	.mb-10{
		margin-bottom: 10rem;
	}
	.overh{
		overflow: hidden;
	}
	.position_parameters{
		display: flex;
		flex-wrap: wrap;
		width: 105rem;

		.good-col{
			width: 26rem;
			margin-right: 3rem;
			margin-top: 1rem;
		}

		.good-col_color{
			width: 105rem;
		}
	}
</style>
<style scoped src="../../../css/nlk-style.css"></style>