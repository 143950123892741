<template>
    <main class="main nd_auth">
        <div class="section section--breadcrumbs">
            <div class="container container-nd"> 
                <div class="inner">
                    <ul class="list list--flex breadcrumbs">
                        <li 
                            class="list-item"
                            @click="push('')" 
                        >
                            <a class="list-link">
                                <svg 
                                    class="icon" 
                                    width="16" 
                                    height="16" 
                                    viewBox="0 0 16 16"
                                >
                                    <use xlink:href="@/assets/img/icons.svg#home" />
                                </svg>
                            </a>
                        </li>
                        <li class="list-item">
                            <span class="list-current">Вход</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="section section--no-pt">
            <div class="container container-nd">
                <div class="row row--login">
                    <div class="col">
                        <div class="auth_left_block">
                            <img 
                                v-if="step != 1 || isUserNotFound || isNumberConfirmedError"
                                @click="prevStep"
                                class="auth_icon_back"
                                src="~@/img/icon_back.png" 
                                alt=""
                            >
                            <div class="heading">
                                <div class="heading-section">
                                    <p>{{ heading }}</p>
                                </div>
                            </div>
                            <div 
                                v-if="step != 1 || isUserNotFound || isNumberConfirmedError"
                                class="subheading"
                            >
                                <p v-if="isNumberConfirmedError" class="second_step_info">
                                    Номер <span class="second_step_value">{{ formatedPhone(form.phone) }}</span> не подтвержден. 
                                    Вернитесь и попробуйте авторизоваться используя почту
                                </p>
                                <p v-if="isUserNotFound && typeAuth == 10" class="second_step_info">
                                    Пользователь с почтой <span class="second_step_value">{{ form.login }}</span> не найден. Пройдите на страницу регистрации чтобы создать нового пользователя
                                </p>
                                <p v-if="isUserNotFound && typeAuth == 20" class="second_step_info">
                                    Пользователь с номером телефона <span class="second_step_value">{{ formatedPhone(form.phone) }}</span> не найден. Пройдите на страницу регистрации чтобы создать нового пользователя
                                </p>
                                <p v-if="step == 2" class="second_step_info">{{ secondStepInfo }}</p>
                                <p v-if="step == 3" class="second_step_info">
                                    На почту <span class="second_step_value">{{ form.login }}</span> будет отправлено письмо со ссылкой для восстановления пароля
                                </p>
                                <p v-if="step == 4" class="second_step_info mb-24">
                                    На вашу почту <span class="second_step_value">{{ form.login }}</span> отправлено письмо со ссылкой. Перейдите по ней для смены пароля
                                </p>
                                <p v-if="step == 4" class="second_step_info">Если его нет во входящих, проверьте папку "Спам"</p>
                                <p class="second_step_value" v-if="typeAuth == 10 && step == 2">{{ form.login }}</p>
                                <p class="second_step_value" v-if="typeAuth == 20 && step == 2">{{ formatedPhone(form.phone) }}</p>
                            </div>
                            <form 
                                class="form"
                                :class="step == 4 ? 'pb-0' : ''" 
                                onsubmit="return false;"
                            >
                                <div class="form-fieldset">
                                    <div class="form-group form-row">
                                        <div 
                                            v-if="step == 1 && !isUserNotFound && !isNumberConfirmedError"
                                            class="form-item"
                                        >
                                            <div class="field">
                                                <div class="field-input">
                                                    <input 
                                                        v-if="typeAuth == 10"
                                                        class="nd_auth_input"
                                                        v-model="form.login"
                                                        type="text" 
                                                        id="login_input"
                                                        placeholder="Email или телефон" 
                                                        :class="validationsForm.isLoginEmpty ? 'error_input' : ''"
                                                        @keyup="checkValueLogin()"
                                                        @paste="checkValueLogin()"
                                                    >

                                                    <the-mask 
                                                        v-if="typeAuth == 20"
                                                        v-model="form.phone"
                                                        mask="+7 (###) ###-####" 
                                                        placeholder="+7" 
                                                        type="tel" 
                                                        id="phone_input"
                                                        class="nd_auth_input"
                                                        :class="validationsForm.isPhoneEmpty ? 'error_input' : ''"
                                                        @keyup.native="checkValueLogin()"
                                                    />
                                                </div>
                                            </div>
                                            <p v-if="validationsForm.isLoginEmpty" class="phone_message_error">Заполните поле логин чтобы продолжить</p>
                                            <p v-if="validationsForm.isPhoneEmpty" class="phone_message_error">Введите телефон полностью чтобы продолжить</p>
                                            <p v-if="messageError && !isErrorRedis" class="phone_message_error">{{ messageError }}</p>
                                            <p v-if="messageError && isErrorRedis && smsTimer != 0" class="phone_message_error">{{ messageError }} {{ formatedTime(smsTimer) }}</p>
                                            <p v-if="isTimerEnd == true" class="phone_message_link" @click="sendCode">Отправить код повторно</p>
                                        </div>
                                        <div 
                                            v-if="step == 2 && typeAuth == 10"
                                            class="form-item"
                                        >
                                            <div class="field">
                                                <div class="field-input">
                                                    <input 
                                                        class="nd_auth_input"
                                                        :class="isLoginFailed ? 'error_input' : ''"
                                                        v-model="form.password"
                                                        :type="isShowPassword ? 'text' : 'password'" 
                                                        placeholder="Пароль" 
                                                    >
                                                    <img
                                                        v-if="isShowPassword" 
                                                        src="~@/img/password_input_icon1.png" 
                                                        alt=""
                                                        class="icon-show-password"
                                                        @click="isShowPassword = !isShowPassword"
                                                    >
                                                    <img 
                                                        v-else
                                                        src="~@/img/password_input_icon2.png"
                                                        alt=""
                                                        class="icon-show-password"
                                                        @click="isShowPassword = !isShowPassword"
                                                    >
                                                    <p v-if="validationsForm.isPasswordEmpty" class="phone_message_error">Введите пароль</p>
                                                    <p v-if="isLoginFailed" class="phone_message_error">Неверный логин или пароль</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div 
                                            v-if="step == 2 && typeAuth == 20"
                                            class="form-item"
                                        >
                                            <div class="field">
                                                <div class="field-input">
                                                    <input 
                                                        class="nd_auth_input"
                                                        v-model="form.code"
                                                        type="text" 
                                                        placeholder="Код" 
                                                    >
                                                    <p v-if="validationsForm.isCodeEmpty" class="phone_message_error">Введите код из смс</p>
                                                    <p v-if="isCodeSended && smsTimer > 1" class="auth_sended_message">Отправить код повторно через {{ formatedTime(smsTimer) }}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-item">
                                            <button 
                                                v-if="(step == 1 || step == 2) && !isUserNotFound && !isNumberConfirmedError"
                                                class="button button--primary nd-br nd_button" 
                                                @click="nextStep()"
                                            >
                                                Продолжить
                                            </button>
                                            <button 
                                                v-if="step == 3"
                                                class="button button--primary nd-br nd_button"
                                                @click="recovery"
                                            >
                                                Прислать ссылку
                                            </button>
                                            <button 
                                                v-if="isUserNotFound"
                                                class="button button--primary nd-br nd_button"
                                                @click="registrationNewUser"
                                            >
                                                Зарегистрироваться
                                            </button>
                                        </div>
                                    </div>
                                    <p 
                                        v-if="step == 2 && typeAuth == 10"
                                        class="forget_password_link"
                                        @click="step = 3"
                                    >
                                        Забыли пароль?
                                    </p>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="col">
                        <div class="auth_right_block">
                            <img src="~@/img/auth_img.png" alt="">
                            <p class="title" @click="push('registration')">Еще не с нами?</p>
                            <p class="subtitle">
                                После регистрации на сайте вам будет
                                доступно отслеживание состояния заказов,
                                личный кабинет и другие новые
                                возможности
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
import Vue from 'vue'
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

export default {
    mixins: [Functions],
    data () {
        return {
            form: {
                login: '',
                password: null,

                phone: '',
                code: null,
            },
            isShowPassword: false,
            typeAuth: 10,
            step: 1,
            smsTimer: 0,
            timerId: null,
            isCodeSended: false,
            isLoginFailed: false,
            isForgetPassword: false,
            isUserNotFound: false,
            messageError: null,
            isErrorRedis: false,
            isNumberConfirmedError: false,
            isTimerEnd: false,
            validationsForm: {
                isLoginEmpty: false,
                isPhoneEmpty: false,
                isCodeEmpty: false,
                isPasswordEmpty: false,
            },
        }
    },
    watch: {
        typeAuth(val) {
            if (val == 10) {
                this.form.login = this.form.phone
            }else if (val == 20) {
                var value = this.form.login
                if (value.length == 11 || value[0] == '8') {
                    var slices = value.substring(1)
                    value = '+7' + slices
                }
                this.form.phone = value
            }
        }
    },
    computed: {
        ...Vuex.mapGetters({
            message: 'auth/message',
            order: 'basket/order',
            formSmsStore: 'auth/formSms',
        }),
        heading() {
            var value = ''
            if (this.step == 1) {
                if (!this.isUserNotFound && !this.isNumberConfirmedError) {
                    value = 'Вход или регистрация'
                }else if (this.isUserNotFound) {
                    value = 'Пользователь не найден'
                }else if (this.isNumberConfirmedError) {
                    value = 'Номер не подтвержден'
                }
            }else if (this.step == 2) {
                if (this.typeAuth == 10) {
                    value = 'Введите пароль'
                }else if (this.typeAuth == 20) {
                    value = 'Введите код'
                }
            }else if (this.step == 3){
                value = 'Восстановление пароля'
            }else{
                value = 'Письмо отправлено'
            }
            return value;
        },
        secondStepInfo() {
            var info = ''
            if (this.typeAuth == 10) {
                info = 'Для входа на сайт введите пароль: '
            }else{
                info = 'Код отправлен на номер: '
            }
            return info
        },
        formSms() {
            return Object.assign({}, this.formSmsStore)
        },
    },
    methods: {
        ...Vuex.mapActions({
            auth: 'auth/auth',
            checksms: 'auth/checksms',
            sms: 'auth/sms',
            verify: 'auth/verify',
            recoveryStore: 'auth/recovery',
        }),
        ...Vuex.mapMutations({
            setFormSms: 'auth/setFormSms',
            setLoginEmail: 'auth/setLoginEmail',
            setLoginPhone: 'auth/setLoginPhone',
        }),
        validation() {
            var is_can = true
            if (this.step == 1) {
                if (this.typeAuth == 10) {
                    if (this.form.login == null || this.form.login.length == 0) {
                        this.validationsForm.isLoginEmpty = true
                        is_can = false
                    }else{
                        this.validationsForm.isLoginEmpty = false
                    }
                }else if (this.typeAuth == 20) {
                    if (this.form.phone == null || this.form.phone.length < 10) {
                        this.validationsForm.isPhoneEmpty = true
                        is_can = false
                    }else{
                        this.validationsForm.isPhoneEmpty = false
                    }
                }
            }else if (this.step == 2) {
                if (this.typeAuth == 10) {
                    if (this.form.password == null || this.form.password.length == 0) {
                        this.validationsForm.isPasswordEmpty = true
                        is_can = false
                    }else{
                        this.validationsForm.isPasswordEmpty = false
                    }
                }else if (this.typeAuth == 20) {
                    if (this.form.code == null || this.form.code.length != 6) {
                        this.validationsForm.isCodeEmpty = true
                        is_can = false
                    }else{
                        this.validationsForm.isCodeEmpty = false
                    }
                }
            }
            return is_can
        },
        startTimer(time) {
            if (!time) {
                time = 300
            }else if (time && !Number(time)) {
                return
            }
            this.smsTimer = time
            if (time && time == 1) {
                this.smsTimer = 0
                this.isErrorRedis = false
                this.isTimerEnd = true
                this.messageError = ''
                return
            }
            this.timerId = setTimeout(function(){
                this.startTimer(time - 1)
                localStorage.setItem('sended_code_time', JSON.stringify(time)) 
            }.bind(this), 1000)
        },
        formatedTime(s) {
            let m = Math.trunc(s / 60) + ''
            s = (s % 60) + ''
            return m.padStart(2, 0) + ':' + s.padStart(2, 0)
        },
        prevStep() {
            if (this.step == 1) {
                if (this.isUserNotFound) {
                    this.isUserNotFound = false
                }
                if (this.isNumberConfirmedError) {
                    this.isNumberConfirmedError = false
                }
                return
            }
            this.step -= 1 
        },
        getFinallyPhone() {
            var finallyPhone = ''
            var phone = this.form.phone
            var splitedPhone = phone.split('')
            splitedPhone.map((element) => {
                if (!isNaN(element) && element != ' ') {
                    finallyPhone += element
                }
            })       
            if (finallyPhone.length == 10) {
                finallyPhone = '+7' + finallyPhone
            }else if (finallyPhone.length == 11) {
                finallyPhone = '+' + finallyPhone
            }
            return finallyPhone
        },
        sendCode() {
            this.verify({phone: this.getFinallyPhone()})
                .then(() => {
                    this.step = 2
                    this.isCodeSended = true
                    this.isTimerEnd = false
                    this.startTimer(300)
                })
                .catch((err) => {
                    if (err.response.data.error.includes('redis exists')) {
                        this.messageError = 'Вы уже отправили код на этот телефон, повторно можно будет отправить через'
                        this.isErrorRedis = true
                        var splitedError = err.response.data.error.split(',')
                        var responseTime = splitedError[1]
                        this.isTimerEnd = false
                        clearTimeout(this.timerId)
                        this.startTimer(Number(responseTime))
                    }else if (err.response.data.error == 'user not found') {
                        this.isUserNotFound = true
                    }else if (err.response.data.error == 'phone number not confirmed') {
                        this.isNumberConfirmedError = true
                    }
                })
        },
        nextStep(){
            if (!this.validation()) {
                return
            }
            if (this.step == 1) {
                if (this.typeAuth == 20) {
                    if (this.smsTimer > 1) {
                        return
                    }
                    this.sendCode()
                }else if (this.typeAuth == 10) {
                    this.verify({login: this.form.login})
                        .then(() => {
                            this.step = 2
                        })
                        .catch(() => {
                            this.isUserNotFound = true
                        })
                }
            }else{
                if (this.typeAuth == 10) {
                    this.logIn()
                }else if (this.typeAuth == 20) {
                    this.formSms.phone = this.getFinallyPhone()
                    this.formSms.number_sms = Number(this.form.code)
                    this.setFormSms(this.formSms)
                    this.sms()
                        .then(() => {
                            if (this.order.id && this.order.positions.length != 0) {
                                this.push('basket/checkout')
                            }else{
                                this.push('nlk')
                            }
                        })
                }
            }
        },
        logIn() {
            this.auth({login: this.form.login, password: this.form.password})
                .then(() => {
                    if (this.order.id && this.order.positions.length != 0) {
                        this.push('basket/checkout')
                    }else{
                        this.push('nlk')
                    }
                })
                .catch(() => {
                    this.isLoginFailed = true
                })
        },
        checkValueLogin() {
            var value = (this.typeAuth == 10 ? this.form.login : this.form.phone)
            if (value.length >= 3) {
                if ((!isNaN(value[1]) && !isNaN(value[2]))) {
                    this.typeAuth = 20
                    setTimeout(function(){
                        var phoneInput = document.getElementById('phone_input')
                        if (phoneInput) {
                            phoneInput.focus()
                        }
                    }.bind(this), 100)
                }
            }else if (value.length == 0) {
                this.typeAuth = 10
                setTimeout(function(){
                    var loginInput = document.getElementById('login_input')
                    if (loginInput) {
                        loginInput.focus()
                    }
                }.bind(this), 100)
            }
        },
        recovery() {
            this.recoveryStore({email: this.form.login})
                .then(() => {
                    this.step = 4
                })
        },
        registrationNewUser() {
            if (this.typeAuth == 10) {
                this.setLoginEmail(this.form.login)
                this.push('registration')
            }else if (this.typeAuth == 20) {
                this.setLoginPhone(this.getFinallyPhone())
                this.push('registration')
            }
        }
    },
}
</script>
<style lang="scss">
    .p-rel{
        position: relative;
    }
    .icon-show-password{
        position: absolute;
        top: 1.5rem;
        right: 10px;

        &:hover{
            cursor: pointer;
            color: #ed1c24;
        }
    }
    .icon-show-password-active{
        color: #ed1c24;

        &:hover{
            color: black;
        }
    }
</style>