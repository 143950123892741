<template>
    <section class="about_company-info_page">
        <banner-block />
        <bread-crumbs :pages-array="pagesAboutCompany" />
        <cards-block />
        <catalog />
        <advantage-block />
        <description-block />
        <form-call-back />
        <type-profiles-block />
        <keys-connections-block />
        <objects-block />
        <clients-block />
        <excursion-block />
        <map-block />
    </section>
</template>
<script>
import Vuex from 'vuex'

import bannerBlock from './banner.vue'
import breadCrumbs from '@/components/breadcrumbs/breadcrumbs.vue'
import cardsBlock from './cards.vue'
import advantageBlock from './advantages.vue'
import descriptionBlock from './description.vue'
import formCallBack from '@/components/form-callback.vue'
import typeProfilesBlock from './type-profiles.vue'
import keysConnectionsBlock from './keys-connections.vue'
import catalog from './catalog.vue'
import objectsBlock from '@/components/pages/home/objects/objects.vue'
import clientsBlock from '@/components/pages/home/brands/brands.vue'
import excursionBlock from '@/components/pages/home/excursion/excursion.vue'
import mapBlock from './map.vue'

export default {
    components: {
        bannerBlock,
        breadCrumbs,
        cardsBlock,
        advantageBlock,
        descriptionBlock,
        formCallBack,
        typeProfilesBlock,
        keysConnectionsBlock,
        catalog,
        objectsBlock,
        clientsBlock,
        excursionBlock,
        mapBlock,
    },
    computed: {
        ...Vuex.mapGetters({
            pagesAboutCompany: 'dictionary/pagesAboutCompany',
        }),
    },
}
</script>