<template>
    <div class="index__admin">
        <span 
            class="index__del" 
            @click="deleteHistory" 
        >
            Удалить Х
        </span>
        <div class="col-12 row">
            <div class="col-3">
                <input  
                    width="100%" 
                    type="text" 
                    placeholder="Год" 
                    v-model="editElement.year" 
                    @keyup="update"
                >
            </div>
            <div class="col-9">
                <input 
                    type="text" 
                    placeholder="Заголовок" 
                    v-model="editElement.heading" 
                    @keyup="update"
                >
            </div>
            
        </div>
        <div>
            <textarea  
                name="" 
                id="" 
                cols="60" 
                rows="4"  
                v-model="editElement.description" 
                @keyup="update"
            />
        </div>
        <div>
            <input 
                type="text" 
                placeholder="Фактоид или сноска"
                style="width: 61%"
                v-model="editElement.link"
                @keyup="update"
            >
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
export default {
    props: {
        element: {
            type: Object,
            default: () => {}
        },
        index: {
            type: Number,
            default: 0
        },
    },
    computed: {
        ...Vuex.mapGetters({
            company: 'app/company',
            fileUrl: 'app/fileUrl',
        }),
        editElement() {
            return Object.assign({}, this.element)
        },
    },
    methods: {
        ...Vuex.mapActions({
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            updateCompanyHistory: 'app/updateCompanyHistory',
            deleteCompanyHistory: 'app/deleteCompanyHistory',
        }),
         update() {
            this.updateCompanyHistory(
                {
                    idx: this.index, 
                    year: this.editElement.year, 
                    heading: this.editElement.heading, 
                    blockType: this.editElement.blockType, 
                    description: this.editElement.description,
                    link: this.editElement.link,
                })
        },
        deleteHistory(){
            this.deleteCompanyHistory(this.index) 
        },
    },
}
</script>