<template>
    <footer class="footer footer-nd" id="main_footer">
        <div class="footer_block-nd">
            <div class="container container-nd">
                <div class="row">
                    <div class="col pdl-40">
                        <a class="logotype" href="#">
                            <img src="@/img/nd-imgs/footer_logo.png" alt="">
                        </a>
                    </div>
                </div>
    
                <div class="row">
                    <div class="col left_part_footer pdl-40">
                        <div class="footer_contacts">
                            <p class="contact_header">Контакты</p>
                            <a v-if="sourceUtmPhone" :href="'tel:' + maskedSourceUtmPhone">{{ maskedSourceUtmPhone }}</a>
                            <a v-if="!sourceUtmPhone && currentAddress" :href="'tel:' + currentAddressPhone">{{ currentAddressPhone }}</a>

                            <a v-if="sourceUtmEmail" :href="'mailto:' + sourceUtmEmail">{{ sourceUtmEmail }}</a>
                            <a v-if="!sourceUtmEmail && currentAddress" :href="'mailto:' + currentAddress.email">{{ currentAddress.email }}</a>
                        </div>

                        <div class="footer-city">
                            <region-select 
                                v-if="defaultValue"
                                data-select 
                                :elements="topLevelCategory" 
                                v-model="city"
                                @change="changeCity" 
                                :defaultValue="defaultValue" 
                                :from="'footer'"
                                :typeSelect="'default'"  
                            />
                        </div> 

                        <div class="label">Мы в социальных сетях</div>
                        <img src="@/img/nd-imgs/vk_logo.png" alt="" class="vk_logo-nd">
                    </div>
    
                    <div class="col two_cols">
                        <div class="subcol">
                            <div class="label">Разделы каталога</div>
                            <ul class="list navigation-footer">
                                <li class="list-item">
                                    <ul class="list sub">
                                        <!-- <li class="list-item" @click="push('metalloconstructions')">
                                            <a class="list-link">Металлоконструкции</a>
                                        </li> -->
                                        <li class="list-item" @click="pushToBuild('metall')">
                                            <a class="list-link">Металлоконструкции</a>
                                        </li>
                                        <!-- <li class="list-item" @click="push('building')">
                                            <a class="list-link">Быстровозводимые здания</a>
                                        </li> -->
                                        <li class="list-item" @click="pushToBuild('build')">
                                            <a class="list-link">Быстровозводимые здания</a>
                                        </li>
                                    </ul>
                                </li>
    
                                <!-- <li 
                                    class="list-item" 
                                    v-for="category in secondLevelCategory" 
                                    :key="category.id"
                                    @click="push('catalog/' + category.id_1c)"
                                >
                                    <a 
                                        class="list-link"
                                    >
                                        {{ category.name }}
                                    </a>
                                </li> -->
                            </ul>
                        </div>
    
                        <div class="subcol">
                            <div class="label">Разделы услуг</div>
                            <ul class="list navigation-footer">
                                <li class="list-item">
                                    <ul class="list sub">
                                        <li class="list-item" @click="push('legals')">
                                            <a class="list-link">Юрлицам</a>
                                        </li>
                                        <li class="list-item" @click="push('coatings')">
                                            <a class="list-link">Покрытия</a>
                                        </li>
                                        <!-- <li class="list-item" @click="push('vacancy')">
                                            <a class="list-link">Вакансии</a>
                                        </li> -->
                                        <!-- <li class="list-item" @click="push('services')">
                                            <a class="list-link">Услуги</a>
                                        </li> -->
                                        <li class="list-item" @click="push('support')">
                                            <a class="list-link">Поддержка</a>
                                        </li>
                                        <li class="list-item" @click="push('partner')">
                                            <a class="list-link">Стань партнером</a>
                                        </li>
                                        <!-- <li class="list-item" @click="push('paydelivery')">
                                            <a class="list-link">Доставка</a>
                                        </li> -->
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col">
                        <div class="label">Разделы сайта</div>
                        <ul class="list navigation-footer">
                            <li class="list-item">
                                <ul class="list sub">
                                    <li class="list-item" @click="push('aboutcompany')">
                                        <a class="list-link">О компании</a>
                                    </li>
                                    <li class="list-item" @click="push('objects')">
                                        <a class="list-link">Наши объекты</a>
                                    </li>
                                    <li class="list-item" @click="push('documents')">
                                        <a class="list-link">Документы</a>
                                    </li>
                                    <li class="list-item" @click="push('offers')">
                                        <a class="list-link">Акции и предложения</a>
                                    </li>
                                    <li class="list-item" @click="push('admin/index')" v-if="can('admin')">
                                        <a class="list-link">Администратор</a>
                                    </li>
                                    <li class="list-item" @click="push('contacts')">
                                        <a class="list-link">Контакты</a>
                                    </li>
                                    <li class="list-item" @click="pushToCalculators">
                                        <a class="list-link">Конструктор</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-bottom footer-bottom-nd">
            <div class="container container-nd">
                <div class="row">
                    <div class="col pdl-40">
                        <div class="label">ОГРН/ИНН: 6829063243 / 682901001</div>
                    </div>
                    <div class="col">
                        <div class="label">Данный интернет-сайт носит исключительно информационный характер, и ни при каких условиях информация и цены, размещенные на сайте, не являются публичной офертой (ст. 437 ГК РФ).</div>
                    </div>
                    <div class="col">
                        <div class="label">
                            ООО «Роспромстрой»
                            © 2019 Компания «МаякМеталл»
                            <!-- Разработка:
                            <a href="https://azabelin.ru/" target="_blank">Студия Алексея Забелина</a> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>













        <div class="footer-top" style="display: none">
            <div class="container container-nd">
                <div class="row">
                    <div class="col">
                        <a class="logotype" href="#">
                            <img src="@/assets/img/logotype-alt.svg" alt="">
                        </a>
                    </div>
                    <div class="col">
                        <div class="label">Ваш город</div>
                        <div class="footer-city">
                            <region-select 
                                v-if="defaultValue"
                                data-select 
                                :elements="topLevelCategory" 
                                v-model="city"
                                @change="changeCity" 
                                :defaultValue="defaultValue" 
                                :from="'footer'"
                                :typeSelect="'location'" 
                            />
                        </div>
                    </div>
                    <div 
                        v-if="sourceUtmPhone"
                        class="col"
                    >
                        <div class="label">Отдел продаж</div>
                        <a class="phone" :href="'tel:' + maskedSourceUtmPhone">{{ maskedSourceUtmPhone }}</a>
                    </div>
                    <div 
                        v-if="!sourceUtmPhone && currentAddress"
                        class="col"
                    >
                        <div class="label">Отдел продаж</div>
                        <a class="phone" :href="'tel:' + currentAddress.phone">{{ currentAddress.phone }}</a>
                    </div>
                    <div 
                        v-if="currentAddress"
                        class="col"
                    >
                        <div class="label">E-mail</div>
                        <a class="email" :href="'mailto:' + currentAddress.email">{{ currentAddress.email }}</a>
                    </div>
                    <div class="col">
                        <div class="label">Социальные сети</div>
                        <ul
                            class="list list--flex social"
                        >
                            <li 
                                class="list-item"
                                v-for="network in uiNetworks"
                                :key="network.id"
                            >
                                <a 
                                  class="list-link" 
                                  :href="network.link"
                                  target="_blank"
                                >
                                    <svg class="icon" width="30" height="30" viewBox="0 0 30 30">
                                        <use xlink:href="@/assets/img/icons.svg#vk"></use>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer-middle" style="display: none">
            <div class="container">
                <div class="row">
                    <div 
                        v-if="currentAddress"
                        class="col"
                    >
                        <div class="label">Адрес</div>
                        <address class="address">{{ currentAddress.address }}</address>
                    </div>
                    <div class="col">
                        <div class="label">Разделы каталога</div>
                        <ul class="list navigation-footer">
                            <!-- <li class="list-item" @click="push('metalloconstructions')">
                                <a class="list-link">Металлоконструкции</a>
                            </li>
                            <li class="list-item" @click="push('building')">
                                <a class="list-link">Быстровозводимые здания</a>
                            </li> -->
                            <li 
                                class="list-item" 
                                v-for="category in secondLevelCategory" 
                                :key="category.id"
                                v-show="category.is_card_product || category.show"
                                @click="push('catalog/' + category.id_1c)"
                            >
                                <a 
                                    class="list-link"
                                >
                                    {{ category.name }}
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <div class="label">Разделы услуг</div>
                        <ul class="list navigation-footer">
                            <li class="list-item">
                                <a class="list-link" @click="push('legals')">Юрлицам</a>
                                <a class="list-link" @click="push('coatings')">Покрытия</a>
                                <a class="list-link" @click="push('vacancy')">Вакансии</a>
                                <ul class="list sub">
                                    <li class="list-item">
                                        <a class="list-link" @click="push('services')">
                                            Услуги
                                        </a>
                                    </li>
                                    <li class="list-item">
                                        <a class="list-link" @click="push('support')">Поддержка</a>
                                    </li>
                                    <li class="list-item" @click="push('partner')">
                                        <a class="list-link">Стань партнером</a>
                                    </li>
                                    <li class="list-item" @click="push('paydelivery')">
                                        <a class="list-link">Доставка</a>
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <div class="label">Разделы сайта</div>
                        <ul class="list navigation-footer">
                            <li class="list-item">
                                <a class="list-link" @click="push('aboutcompany')">О компании</a>
                                <ul class="list sub">
                                    <!-- <li class="list-item">
                                        <a class="list-link" href="#">Новости</a>
                                    </li> -->
                                    <li class="list-item" @click="push('objects')">
                                        <a class="list-link">Наши объекты</a>
                                    </li>
                                    <li class="list-item" @click="push('documents')">
                                        <a class="list-link">Документы</a>
                                    </li>
                                    <li class="list-item" @click="push('offers')">
                                        <a class="list-link">Акции и предложения</a>
                                    </li>
                                    <li class="list-item" @click="push('admin/index')" v-if="can('admin')">
                                        <a class="list-link">Администратор</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="list-item" @click="push('contacts')">
                                <a class="list-link">Контакты</a>
                            </li>
                            <li class="list-item" @click="pushToCalculators">
                                <a class="list-link">Конструктор</a>
                            </li>
                        </ul>
                    </div>
                    <div class="col">
                        <div class="label">Социальные сети</div>
                        <ul class="list list--flex social">
                            <li class="list-item">
                                <a class="list-link" href="#">
                                    <svg class="icon" width="30" height="30" viewBox="0 0 30 30">
                                        <use xlink:href="@/assets/img/icons.svg#vk"></use>
                                    </svg>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import Acl from '@/utils/acl'
import regionSelect from '@/components/choices-select/region-select.vue'

export default {
    mixins: [Functions, Acl],
    components: {
        regionSelect,
    },
    data() {
        return {
            city: null,
            defaultValue: '',
            isSetDefaultValue: false,
            isReady: false,
            choosenCity: '',
            isCheckedLocalRegion: false,
            isShowRegionButton: true,
        }
    },
    computed:{
        ...Vuex.mapGetters({
            regions: 'dictionary/regions',
            main: 'app/main',
            topLevelCategory: 'dictionary/topLevelCategory',
            region: 'auth/city',
            cityName: 'auth/cityName',
            regionAdresses: 'app/regionAdresses',
            secondLevelCategory: 'dictionary/secondLevelCategory',

            sourceUtmPhone: 'app/sourceUtmPhone',
            sourceUtmEmail: 'app/sourceUtmEmail',

            uiNetworks: 'ui_network/networks',
            offices: 'ui_contacts/offices',
        }),
        maskedSourceUtmPhone() {
            let masked = ''
            
            let phone = this.sourceUtmPhone
            masked += '+' + phone.substring(0, 1) 
            masked += ' (' + phone.substring(1, 4) + ') ' 
            masked += phone.substring(4, 7) 
            masked += '-' + phone.substring(7)  
            
            return masked
        },
        // currentAddress() {
        //     let address = null
        //     this.regionAdresses.map((element) => {
        //         if (element.id_1c == this.region) {
        //             address = element
        //         }
        //     })
        //     return address
        // },
        currentAddress() {
            let object = null
            this.offices.map((element) => {
                if (element.city == this.choosenCity) {
                    object = element
                }
            })
            return object
        },
        currentAddressPhone() {
            var phone = '8 (952) 647-00-63'
            var phones = []
            if (this.currentAddress.phone) {
                phones = this.currentAddress.phone.split(',')
                if (phones[0]) {
                    phone = phones[0]
                }
            }
            return phone
        },
    },
    watch: {
        isReady() {
            this.setDefaultCity()
        },
        topLevelCategory: {
            handler: function () {
                if (this.isSetDefaultValue == false) {
                    this.isReady = true
                }
            },
            deep: true
        },
        cityName() {
            this.checkCityLocal()
        },
    },
    mounted() {
        this.setDefaultCity()
    },
    created(){
        if(localStorage.fromMayak){
            this.from =  JSON.parse(localStorage.getItem("fromMayak"))
        }
        this.checkCityLocal()
        
    },
    methods: {
        ...Vuex.mapMutations({
            setCity:'auth/setCity',
            setRegionCode: 'auth/setRegionCode',
            setOrder: 'basket/setOrder',
        }),
        checkCityLocal() {
            var regionObject = JSON.parse(localStorage.getItem("mayakRegionObject"))
            this.city = regionObject.region
            this.choosenCity = regionObject.city
        },
        pushToBuild(str) {
            if (str == 'build') {
                window.open('https://mk.mayakmetall.ru/', '_blank')
            }else if (str == 'metall') {
                window.open('https://mk.mayakmetall.ru/mk', '_blank')
            }
        },
        getFirstOfficeOfRegion(region) {
            var value = null
            var isSeted = false
            this.offices.map((element) => {
                if (element.region == region && isSeted == false) {
                    value = element.city
                    isSeted = true
                }
            })
            return value
        },
        changeCity(){
            this.setCity(this.city)
            this.choosenCity = this.getFirstOfficeOfRegion(this.city)
            localStorage.setItem('mayakRegionObject', JSON.stringify({city: this.getFirstOfficeOfRegion(this.city), region: this.city}))
            this.setRegionCode({region: this.city, array: this.topLevelCategory})
            if (localStorage.mayakOrderId) {
                localStorage.removeItem("mayakOrderId")
                this.setOrder(
                    {
                        id: null,
                        positions: [],
                        
                        account_type: null,
                        location: null,
                        postcode: null,

                        delivery_type: null,
                        pay_type: null,

                        organization_name: null,
                        organization_address: null,
                        inn: null,
                        kpp: null,

                        fio: null,
                        email:null,
                        phone: null,
                        comment: null,
                    }
                )
            }
        },
        setDefaultCity() {
            let name = ''

            if (!this.city) {
                name = 'Выберите регион'
            }else{
                this.topLevelCategory.map((element) => {
                    if (element.id_1c == this.city) {
                        name = element.name
                    }
                })
            }

            if (name) {
                this.defaultValue = name
                this.isSetDefaultValue = true
            }
        },
        pushToCalculators() {
            this.push('')

            setTimeout(() => {
                let element = document.getElementById('main_page_calcurators')
                let top = element.offsetTop
                window.scrollTo(0, top)
            }, 500)
        },
    },
}
</script>