<template>
    <section class="about_company-info_page-sp_type-profiles">
        <div class="container container-nd">
            <p class="main_header">Тип профилирования</p>
            <div class="cards_proftypes">
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile1.png" alt="proftype img">
                    <p>«Г» - ГЛАДКАЯ</p> 
                </div>
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile2.png" alt="proftype img">
                    <p>«В1» - ВОЛНА</p> 
                </div>
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile3.png" alt="proftype img">
                    <p>«В2» - ВОЛНА</p> 
                </div>
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile3.png" alt="proftype img">
                    <p>«Т1» - ТРАПЕЦИЯ</p> 
                </div>
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile3.png" alt="proftype img">
                    <p>«Т2» - ТРАПЕЦИЯ</p> 
                </div>
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile3.png" alt="proftype img">
                    <p>«Т3» - ТРАПЕЦИЯ</p> 
                </div>
                <div class="card_proftype" @click="pushToSpCatalog">
                    <img src="@/img/info-sp/type_profile3.png" alt="proftype img">
                    <p>«Н» - НАКАТКА</p> 
                </div>
            </div>
        </div>
    </section>
</template>
<script>

export default {
    methods: {
        pushToSpCatalog() {
            window.open('/catalog/H2021', '_blank')
        }
    },
}
</script>