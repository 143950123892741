<template>
    <section class="about_company-info_page-sp_keys-connections">
        <div class="container container-nd">   
            <p class="main_header">Замковые соединения сэндвич-панелей</p>
            <div class="cards_keycon">
                <div class="card_keycon">
                    <img src="@/img/info-sp/keycon.png" alt="">
                    <p class="card_header">ЗАМОК ROOF-LOCK</p>
                    <p class="card_subheader">Преимущества</p>
                    <ul class="keycon_advantage_list">
                        <li class="keycon_advantage_li">
                            позволяет обеспечить прочность конструкции и надежную защиту от протечек; 
                        </li>
                        <li class="keycon_advantage_li">
                            исключает появление щелей; предотвращает проникновение холода; 
                        </li>
                        <li class="keycon_advantage_li">
                            обладает повышенной прочностью и герметичностью. 
                        </li>
                    </ul>
                </div>
                <div class="card_keycon">
                    <img src="@/img/info-sp/keycon.png" alt="">
                    <p class="card_header">ЗАМОК ROOF-LOCK</p>
                    <p class="card_subheader">Преимущества</p>
                    <ul class="keycon_advantage_list">
                        <li class="keycon_advantage_li">
                            позволяет обеспечить прочность конструкции и надежную защиту от протечек; 
                        </li>
                        <li class="keycon_advantage_li">
                            исключает появление щелей; предотвращает проникновение холода; 
                        </li>
                        <li class="keycon_advantage_li">
                            обладает повышенной прочностью и герметичностью. 
                        </li>
                    </ul>
                </div>
                <div class="card_keycon">
                    <img src="@/img/info-sp/keycon.png" alt="">
                    <p class="card_header">ЗАМОК Z-LOCK</p>
                    <p class="card_subheader">Преимущества</p>
                    <ul class="keycon_advantage_list">
                        <li class="keycon_advantage_li">
                            позволяет обеспечить прочность конструкции и надежную защиту от протечек; 
                        </li>
                        <li class="keycon_advantage_li">
                            исключает появление щелей; предотвращает проникновение холода; 
                        </li>
                        <li class="keycon_advantage_li">
                            обладает повышенной прочностью и герметичностью. 
                        </li>
                    </ul>
                </div>
                <div class="card_keycon">
                    <img src="@/img/info-sp/keycon.png" alt="">
                    <p class="card_header">ЗАМОК Z-LOCK</p>
                    <p class="card_subheader">Преимущества</p>
                    <ul class="keycon_advantage_list">
                        <li class="keycon_advantage_li">
                            позволяет обеспечить прочность конструкции и надежную защиту от протечек; 
                        </li>
                        <li class="keycon_advantage_li">
                            исключает появление щелей; предотвращает проникновение холода; 
                        </li>
                        <li class="keycon_advantage_li">
                            обладает повышенной прочностью и герметичностью. 
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
</template>
<script>
export default {

}
</script>