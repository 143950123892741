<template>
    <main class="main catalog-nd">
      <div v-if="products.length == 0">
            <banner-block />
            <div class="section section--breadcrumbs">
              <div class="container container-nd">
                  <div class="inner inner-nd">
                      <breadcrumbs :isFirstLevelCatalog="isFirstLevelCatalog" />
                  </div>
              </div>
            </div>

            <top-level-category-block v-if="isFirstLevelCatalog" />
            <div v-else class="section section--no-pt">
              <div class="container container-nd">
                <div class="inner">
                  <div class="heading">
                    <div class="heading-section">
                      <h2>Каталог товаров</h2>
                    </div>
                  </div>
                </div>
                <ul class="list list--flex layout-cards -col-4">

                </ul>
              </div>
            </div>

            <calculator-block />
            <callback-block />
            <popular-products-block />

        
            <application 
              v-if="app == true" 
              :nameServiceForMessage="nameServiceForMessage"
            />
        </div>



        <div v-if="products.length != 0 && products[0].is_group">
            <banner-block />
            <div class="section section--breadcrumbs">
              <div class="container container-nd">
                  <div class="inner inner-nd">
                      <breadcrumbs :isFirstLevelCatalog="isFirstLevelCatalog" />
                  </div>
              </div>
            </div>

            <top-level-category-block v-if="isFirstLevelCatalog" />
            <div v-else class="section section--no-pt">
              <div class="container container-nd">
                <div class="inner">
                  <div class="heading">
                    <div class="heading-section">
                      <h2>Каталог товаров</h2>
                      <!-- // {{ currentProduct }} -->
                    </div>
                  </div>
                </div>
                <ul class="list list--flex layout-cards -col-4">
                  <!-- (product.is_group && product.is_card_product) || (!product.is_group && product.is_card_product && product.price != 0) -->
                  <li 
                    class="list-item" 
                    v-for="product in products" 
                    :key="product.id" 
                    @click="getProducts(product)" 
                    
                  >
                    <a 
                      class="card"
                    >
                      <div class="card-heading">
                        <div class="card-icon">
                          <img 
                            v-if="product.preview"
                            class="product-preview-img"
                            :src="yandexCloud + product.preview" 
                            alt=""
                            :style="(!isFirstLevelCatalog ? 'background-color: rgb(199, 199, 197)' : '')"
                          >
                          <svg 
                            v-if="!product.preview"
                            class="icon" 
                            width="60" 
                            height="60" 
                            viewBox="0 0 60 60"
                          >
                            <use xlink:href="@/assets/img/icons.svg#beam_2"></use>
                          </svg>
                        </div>
                        <div class="card-title">{{ product.name }}</div>
                      </div>
                      <div class="card-arrow">
                        <svg class="icon" width="19" height="19" viewBox="0 0 19 19">
                          <use xlink:href="@/assets/img/icons.svg#arrow-top-right"></use>
                        </svg>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <calculator-block />
            <callback-block />
            <popular-products-block />

        
            <application 
              v-if="app == true" 
              :nameServiceForMessage="nameServiceForMessage"
            />
      </div>
      <div v-if="products.length != 0 && !products[0].is_group" id="topCatalog">
        <div class="section section--breadcrumbs">
            <div class="container">
              <div class="inner">
                <breadcrumbs />
              </div>
            </div>
          </div>
          <div class="section section--no-pt">
            <div class="container">
              <div class="inner">
                <div class="heading">
                  <div class="heading-section">
                    <h2>{{ header }}</h2>
                  </div>
                </div>
              </div>
              <div class="layout layout--catalog">
                <div class="layout-aside">
                  <div class="layout-group">
                    <div class="menu">
                      <div class="menu-toggle">
                        <div class="menu-toggle-section"><span>Каталог товаров</span><span>Свернуть каталог</span></div>
                        <button class="menu-toggle-button"></button>
                      </div>
                      <div class="menu-title">Продукция</div>
                      <ul class="list menu-list">
                        <single-category 
                          v-for="category in categories" 
                          :key="category.id" 
                          :category="category" 
                          v-show="category.is_card_product || category.show"
                        />
                      </ul>
                    </div>
                  </div>
                  <div class="layout-group">
                    <div class="message">
                      <h4 class="message-title">Калькулятор кровли</h4>
                      <p class="message-description">Специальный сервис позволит самостоятельно рассчитать количество и стоимость материалов</p>
                      <a class="message-button button button--primary" @click="push('calculators/roof')">Перейти в конструктор</a>
                      <div class="note message-note">
                        <span class="note-required">*</span>
                        Точную стоимость проекта узнавайте у наших менеджеров по телефону:
                      </div>
                      <a class="message-phone" href="tel:8 800 770 01 98">8 800 770 01 98</a>
                    </div>
                  </div>
                </div>
                <div class="layout-section">
                  <div class="section section--no-pt">
                    <div class="catalog-controls">
                      <div class="catalog-controls-inner">
                        <div class="catalog-control">
                          <div class="catalog-control-label">Сортировать по:</div>
                          <div class="catalog-control-values">
                            <choices-select 
                              data-select 
                              :elements="arraySort" 
                              v-model="form.sort"
                              :defaultValue="'Не выбрано'" 
                              :typeSelect="'float'" 
                            />
                          </div>
                        </div>
                        <div class="catalog-control">
                          <div class="catalog-control-label">Показывать по:</div>
                          <div class="catalog-control-values">
                            <choices-select 
                              data-select 
                              :elements="arrayLimits" 
                              v-model="form.limit"
                              @change="updateForm"
                              :defaultValue="'Не выбрано'" 
                              :typeSelect="'float'" 
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <ul class="list list--flex layout-products -col-3">
                      <single-card v-for="product in products" :key="product.id" :product="product" v-show="product.is_card_product && product.price != 0" />
                    </ul>
                    <v-pagination
                      v-model="currentPage"
                      :page-count="amountPages"
                      :classes="bootstrapPaginationClasses"
                    ></v-pagination>
                  </div>
                  <div class="section section--no-pt">
                    <div class="inner">
                      <div class="heading">
                        <div class="heading-section">
                          <h3>Наши<br> преимущества</h3>
                        </div>
                      </div>
                    </div>
                    <div class="list-offset -col-1">
                      <ul class="list list--flex layout-advantages -col-3">
                        <li class="list-item">
                          <div class="advantage">
                            <div class="advantage-icon"><img src="@/assets/img/advantages/1.svg" alt=""></div>
                            <div class="advantage-title">Собственное производство</div>
                            <div class="advantage-description">Группа компаний «Маякметалл» уже более 25 лет является ведущим российским производителем и поставщиком строительных металлоконструкций, металлопроката и крепежных изделий на территории России и стран СНГ.</div>
                          </div>
                        </li>
                        <li class="list-item">
                          <div class="advantage">
                            <div class="advantage-icon"><img src="@/assets/img/advantages/2.svg" alt=""></div>
                            <div class="advantage-title">Гарантия и контроль качества</div>
                            <div class="advantage-description">Мы уделяем максимум внимания качеству сырья и выпускаемой продукции, совершенствованию сервисного обслуживания клиентов и усилению конкурентных преимуществ компании в целом. Все это позволяет предоставить нашим клиентам продукцию высшего качества с длительными гарантийными обязательствами.</div>
                          </div>
                        </li>
                        <li class="list-item">
                          <div class="advantage">
                            <div class="advantage-icon"><img src="@/assets/img/advantages/3.svg" alt=""></div>
                            <div class="advantage-title">Доставка от производства</div>
                            <div class="advantage-description">Продавец с согласия  Покупателя может организовать доставку путем привлечения услуг транспортной организации. Стоимость доставки не входит в стоимость Товара. Сумма доставки рассчитывается с учетом одного адреса доставки.</div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section section--no-pt section--no-pb">
                    <div class="inner">
                      <div class="heading">
                        <div class="heading-section">
                          <h3>Купить<br> металлочерепицу<br> в Самаре</h3>
                        </div>
                      </div>
                    </div>
                    <div class="layout-content">
                      <p>Металлочерепица - это кровельный материал, который является на сегодняшний день, пожалуй, самым популярным среди строителей-профессионалов и частных застройщиков.</p>
                      <p>Металлочерепица, производимая группой компаний «Маяк», выпускается в виде листов фиксированной длины, либо произвольной длины по индивидуальным размерам кровли заказчика, изготавливается из рулонной окрашенной стали методом проката с использованием высокотехнологичного оборудования, которое создает на стальном листе профиль, имитирующий сложенную рядами натуральную черепицу.</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
  </main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import SingleCategory from './single-category.vue'
import breadcrumbs from './breadcrumbs.vue'
import vPagination from 'vue-plain-pagination'
import choicesSelect from '@/components/choices-select/choices-select.vue'
import singleCard from './single-card.vue'
import application from '@/components/modals/application.vue'
import calculatorBlock from '@/components/pages/home/calculators/new-calculator.vue'
import callbackBlock from '@/components/form-callback.vue'
import popularProductsBlock from '@/components/pages/home/popular-products/popular-products.vue'
import topLevelCategoryBlock from './topLevelCategory.vue'
import bannerBlock from './banner.vue'

export default {
  mixins: [Functions],
  props: {
    msg: {
      type: String,
      default: ""
    },
  },
  components: {
    SingleCategory,
    breadcrumbs,
    vPagination,
    choicesSelect,
    singleCard,
    application,
    calculatorBlock,
    callbackBlock,
    popularProductsBlock,
    topLevelCategoryBlock,
    bannerBlock,
  },
  data () {
    return {
      dataOffers: [],
      isProducts: true,
      isOpen: false,
      categories: [],

      currentPage: 1,

      bootstrapPaginationClasses: {
        ul: 'list list--flex pagination',
        li: 'list-item',
        liActive: '-active',
        liDisable: 'disabled',
        button: 'list-link'
      },

      arrayKrovlya: [
        {id: 1, name: 'Monterrey'},
        {id: 2, name: 'Monterrey'},
      ],
      arraySort: [
        {id: 1, name: 'Названию (А -> Я)'},
        {id: 2, name: 'Названию (Я -> А)'},
      ],
      arrayLimits: [
        {id: 1, name: '12'},
        {id: 2, name: '24'},
        {id: 3, name: '36'},
      ],

      nameServiceForMessage: null,
      currentProduct: {},
    }
  },
  computed: {
    ...Vuex.mapGetters({
      offers: 'app/offers',
      serviсes: 'app/serviсes',
      pagesCatalog: 'dictionary/pagesCatalog',
      products: 'catalog/products',
      searchForm: 'catalog/form',
      region: 'auth/city',
      amountPages: 'catalog/amountPages',
      newPosition: 'basket/newPosition',
      order: 'basket/order',
      header: 'catalog/header',
      regions: 'dictionary/regions',
      app:'appmodal/app',
      yandexCloud: 'app/yandexCloud',
    }),
    form() {
      return Object.assign({}, this.searchForm)
    },
    editNewPosition() {
      return Object.assign({}, this.newPosition)
    },
    id1cCategory() {
      return this.$route.params.id_1c_category
    },
    isFirstLevelCatalog() {
      let isFirst = false
      if (this.$route.path == '/catalog' || this.$route.path == '/catalog/' + this.region) {
        isFirst = true
      }
      return isFirst
    }
  },
  watch: {
    currentPage(val) {
      this.setPage(val)
    },
    offers: {
      handler: function () {
        this.getOffers()
      },
      deep: true
    },
    id1cCategory() {
      this.parseUrl()
    },
    region() {
      this.parseUrl()
    }
  },
  mounted() {
    this.parseUrl()
  },
  created(){
    if(this.offers == null){
      return
    }
    this.getOffers()
  },
  methods: {
    ...Vuex.mapMutations({
      setForm: 'catalog/setForm',
      pushToOrder: 'basket/pushToOrder',
      clearNewPosition: 'basket/clearNewPosition',
      setApp:'appmodal/setApp',
    }),
    ...Vuex.mapActions({
      search: 'catalog/search',
      getCategories: 'catalog/getCategories',
      addPosition: 'basket/addPosition',
      createPosition: 'basket/create',
      searchNavbar: 'catalog/searchNavbar',
      getCardProduct: 'catalog/getProduct',
    }),
    getOffers(){
      if(!this.offers || !this.offers.offers){
        return
      }
      this.offers.offers.map((el)=>{
          if(el == null){
              return
          }
        if(this.isAuthenticated == true){
          if(el.from == this.user.account_type || el.from == null){
              this.dataOffers.push(el)
              return
          }
        }else{
          if(el.from == null){
              this.dataOffers.push(el)
              return
          }
        }
      })
    },
    parseUrl() {
      let id_1c = null
      if (this.id1cCategory) {
        id_1c = this.id1cCategory
      }else if (this.region) {
        id_1c = this.region
      }
      
      if (!id_1c) {
        return
      }

      // this.searchNavbar({
      //   id_1c: id_1c,
      //   root_id: this.region,
      //   is_group: true
      // })
      if (id_1c != this.region) {
        this.getCardProduct({id_1cs: [id_1c], root_id: this.region})
          .then((response) => {
            this.currentProduct = response.data[0]
          })
      }else{
        this.currentProduct = {}
      }

      if (this.region) {
        this.form.parent_id = id_1c
        this.form.root_id = this.region
        this.form.is_show_zero_price = false
        // this.form.componentFrom = 'search profucts on catalog page'
        this.setForm(this.form)
        this.search({from: 'catalog'})
          .then(() => {
            
          })
          .catch(() => {
            this.push('catalog')
          })
      }
    },
    getProducts(product) {
      if (product.is_group && !product.is_sub_sign) {
        this.push('catalog/' + product.id_1c)   
      }else{
        this.push('cardProduct/' + product.id)
      }
    },
    updateForm() {
      let value = null
      this.arrayLimits.map(element => {
        if (element.id == Number(this.form.limit)) {
          value = Number(element.name)
        }
      })

      this.form.limit = value
      this.setForm(this.form)
      this.search()
    },
    setPage(page) {
      let topElement = document.querySelector('#topCatalog')

      window.scrollTo(0, topElement.offsetTop)
      this.form.page = page
      this.setForm(this.form)
      this.search()
    },
    toProduct(ev, product) {
      if (ev.target.tagName == 'svg' || ev.target.tagName == 'BUTTON') {
        this.push('cardProduct/' + product.id) 
      }else{
        this.push('cardProduct/' + product.id)
      }
    },
    getCatalogCategories() {
      if (!this.region) {
        return
      }
      this.getCategories({ root_id: this.region, parent_id: this.region })
        .then((response) => {
          this.categories = response.data
        })
    },
    openModal(name) {
      this.nameServiceForMessage = name 
      this.setApp(true)
    },
  }
}
</script>
<style>
  .pagination .list-item .list-link{
    border: none;
  }
  /* .card-heading{
    right: 32px;
  } */
  .product-preview-img{
    height: 120px;
    width: 120px;
    /* z-index: 2; */
    /* position: absolute; */
  }
</style>