<template>
    <main class="admin-index index__admin">
        <div class="admin-index_header">
            <h3>О компании</h3>
            <button 
                class="button button--primary save_btn" 
                @click="updateData"
            >
                Сохранить
            </button>
        </div>

        <div class="index__admin">
            <span>Блок о компании</span>
            <span>Заголовок</span>
            <input 
                type="text" 
                v-model="editCompany.headingCompany" 
                placeholder="Заголовок"
                @keyup="update"
            >
            <div>
                <vue-editor 
                    v-model="editCompany.descriptionCompany" 
                    @text-change="update"
                />
            </div>
            <span>Ссылка на изображение</span>
            <input 
                type="text" 
                v-model="editCompany.imgCompany" 
                placeholder="Ссылка на изображение"
                @keyup="update"
            >
            <div class="row">
                <label class="fl__img">
                    <input 
                        :ref="'file'" 
                        type="file" 
                        @change="uploadImg()"
                    >
                </label>
                <p> Изображение должно быть размером 600х540 рх, формат jpg, png. Для правильного отображения соблюдайте размер и формат. </p>
            </div>
            <img 
                :src="company.imgCompany" 
                width="30%"
                alt=""
            >
        </div>
        <div class="index__admin">
            <span>История компании в датах</span> 
        </div>
                  
        <div class="tabs tabs--buttons tabs--history">
            <div class="tabs-title">
                <div 
                    :class="history.blockType == 1 ? 'tabs-item -active' :'tabs-item'" 
                    @click="changeView(1)"
                >
                    2000-ныe
                </div>
                <div 
                    :class="history.blockType == 2 ? 'tabs-item  -active' :'tabs-item'" 
                    @click="changeView(2)"
                >
                    1999-тыe
                </div>
            </div>
            <div class="index__admin">
                <button 
                    @click="addHistr"
                >
                    Добавить историю
                </button>  
            </div>
            <div 
                v-show="viewYears == obj.blockType" 
                v-for="(obj, i) in company.dataHistory" 
                :key="i" 
                class="index__admin" 
            >
                <history 
                    :key="i"
                    :index="i"
                    :element="obj"
                />
                
            </div>
            <h3>Блок ссылок на другие страницы сайта</h3>
            <div 
                v-for="(obj, ind) in company.dataBlocks" 
                :key="ind+ 's'"
                class="index__admin" 
            >
                <blocks 
                    :key="ind"
                    :index="ind"
                    :element="obj"
                />
            </div>
            <div  class="index__admin"> 
                <button @click="addBlock">
                    Добавить блок
                </button>
            </div>
        </div>
    </main>
</template>

<script>
import Vuex from 'vuex'
import history from './history.vue'
import blocks from './blocks.vue'
import { VueEditor } from "vue2-editor"

export default {
    components: {
    VueEditor,
    blocks,
    history,
    },
    // mixins: [Functions],
    data() {
        return {
            viewYears: 1,
            dataHistory:[],
            dataBlocks:[],
            headingCompany: '',
            descriptionCompany:'',
            imgCompany: '',
            oneBlock:{
                blockName:'',
                blockLink:'',
            },
            history:{
                blockType: 1,
                year: '',
                heading: '',
                description: '',
            },
            editObject: null,
            index: null,
        }
    },
    computed: {
        ...Vuex.mapGetters({
            company: 'app/company',
            fileUrl: 'app/fileUrl',
        }),
        editCompany() {
            return Object.assign({}, this.company)
        }
    },
    methods: {
        ...Vuex.mapActions({
            updateJson: 'app/updateJson',
            upload: 'app/upload',
        }),
        ...Vuex.mapMutations({
            pushCompanyHistory: 'app/pushCompanyHistory',
            pushCompanyBlock: 'app/pushCompanyBlock',
            updateCompanyObject: 'app/updateCompanyObject',
        }),
        update() {
            this.updateCompanyObject(this.editCompany)
        },
        uploadImg() {
            let image = this.$refs['file'].files[0]
            this.upload({file: image})
                .then((response) => {
                    this.editCompany.imgCompany = this.fileUrl + response.data[0].link
                    this.update()
                })
        },
        updateData() {
            this.updateJson()
        },
        changeView(val){
            this.viewYears = val
            this.history.blockType = val
            
        },
        addHistr(){
          this.pushCompanyHistory({blockType: this.history.blockType, year: null, heading: null, description: null, link: null})
        },
        addBlock(){
            this.pushCompanyBlock({blockName: null, blockLink: null})
        },
    },
}
</script>
