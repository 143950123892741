<template>
    <main class="main">
        <div class="section section--breadcrumbs">
            <div class="container">
                <div class="inner">
                    <ul class="list list--flex breadcrumbs">
                        <li class="list-item">
                            <a class="list-link" href="#">
                                <svg class="icon" width="16" height="16" viewBox="0 0 16 16">
                                    <use xlink:href="assets/img/icons.svg#home"></use>
                                </svg>
                            </a>
                        </li>
                        <li class="list-item"><span class="list-current">Оплата и доставка</span></li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="section section--no-pt">
            <div class="container">
                <div class="inner">
                    <div class="heading">
                        <div class="heading-section">
                            <h2>Оплата и доставка</h2>
                        </div>
                    </div>
                </div>
                <div class="tabs tabs--pay-delivery">
                    <div class="tabs-title">
                        <div 
                            class="tabs-item" 
                            :class="{'-active' : activeTab == 1}"
                            @click="activeTab = 1"
                        >
                            Способы оплаты
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 2}"
                            @click="activeTab = 2"
                        >
                            Правила оплаты
                            </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 3}"
                            @click="activeTab = 3"
                        >
                            Возврат товара
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 4}"
                            @click="activeTab = 4"
                        >
                            Отказ от услуги
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 5}"
                            @click="activeTab = 5"
                        >
                            Доставка товара
                        </div>
                    </div>
                    <div class="tabs-content">
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 1}"
                        >
                            <div class="row row--pay-delivery">
                                <div class="col">
                                    <h3>Способы<br> оплаты</h3>
                                </div>
                                <div class="col">
                                    <p>Оплата заказов производится любым удобным для вас способом:</p>
                                    <p>- Банковской картой онлайн (Visa/Masterсard/Мир)<br> - Счет на оплату в банке<br> - Покупка в кредит</p>
                                    <p>При оплате заказа онлайн на ваш e-mail будет отправлен электронный чек.<br> Для заказов с оплатой при получении предоставляется бумажный чек.</p>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 2}"
                        >
                            <div class="row row--pay-delivery">
                                <div class="col">
                                    <h3>Правила<br> оплаты</h3>
                                </div>
                                <div class="col">
                                    <p>По запросу покупателя менеджер формирует счет на оплату и высылает покупателю ссылку на email/sms/icq, перейдя по которой, покупатель  выбрать способ оплаты и оплатить счет. Сумму и описание товара уже будут указаны. К оплате принимаются платежные карты: VISA Inc, MasterCard WorldWide.</p>
                                    <p>Для оплаты товара банковской картой при оформлении заказа в интернет-магазине выберите способ оплаты: банковской картой.</p>
                                    <p>При оплате заказа банковской картой, обработка платежа происходит на авторизационной странице банка, где Вам необходимо ввести данные Вашей банковской карты:</p>
                                    <p>- тип карты<br> - номер карты,<br> - срок действия карты (указан на лицевой стороне карты)<br> - Имя держателя карты (латинскими буквами, точно также как указано на карте)<br> - CVC2/CVV2 код</p>
                                    <p>Если Ваша карта подключена к услуге 3D-Secure, Вы будете автоматически переадресованы на страницу банка, выпустившего карту, для прохождения процедуры аутентификации. Информацию о правилах и методах дополнительной идентификации уточняйте в Банке, выдавшем Вам банковскую карту.</p>
                                    <p>Безопасность обработки интернет-платежей через платежный шлюз банка гарантирована международным сертификатом безопасности PCI DSS. Передача информации происходит с применением технологии шифрования SSL.  Эта информация недоступна посторонним лицам.</p>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 3}"
                        >
                            <div class="row row--pay-delivery">
                                <div class="col">
                                    <h3>Возврат<br> товара</h3>
                                </div>
                                <div class="col">
                                    <p>Процедура возврата товара надлежащего качества регламентируется статьей 26.1 федерального закона «О защите прав потребителей».</p>
                                    <p>- Потребитель вправе отказаться от товара в любое время до его передачи, а после передачи товара - в течение семи дней.</p>
                                    <p>- Возврат товара надлежащего качества возможен в случае, если сохранены его товарный вид, потребительские свойства, а также документ, подтверждающий факт и условия покупки указанного товара;</p>
                                    <p>- Потребитель не вправе отказаться от товара надлежащего качества, имеющего индивидуально-определенные свойства если указанный товар может быть использован исключительно приобретающим его человеком;</p>
                                    <p>- При отказе потребителя от товара продавец должен возвратить ему денежную сумму, уплаченную потребителем по договору, за    исключением расходов продавца на доставку от потребителя возвращенного товара, не позднее чем через десять дней со дня предъявления потребителем соответствующего требования;</p>
                                    <p>- Потребитель при обнаружении недостатков, не оговоренных продавцом, оказанной услуги вправе по своему выбору потребовать статьей 18 федерального закона «О защите прав потребителей»:</p>
                                    <ul>
                                        <li>безвозмездного устранения недостатков;</li>
                                        <li>соответствующего уменьшения цены;</li>
                                        <li>возмещения понесенных им расходов по устранению недостатков своими силами или третьими лицами;</li>
                                    </ul>
                                    <p>Процедура возврата товара не надлежащего качества регламентируется статьей 18 федерального закона «О защите прав потребителей».</p>
                                    <p>Потребитель в случае обнаружения в товаре недостатков, если они не были оговорены продавцом, по своему выбору вправе:</p>
                                    <ul>
                                        <li>потребовать замены на товар этой же марки (этих же модели и (или) артикула);</li>
                                        <li>потребовать замены на такой же товар другой марки (модели, артикула) с соответствующим перерасчетом покупной цены;</li>
                                        <li>потребовать соразмерного уменьшения покупной цены;</li>
                                        <li>потребовать безвозмездного устранения недостатков товара или возмещения расходов на их исправление потребителем или третьим лицом;</li>
                                        <li>отказаться от исполнения договора купли-продажи и потребовать возврата уплаченной за товар суммы.</li>
                                        <li>Потребитель в случае обнаружения в товаре внешних недостатков, не оговоренных продавцом, вправе предъявить требования Продавцу в момент приемки товара.</li>
                                        <li>Потребитель вправе предъявлять требования, связанные со скрытыми недостатками  товара, если они обнаружены в течение гарантийного срока, за исключением акционного товара;</li>
                                    </ul>
                                    <p>Акционный товар не подлежит замене и возварту.</p>
                                    <ul>
                                        <li>Исполнитель отвечает за недостатки услуги, на которую не установлен гарантийный срок, если потребитель докажет, что они возникли до ее принятия им или по причинам, возникшим до этого момента</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 4}"
                        >
                            <div class="row row--pay-delivery">
                                <div class="col">
                                    <h3>Отказ<br> от услуги</h3>
                                </div>
                                <div class="col">
                                    <p>Право потребителя на расторжение договора об оказании услуги регламентируется статьей 32 федерального закона «О защите прав потребителей»</p>
                                    <p>Потребитель вправе расторгнуть договор об оказании услуги в любое время, уплатив исполнителю часть цены пропорционально части оказанной услуги до получения извещения о расторжении указанного договора и возместив исполнителю расходы, произведенные им до этого момента в целях исполнения договора, если они не входят в указанную часть цены услуги</p>
                                </div>
                            </div>
                        </div>
                        <div 
                            class="tabs-item"
                            :class="{'-active' : activeTab == 5}"
                        >
                            <div class="row row--pay-delivery">
                                <div class="col">
                                    <h3>Доставка<br> товара</h3>
                                </div>
                                <div class="col">
                                    <p>Доставка товара в адрес Покупателя осуществляется одним из способов:</p>
                                    <ul>
                                        <li>Самовывоз: самостоятельно Покупателем   со склада Продавца.</li>
                                        <li>Доставка от производителя</li>
                                    </ul>
                                    <p>Заказывая  на ГК «Маяк»  партии  сэндвич-панелей, профилированного листа или  металлоконструкций, вы можете быть уверены в  их качестве и сроках поставки .  Продукция на производственных площадках будет производится в соответствии с разработанным под ваши нужды графиком.</p>
                                    <p>Для облегчения задач, стоящих перед нашими Заказчиками, ГК «Маяк» организовала собственную службу логистики.  Вы всегда можете обратиться к нам с вопросом - «как быстрее, надежнее, и выгоднее доставить  партию сэндвич-панелей, профлиста или металлочерепицы по  нужному  адресу?»</p>
                                    <p>В зависимости от ваших объемов, сроков и типа продукции, специалисты отдела логистики разработают  график отгрузок, оптимально вписывающийся в  график строительства вашего здания или складскую программу вашего магазина.   Мы всегда можем рекомендовать  вам надежную транспортную компанию, или предложим организовать доставку продукции собственным транспортом ГК «Маяк» .</p>
                                    <p>Вы купили у нас материалы для строительства собственного дома или коттеджа? Вам не придется связываться с сомнительными «частными перевозчиками» - наш менеджер организует доставку  ваших покупок быстро, надежно и по фиксированным ценам.  К вашим услугам малотоннажные  грузовые машины, которые доставят  купленный у нас металлопрокат  на вашу строительную площадку к заранее оговоренному времени</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>
<script>
export default {
    data() {
        return {
            activeTab: 1
        }
    }
}
</script>