<template>
    <div class="section home_promo_block">
        <div v-if="defaultPromo" class="container container-nd">
            <img :src="defaultPromo.linkimg" alt="">

            <a 
                class="button button--primary" 
                @click="push('offers/' + defaultPromo.link)"
            >
                Читать полностью
            </a>
        </div>
    </div>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
export default {
    mixins: [Functions],
    computed: {
        ...Vuex.mapGetters({
            uiPromotions: 'ui_promotion/promotions',
        }),
        defaultPromo() {
            return this.uiPromotions[0]
        }
    },
}
</script>