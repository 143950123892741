<template>
	<main class="main">
		<div class="section section--no-pt section--no-pb section-nlk section-cl">
			<div class="container">
				<div class="inner">
					<ul class="list list--flex breadcrumbs cl-breadcrumbs">
						<li 
							class="list-item"
							@click="push('')"
						>
							<a class="list-link" href="#">
								<svg class="icon" width="16" height="16" viewBox="0 0 16 16">
									<use xlink:href="@/assets/img/icons.svg#home"></use>
								</svg>
							</a>
						</li>
						<li class="list-item"><span class="list-current">Калькулятор водостока</span></li>
					</ul>
					<form
						onsubmit="return false"
					>
						<h2 class="cl__h2-title"><span>1</span> Выберите тип кровли</h2>
						<!-- <p 
							class="fs-14 max-1180 mb-30"
							v-if="adminCalculatorDrainage.step1" 
							v-html="adminCalculatorDrainage.step1.description"
						/> -->
						<div class="cl-top-option nlk-horizontal-scroll mb-70">
							<label 
									class="cl-icon-radio"
									v-for="roofType in roofTypes"
									:key="roofType.id"
									@click="setRoofType(roofType.id)"
								>
									<input 
										type="radio" 
										name="option1" 
										:checked="(calculateForm.roof_type == roofType.id ? true : false)"
									>
									<div class="cl-icon-radio__icon">
										<img :src="require('@/assets/img/option1/o' + roofType.num + '.svg')" class="cl-icon-radio__img-1">
										<img :src="require('@/assets/img/option1/o' + roofType.num + '-a.svg')" alt="" class="cl-icon-radio__img-2">
									</div>
									<div class="cl-icon-radio__btn">{{ roofType.name }}</div>
								</label>
						</div>

						<div class="cl-row">
							<div class="cl-row-group cl-row-group_mr-90">
								<h2 class="cl__h2-title">
									<span>1А</span> 
									Смонтирована ли у вас кровля?
								</h2>

								<p 
									class="fs-14 max-1180 mb-30"
									v-if="adminCalculatorDrainage.step1A" 
									v-html="adminCalculatorDrainage.step1A.description"
								/>

								<div class="cl-row-btns">
									<label class="cl-rowbtn-radio">
										<input 
											type="radio" 
											name="btn1" 
											:checked="(calculateForm.is_mounted == true ? true : false)"
											@click="setIsMounted(true)"
										>
										<span>Да</span>
									</label>
									<label class="cl-rowbtn-radio">
										<input 
											type="radio" 
											name="btn1"
											:checked="(calculateForm.is_mounted == false ? true : false)"
											@click="setIsMounted(false)"
										>
										<span>Нет</span>
									</label>
								</div>
							</div>
							<div class="cl-row-group cl-row-group_mr-90">
								<h2 class="cl__h2-title">
									<span>1Б</span> 
									Материал фасада дома
								</h2>

								<p 
									class="fs-14 max-1180 mb-30"
									v-if="adminCalculatorDrainage.step1A" 
									v-html="adminCalculatorDrainage.step1A.description"
								/>

								<div class="cl-row-btns">
									<label 
										class="cl-rowbtn-radio"
										v-for="facadeMaterial in facadeMaterials"
										:key="facadeMaterial.id"
									>
										<input 
											type="radio" 
											name="btn2" 
											:checked="(calculateForm.facade_material == facadeMaterial.id ? true : false)"
											@click="setFacadeMaterial(facadeMaterial.id)"
										>
										<span>{{ facadeMaterial.name }}</span>
									</label>
								</div>
							</div>
						</div>

						<!-- <div class="cl-hr"></div>
						
						<h2 
							v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40"
							class="cl__h2-title mb-50"
						>
							<span>2</span>
							Расстояние между стропилами
						</h2>

						<p 
							class="fs-14 max-1180 mb-30"
							v-if="adminCalculatorDrainage.step2 && calculateForm.roof_type == 30 || calculateForm.roof_type == 40" 
							v-html="adminCalculatorDrainage.step2.description"
						/>

						<div 
							v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40" 
							class="cl-row"
						>
							<label 
								class="cl-rowbtn-radio cl-rowbtn-radio-big cl-row-group_mr-23"
								v-for="distance in distanceList"
								:key="distance.id"
							>
								<input 
									type="radio" 
									name="btn4" 
									:checked="(calculateForm.distance == distance.value ? true : false)"
									@click="setDistance(distance.value)"
								>
								<span>{{ distance.name }}</span>
							</label>
						</div> -->

						<div class="cl-hr"></div>

						<div class="cl-grid">
							<div class="cl-grid__left">
								<h2 class="cl__h2-title mb-50">
									<span>2</span> 
									Введите необходимые размеры
								</h2>

								<!-- <p 
									v-if="adminCalculatorDrainage.step3" 
									v-html="adminCalculatorDrainage.step3.description"
								/> -->

								<label class="cl-input-group mb-30">
									<input 
										type="number"
										step="0.01"
										required
										v-model="calculateForm.width"
										@keyup="update"
										@focus="inputFocusName = 'w1'"
									>
									<span data-title="Ширина, м" v-if="calculateForm.roof_type != 30 && calculateForm.roof_type != 40">w</span>
									<span data-title="Ширина ската 1, м" v-else>w1</span>
								</label>

								<label 
									class="cl-input-group mb-30"
									v-if="calculateForm.roof_type == 30 || calculateForm.roof_type == 40"
								>
									<input 
										type="number" 
										step="0.01"
										required
										v-model="calculateForm.width_2"
										@keyup="update"
										@focus="inputFocusName = 'w2'"
									>
									<span data-title="Ширина ската 2, м">w2</span>
								</label>

								<label class="cl-input-group mb-30">
									<input 
										type="number" 
										step="0.01"
										required
										v-model="calculateForm.height"
										@keyup="update"
										@focus="inputFocusName = 'h1'"
									>
									<span data-title="Высота от земли до карниза, м" class="max_width_span_text_with_arrows">h</span>
								</label>

								<label class="cl-input-group mb-30">
									<input 
										type="number"
										step="0.01" 
										required
										v-model="calculateForm.cornice_lentgh"
										@keyup="update"
										@focus="inputFocusName = 'a'"
									>
									<span data-title="Длина карнизного свеса, м">a</span>
								</label>

								<label class="cl-input-group cl-input-group_no">
									<input 
										type="number"
										min="0.6"
										max="1"
										step="0.1" 
										required
										v-model="calculateForm.distance"
										@keyup="update"
										@change="validate"
									>
									<span data-title="Расстояние между крюками, м" class="max_width_span_text_with_arrows"></span>
								</label>
							</div>
							<div class="cl-grid__right">
								<img :src="require('@/assets/img/calculators/drainage/' + getCalculatorImgFullName)" alt="">
							</div>
						</div>
						<div class="cl-hr"></div>
						<h2 class="cl__h2-title mb-50"><span>3</span> Выберите водосточную систему</h2>

						<!-- <p 
							class="fs-14 max-1180 mb-30"
							v-if="adminCalculatorDrainage.step4" 
							v-html="adminCalculatorDrainage.step4.description"
						/> -->
						<div class="cl-row fw-wrap">
							<label 
								class="cl-rowbtn-radio cl-rowbtn-radio-big cl-row-group_mr-23 mb-2"
								v-for="drainSystem in drainSystems"
								:key="drainSystem.id"
							>
								<input 
									type="radio" 
									name="btn3" 
									:checked="(calculateForm.view_gutter == drainSystem.id_1c ? true : false)"
									@click="setViewGutter(drainSystem.id_1c)"
								>
								<span>
									{{ drainSystem.name }}
									<span class="cl-bal">
										<span class="cl-bal__icon">?</span>
										<span class="cl-bal__text">Lorem ipsum dolor sit amet.</span>
									</span>
								</span>
							</label>	
						</div>
						
						<div class="cl-hr"></div>
						<h2 
							v-if="products.length != 0"
							class="cl__h2-title mb-50"
						>
							<span>4</span>
							Выберите цвет водостока
						</h2>

						<!-- <p 
							class="fs-14 max-1180 mb-30"
							v-if="adminCalculatorDrainage.step5 && products.length != 0" 
							v-html="adminCalculatorDrainage.step5.description"
						/> -->
						
						<div 
							v-if="products.length != 0"
							class="cl-color-grid"
						>
							<label 
								class="cl-check"
								v-for="(color, i) in colors"
								:key="i"
								
							>
								<input 
									type="checkbox"
									:checked="(calculateForm.color == color.id_1c ? true : false)"
									@click="setColor(color.id_1c)"
								>
								<span>
									<span class="color" :style="'background-color:' + color.code"></span> {{ color.id_1c }}
								</span>
							</label>
						</div>

						<div class="cl-hr"></div>

						<button 
							class="button button--primary w-205"
							type="button"
							@click="save"
						>
							Рассчитать
						</button>

						<div class="cl-hr"></div>
					</form>

					<div 
						v-if="!isEmptyObject(calculateResponse)"
						class="sticky-grid mt-80"
					>
						<div class="sticky-grid__content">
							<div 
								class="cl-prod-item nlk-horizontal-scroll"
								v-for="(position, idx) in calculateResponse.positions"
								:key="idx"
							>
								<div class="cl-prod-item-row p-30 pt-57">
									<div class="cl-prod-item__img ml-30 mr-150">
										<img 
											v-if="getDrainagePhotoFullName(position.calc_type)"	
											:src="require('@/assets/img/drainages/' + getDrainagePhotoFullName(position.calc_type))"
										>
									</div>
									<div class="cl-prod-item__text">
										<div class="cl-prod-item__title">{{ position.name }}</div>
										<ul class="cl-prod-item__list">
											<a @click="openImgModal(position.calc_type)">Показать элемент на схеме</a>
										</ul>
									</div>
									<div class="cl-prod-item__last">
										<div class="cl-prod-item__price">{{ position.price }} {{ regionCurrency[regionCode].symbol }}/шт</div>
										<button 
											class="btn-close"
											@click="deletePosition(idx)"
										/>
									</div>
								</div>
								<div class="cl-prod-item-row cl-prod-item-row_bottom flex_jcsb">
									<div class="cl-prod-item__col">Количество: {{ position.amount }}</div>
									<div class="cl-prod-item__all">
										<div class="cl-prod-item__lab">Итого:</div>
										<div class="cl-prod-item__price">{{ position.sell }} {{ regionCurrency[regionCode].symbol }}</div>
									</div>
								</div>
							</div>

							<div class="cal-seo">
								<h3 class="cal-seo__h3">{{ adminCalculatorDrainage.generalDescription.header }}</h3>
								<div v-html="adminCalculatorDrainage.generalDescription.text" />
							</div>
						</div>
						<div class="sticky-grid__aside">
							<div class="sticky-box">
								<div class="sticky-box__title">Счет / заказ</div>
								<div class="sticky-box__bottom">
									Общая стоимость <b>{{ fullCost }} {{ regionCurrency[regionCode].symbol }}</b>
								</div>

								<button 
									class="button button--primary"
									:disabled="!isCanBasket"
									@click="addInBasket"
								>
									Добавить в корзину
								</button>
							</div>
						</div>
					</div>

					<img-modal 
						v-if="isShowDrainageImgModal && position_img_link != null" 
						:link="position_img_link"
					/>
				</div>
			</div>
		</div>
	</main>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import imgModal from './drainage-position-img-modal.vue'

export default {
	mixins: [Functions],
	components: {
		imgModal,
	},
	data() {
		return {
			material: 10,
			roofTypes: [
				{id: 10, name: 'Односкатная', num: 1},
				{id: 20, name: 'Двускатная', num: 2},
				{id: 30, name: 'Вальмовая', num: 3},
				{id: 40, name: 'Шатровая', num: 4},
				{id: 50, name: 'Мансардная', num: 5},
			],
			drainSystems: [],
			facadeMaterials: [
				{id: 20, name: "Кирпич"},
				{id: 10, name: "Дерево"},
			],
			distanceList: [
				{id: 10, name: "0.6 м", value: 0.6},
				{id: 20, name: "0.7 м", value: 0.7},
				{id: 30, name: "0.8 м", value: 0.8},
				{id: 40, name: "0.9 м", value: 0.9},
			],
			colors: [],
            colorsArray: [],
			changeElementIndex: null,
			inputFocusName: null,
			products: [],
			imagesName: [
				'drain_end_cap',
				'drain_funnel',
				'drain_gutter',
				'drain_drain_elbow_sink',
				'drain_gutter_connector',
				'drain_drain_elbow_60',
				'drain_mount_adjustable',
				'drain_pipe',
				'drain_connecting_pipe',
				'drain_pipe_bracket_brick',
				'drain_gutter_corner',
				'drain_hook',
			],
			test_calc_type: '',
			position_img_link: null,
		}
	},	
	computed: {
		...Vuex.mapGetters({
			formCalculatorDrainage: 'calculator/formCalculatorDrainage',
			calculateResponse: 'calculator/calculateResponse',
			yandexCloud: 'app/yandexCloud',
			regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
			calculators: 'app/calculators',
			calc_drain_body: 'calculator/calc_drain_body',
			region: 'auth/city',
			regionAdresses: 'app/regionAdresses',
			isShowDrainageImgModal: 'calculator/isShowDrainageImgModal',
			userCompanyId: 'auth/userCompanyId',
		}),
		calculateForm() {
			return Object.assign({}, this.formCalculatorDrainage)
		},
		fullCost() {
			let cost = 0
			if (this.calculateResponse.positions) {
				this.calculateResponse.positions.map((element) => {
					if (element.sell) {
						cost += Number(element.sell)
					}
				})
			}
			return cost
		},
		isCanBasket() {
			let can = true
			if (this.calculateResponse.positions) {
				this.calculateResponse.positions.map((element) => {
					if (element.price == null || element.price == 0) {
						can = false
					}
				})
			}else{
				can = false
			}
			return can
		},
		formViewGutter() {
			return this.calculateForm.view_gutter
		},
		formRoofType() {
			return this.calculateForm.roof_type
		},
		getCalculatorImgFullName(){
			let name = null
			name = this.calculateForm.roof_type + '/' 
			if (this.inputFocusName) {
				name += this.inputFocusName + '.svg' 
			}else{
				name += 'w1.svg'
			}
			return name
		},
		adminCalculatorDrainage() {
			return this.calculators.calculatorDrainage
		},
		objectCurrentRegion() {
            let current_region = {}
            this.regionAdresses.map((element) => {
                if (element.id_1c == this.region) {
                    current_region = element
                }
            })
            return current_region
        },
	},
	watch: {
		calculateForm() {
			this.setCalculateResponse({})
		},
		material() {
			this.calculateForm.view_material = null
			this.update()
		},
		formViewGutter() {
			// this.getCurrentColors()
		},
		formRoofType() {
			this.inputFocusName = null
		},
		region() {
			this.setCalculateResponse({})
			this.setDefaultFormDrainage()
			this.products = []
			this.getValues()
		},
	},
	destroyed() {
		this.setCalculateResponse({})
		this.setDefaultFormDrainage()
	},
	mounted() {
		// this.getCurrentColors()
		this.getValues()
	},
	methods: {
		...Vuex.mapMutations({
			setFormCalculatorDrainage: 'calculator/setFormCalculatorDrainage',
			deletePosition: 'calculator/deletePosition',
			savePosition: 'calculator/savePosition',
			setCalculateResponse: 'calculator/setCalculateResponse',
			setDefaultFormDrainage: 'calculator/setDefaultFormDrainage',
			setIsShowDrainageImgModal: 'calculator/setIsShowDrainageImgModal',
		}),
		...Vuex.mapActions({
			calculateDrainageStore: 'calculator/calculateDrainage',
			calculatePosition: 'calculator/calculatePosition',
			createPosition: 'basket/create',
			getColors: 'calculator/getColors',
			getStoreCalcDrain: 'calculator/getCalcDrain',
			searchNavbar: 'catalog/searchNavbar',
			getFilters: 'catalog/getFilters',
		}),
		save() {
			this.calculateForm.region_code = this.objectCurrentRegion.region_code, 
			this.calculateForm.region_description = this.objectCurrentRegion.region_description
			this.calculateForm.root_1c_id = this.region
			this.calculateForm.company_id = Number(this.userCompanyId)
			this.calculateDrainage()
		},
		calculateDrainage() {
			this.validate()
			this.calculateDrainageStore()
		},
		setChangeElementIndex(val) {
			this.changeElementIndex = val
		},
		issetColorInArray(id_1c) {
			let isset = false
			this.colorsArray.map((element) => {
				if (element == id_1c) {
					isset = true
				}
			})
			return isset
		},
		setColorsArray() {
			if (this.products.length == 0) {
				return
			}
			this.colorsArray = []
			this.products.map((element) => {
				if (element.colors) {
					if (Array.isArray(element.colors)) {
						if (element.colors.length != 0) {
							element.colors.map(el => {
								if (!this.issetColorInArray(el)) {
									this.colorsArray.push(el)
								}
							})
						}
					}else{
						if (!this.issetColorInArray(element.colors)) {
							this.colorsArray.push(element.colors)
						}
					}
				}
			})

			this.getCurrentColors()
		},
		getCurrentColors() {
			this.getColors(this.colorsArray)
				.then((response) => {
					this.colors = response.data
				})
		},
		validate() {
			if (this.calculateForm.cornice_lentgh) {
				this.calculateForm.cornice_lentgh = Number(this.calculateForm.cornice_lentgh)
			}
			if (this.calculateForm.distance) {
				this.calculateForm.distance = Number(this.calculateForm.distance)
				if (this.calculateForm.distance < 0.6) {
					this.calculateForm.distance = 0.6
				}else if (this.calculateForm.distance > 1) {
					this.calculateForm.distance = 1
				}
			}
			if (this.calculateForm.facade_material) {
				this.calculateForm.facade_material = Number(this.calculateForm.facade_material)
			}
			if (this.calculateForm.height) {
				this.calculateForm.height = Number(this.calculateForm.height)
			}
			if (this.calculateForm.width) {
				this.calculateForm.width = Number(this.calculateForm.width)
			}
			if (this.calculateForm.width_2) {
				this.calculateForm.width_2 = Number(this.calculateForm.width_2)
			}
			this.update()
		},
		update() {
			this.setFormCalculatorDrainage(this.calculateForm)
		},
		setRoofType(id) {
			this.calculateForm.roof_type = id
			this.update() 
		},
		setFacadeMaterial(id) {
			this.calculateForm.facade_material = id
			this.update() 
		},
		setIsMounted(val) {
			this.calculateForm.is_mounted = val
			this.update() 
		},
		setViewGutter(id_1c) {
			this.calculateForm.view_gutter = id_1c
			this.getProducts()
			this.update() 
		},
		setDistance(val) {
			this.calculateForm.distance = val
			this.update()
		},
		setColor(id_1c) {
			this.calculateForm.color = id_1c
			this.update() 
		},
		changeAmountInsulationThickness(type) {
			this.calculateForm.insulation_thickness = Number(this.calculateForm.insulation_thickness)
			if (type == 'plus') {
				this.calculateForm.insulation_thickness += 1
			}else if (type == 'minus') {
				if (this.calculateForm.insulation_thickness == 1) {
					return
				}
				this.calculateForm.insulation_thickness -= 1
			}
			this.update()
		},
		updatePosition(element, index, color, elementName) {
			if (elementName) {
				this.savePosition(
				{
					index: index,
					id_1c: element.id_1c,
					coating: element.coating,
					thickness: element.thickness,
					overall_width: element.overall_width,
					images: element.links,
					color: color,
					name: elementName,
					root_id: element.root_1c_id
				})
			}else{
				this.savePosition(
					{
						index: index,
						id_1c: element.id_1c,
						coating: element.coating,
						thickness: element.thickness,
						overall_width: element.overall_width,
						images: element.links,
						color: color,
						root_id: element.root_1c_id
					})
			}
			this.calculate(index)
		}, 
		calculate(index) {
			this.calculateResponse.positions.map((el, idx) => {
				if (idx == index) {	
					this.calculatePosition({position: el, index: index})
						.then((response) => {
							el.price = response.data.positions[0].price
							el.sell = response.data.positions[0].sell
						})
				}
			})
		},
		addInBasket() {
			let array = []
			this.calculateResponse.positions.map((element) => {
				if (element.id_1c) {
					array.push(element)
				}
			})
			
			let source_utm = JSON.parse(localStorage.getItem("utm_source"))
			this.createPosition({
				positions: array, 
				region_code: this.objectCurrentRegion.region_code, 
				region_description: this.objectCurrentRegion.region_description,
				utm: source_utm,
			})
				.then((response) => {
					localStorage.setItem('mayakOrderId', JSON.stringify(response.data.id))
					let that = this
					setTimeout(() => {
						that.push('basket')
					}, 1000)
				})
		},
		issetImgsName(name) {
			let isset = false
			this.imagesName.map((element) => {
				if (element == name) {
					isset = true
				}
			})
			return isset
		},
		getDrainagePhotoFullName(name) {
			let response = ''
			if (this.issetImgsName(name)) {
				response = name + '.png'
			}
			return response
		},
		getValues() {
			this.getStoreCalcDrain(this.region)
				.then(() => {
					if (this.calc_drain_body.systems.length != 0) {
						this.searchNavbar({root_id: this.region, id_1cs: this.calc_drain_body.systems})
							.then((response) => {
								this.drainSystems = response.data
							})
					}
				})
		},
		getProducts() {
			this.searchNavbar({root_id: this.region, parent_id: this.calculateForm.view_gutter})
				.then((response) => {
					// this.colors = response.data.colors

					let parent_id_1cs = []
					if (response.data.length != 0) {
						response.data.map((element) => {
							parent_id_1cs.push(element.id_1c)
						})
					} 
					if (parent_id_1cs.length != 0) {
						this.searchNavbar({root_id: this.region, parent_ids: parent_id_1cs})
							.then((resp) => {
								this.products = resp.data
								this.setColorsArray()
							})
					}

				})
		},
		openImgModal(calc_type) {
			this.setIsShowDrainageImgModal(true)
			if (calc_type != null && calc_type != 'null') {
				this.position_img_link = this.getDrainagePhotoFullName(calc_type)
			}else{
				this.position_img_link = this.getDrainagePhotoFullName(this.imagesName[0])
			}
		}
	}	
}
</script>
<style scoped src="../../../css/nlk-style.css"></style>
<style lang="scss">
	.fw-wrap{
		flex-wrap: wrap;
	}
	.mb-2{
		margin-bottom: 2rem;
	}
</style>