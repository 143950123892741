<template>
    <header class="header">
        <div class="header-container header-container--desktop">
            <div class="header-top">
                <div class="container header-nd">
                    <!-- container-nd -->
                    <div class="navbar_city_block">
                        <div  class="open_region_block">
                            <div @click="isShowRegionsList = !isShowRegionsList" class="open_region_row">
                                <img class="point_icon" src="@/assets/img/navbar_point_icon.png" alt="">
                                <p class="navbar_city_name">{{ choosenCity }}</p>
                                <img v-if="!isShowRegionsList" class="arrow_icon" src="@/assets/img/arrow_down.png" alt="">
                                <img v-if="isShowRegionsList" class="arrow_icon" src="@/assets/img/arrow_up.png" alt="">
                            </div>
                            

                            <div v-if="isShowRegionsList" class="regions_list_block">
                                <p class="regions_header">Выберите город</p>
                                <div class="lists">
                                    <ul class="regions_list">
                                        <li @click="choosenCountry = 'ru'" :class="choosenCountry == 'ru' ? '-active' : ''">
                                            Россия
                                            <img v-if="choosenCountry == 'ru'" src="@/assets/img/red_arrow_right.png" alt="">
                                        </li>
                                        <li @click="choosenCountry = 'kz'" :class="choosenCountry == 'kz' ? '-active' : ''">
                                            Казахстан
                                            <img v-if="choosenCountry == 'kz'" src="@/assets/img/red_arrow_right.png" alt="">
                                        </li>
                                    </ul>
                                    <ul class="cities_list">
                                        <li
                                            :class="choosenCity == cityRegion.name ? '-active' : ''"
                                            v-for="cityRegion, index in regionCityArray"
                                            :key="index"
                                            v-show="(choosenCountry == 'kz' && cityRegion.region == 'H2018') || (choosenCountry == 'ru' && cityRegion.region != 'H2018')"
                                            @click="setChoosenCity(cityRegion)"
                                        >
                                            {{ cityRegion.name }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <button class="button button--primary work_button" @click="push('vacancy')">Работа в МаякМеталл</button>
                    </div>
                    <ul class="list list--flex navigation-header list-nd">
                        <li 
                            class="list-item-nd" 
                            @click="push('aboutcompany')"
                        >
                            <a class="list-link">О компании</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('catalog')"
                        >
                            <a class="list-link">Каталог</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('coatings')"
                        >
                            <a class="list-link">Покрытия</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('documents')"
                        >
                            <a class="list-link">Документы</a>
                        </li>
                        <li 
                            class="list-item-nd"
                        >
                            <a @click="pushToBuild('metall')" class="list-link">Металлоконструкции</a>
                        </li>
                        <li 
                            class="list-item-nd" 
                            @click="push('contacts')"
                        >
                            <a class="list-link">Контакты</a>
                        </li>
                    </ul>
                    <div style="width: 21.5%;"></div>
                </div>
            </div>
            <div class="header-bottom header-bottom-nd">
                <div class="container">
                    <!-- container-nd -->
                    <div class="header-bottom-block">
                        <div class="header-logotype-nd" @click="pushWithNavigationClose('')">
                            <a class="logotype">
                                <img src="@/assets/img/logotype.svg" alt="">
                            </a>
                        </div>
                        <div class="header-search-nd" v-click-outside="onClickOutside">
                            <form 
                                class="search search--results" 
                                :class="{'search--focus' : productsNavbar.length != 0 && isShowProductList}" 
                                action="#" 
                                data-search 
                                onsubmit="return false"
                            >
                                <div class="search-field">
                                    <input 
                                        class="search-input" 
                                        v-model="form.query" 
                                        @keyup="getProducts" 
                                        type="search" 
                                        placeholder="Поиск по каталогу" 
                                        data-search-input
                                        @focus="isShowProductList = true"
                                        @keyup.enter="searchProductsPage"
                                    >
                                    <button 
                                        class="search-submit" 
                                        @click="searchProductsPage"
                                    >
                                        <span class="search-submit-label">найти</span>
                                    </button>
                                </div>
                                <div class="search-dropdown">
                                    <div class="search-results"  data-search-results>
                                        <div 
                                            class="search-result" 
                                            v-for="product in productsNavbar" 
                                            :key="product.id"
                                            v-show="product.price != 0"
                                        >
                                            <div class="search-result-image">
                                                <a class="search-result-link" v-if="product.links">
                                                    <img :src="yandexCloud + product.links[0]" alt="">
                                                </a>
                                                <a class="search-result-link" v-else>
                                                    <img src="@/assets/img/default_img_nomenclature.png">
                                                </a>
                                            </div>
                                            <div class="search-result-title" @click="pushToProduct(product)">
                                                <a class="search-result-link">{{ product.name }}</a>
                                            </div>
                                            <div class="search-result-price">
                                                <div class="search-result-price-label">Цена</div>
                                                <div class="search-result-price-value">{{ product.price }} {{ regionCurrency[regionCode].currency }}/м2</div>
                                            </div>
                                            <div class="search-result-button" @click="addInBasket(product)">
                                                <button class="button button--primary button--cart">
                                                    <svg class="icon" width="25" height="25" viewBox="0 0 25 25">
                                                        <use xlink:href="@/assets/img/icons.svg#cart"></use>
                                                    </svg>| 1
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div class="header-contacts-nd">
                            <div class="right-part"  @mouseleave="closeOfficesList">
                                <div @mouseover="openOfficesList" class="header-phone" style="margin-bottom: 0px;">
                                    <a class="phone" v-if="sourceUtmPhone">{{ maskedSourceUtmPhone }}</a>
                                    <a class="phone" v-if="!sourceUtmPhone && currentAddress">{{ currentAddressPhone }}</a>
                                    <img v-if="!isShowOfficesList" src="@/assets/img/arrow_down.png" alt="">
                                    <img v-if="isShowOfficesList" src="@/assets/img/arrow_up.png" alt="">
                                </div>
    
                                <img v-if="order.positions.length != 0" @click="push('basket')" class="full_basket_icon nd-icon" src="@/img/nd-icons/full_basket.png" alt="">
                                <img v-else @click="push('basket')" class="full_basket_icon nd-icon" src="@/img/nd-icons/empty_basket.png" alt="">
                                
                                <img @click="push('nlk')" class="lk_icon nd-icon" src="@/img/nd-icons/lk.png" alt="">

                                <div v-if="isShowOfficesList" @mouseover="openOfficesList" class="offices_block">
                                    <button @click="openModalCallback()" class="button button--primary call_button">
                                        <img class="phone_icon" src="@/assets/img/phone_icon.png" alt="">
                                        Заказать звонок
                                    </button>
                                    <div class="offices_subblock">
                                        <p class="offices_block_header">Офис продаж и склад</p>
                                        <p class="offices_block_text">{{ currentAddress.city }} , {{ currentAddress.street }}</p>
                                    </div>
                                    <div class="offices_subblock">
                                        <p class="offices_block_header">Время работы</p>
                                        <p class="offices_block_text">{{ currentAddress.working_hours }}</p>
                                    </div>
                                    <div class="offices_subblock">
                                        <p class="offices_block_header">Почта</p>
                                        <p v-if="sourceUtmEmail" class="offices_block_text">{{ sourceUtmEmail }}</p>
                                        <p v-else class="offices_block_text">{{ currentAddress.email }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    
        <mobile-nav
            @pushToLogin="pushToLogin" 
        />
    
        <!-- <div v-if="this.topLevelCategory[0]" class="header-controls">
            <div class="container">
                <div 
                    class="dropdown header-dropdown"
                    :style="!city ? 'display: block': ''"
                >
                    <div class="dropdown-group">
                        <div class="dropdown-group-title">Вы находитесь в {{ this.topLevelCategory[0].name }}?</div>
                        <div class="dropdown-group-body">
                            
                            <choices-select
                                v-if="isOtherRegion == true"
                                data-select 
                                :elements="topLevelCategory" 
                                v-model="valueRegion" 
                                :defaultValue="defaultValueRegion"
                                :typeSelect="'default'"
                                :id_1c="true"
                            />

                            <ul class="list list--flex layout-buttons" v-if="isOtherRegion == false" >
                                <li class="list-item"><a class="button button--primary" @click="acceptFirstRegion">Да, верно</a></li>
                                <li class="list-item"><a class="button button--default" @click="isOtherRegion = true">Изменить город</a></li>
                            </ul>

                            <ul class="list list--flex layout-buttons" v-if="isOtherRegion == true" style="margin-top: 8px">
                                <li class="list-item"><a class="button button--primary" @click="changeRegion">Подтвердить</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
    </header>
</template>
<script>
import Vuex from 'vuex'
import Functions from '@/utils/functions'
import vClickOutside from 'v-click-outside'
import mobileNav from './mobile-navigation.vue'
// import choicesSelect from '@/components/choices-select/choices-select.vue'

export default {
    mixins: [Functions],
    components: {
        mobileNav,
        // choicesSelect,
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    data () {
        return {
            isShowControls: false,
            isShowChooseTypeUser: false,
            isShowChooseCity: false,
            city: null,
            from: 10,

            options: [
                { id: 1, text: 'Hello' },
                { id: 2, text: 'World' }
            ],

            query: null,
            isShowProductList: false,
            isReady: false,
            defaultValue: '',
            isSetDefaultValue: false,
            valueRegion: null,
            defaultValueRegion: 'Выберите регион',
            isOtherRegion: false,

            organizationId: null,

            isShowAllAdresses: false,

            isShowRegionsList: false,
            isShowOfficesList: false,

            choosenCountry: 'ru',
            choosenCity: '',

            offShowOfficesListId: null, 
            isCheckedLocalRegion: false,
        }
    },
    watch: {
        isReady() {
            this.setDefaultCity()
        },
        topLevelCategory: {
              handler: function () {
                  if (this.isSetDefaultValue == false) {
                      this.isReady = true
                  }
              },
              deep: true
        },
        currentUrl() {
            this.form.query = ''
            this.updateForm(this.form)
        },
        userCompanyId(val) {
            this.organizationId = val
        },
        region() {
            if (this.isCheckedLocalRegion) {
                this.checkChoosenCityLocalStorage()
            }
        },
    },
    mounted() {
        if (this.topLevelCategory.length != 0){
            this.setDefaultCity()
        }
        if (this.userCompanyId) {
            this.organizationId = this.userCompanyId
        }
    },
    created(){
        this.getContacts()
        this.geolocation()
            .then(() => {
                this.checkChoosenCityLocalStorage()
                this.isCheckedLocalRegion = true
            })

        if(localStorage.fromMayak){
            this.from = JSON.parse(localStorage.getItem("fromMayak"))
        }

        this.searchUtm()
            .then(() => {
                setTimeout(() => this.checkSourceUtm(), 300)
            })
    },
    computed:{
        ...Vuex.mapGetters({
            region:'auth/city',
            regions: 'dictionary/regions',
            fileUrl: 'app/fileUrl',
            isAuthenticated:'auth/isAuthenticated',
            isOpenMobileNavigation: 'app/isOpenMobileNavigation',
            isOpenMobileSubNavigation: 'app/isOpenMobileSubNavigation',
            searchForm: 'search/form',
            newPosition: 'basket/newPosition',
            order: 'basket/order',
            productsNavbar: 'search/productsNavbar',
            yandexCloud: 'app/yandexCloud',
            topLevelCategory: 'dictionary/topLevelCategory',
            regionCode: 'auth/regionCode',
            regionCurrency: 'dictionary/regionCurrency',
            regionAdresses: 'app/regionAdresses',
            sourceUtmPhone: 'app/sourceUtmPhone',
            sourceUtmEmail: 'app/sourceUtmEmail',
            user: 'auth/user',
            companies: 'companies/companies',
            userCompanyId: 'auth/userCompanyId',
            utmPositions: 'utm/utmPositions',
            currentCard: 'ui_card_address/currentCard',
            ui_contacts: 'ui_contacts/contacts',
            offices: 'ui_contacts/offices',
        }),
        maskedSourceUtmPhone() {
            let masked = ''
            
            let phone = this.sourceUtmPhone
            masked += '+' + phone.substring(0, 1) 
            masked += ' (' + phone.substring(1, 4) + ') ' 
            masked += phone.substring(4, 7) 
            masked += '-' + phone.substring(7)  
            
            return masked
        },
        form() {
            return Object.assign({}, this.searchForm)
        },
        editNewPosition() {
            return Object.assign({}, this.newPosition)
        },
        currentAddress() {
            let object = null
            this.offices.map((element) => {
                if (element.city == this.choosenCity) {
                    object = element
                }
            })
            return object
        },
        currentAddressPhone() {
            var phone = '8 (952) 647-00-63'
            var phones = []
            if (this.currentAddress.phone) {
                phones = this.currentAddress.phone.split(',')
                if (phones[0]) {
                    phone = phones[0]
                }
            }
            return phone
        },
        currentUrl() {
            return this.$route.name
        },
        sourceUtm() {
            return this.$route.query.utm_source
        },
        regionCityArray() {
            var array_result = []
            var array = this.offices
            array.map((element) => {
                if (!this.checkIssetElementInArray(array_result, element.city)) {
                    array_result.push({name: element.city, value: element.city, region: element.region})
                }
            })
            return array_result
        },
    },
    methods:{
        ...Vuex.mapMutations({
            setCity:'auth/setCity',
            setFrom:'auth/setFrom',
            setOpenMobileNavigation: 'app/setOpenMobileNavigation',
            setOpenMobileSubNavigation: 'app/setOpenMobileSubNavigation',
            updateForm: 'search/updateForm',
            clearNewPosition: 'basket/clearNewPosition',
            setProductsNavbar: 'search/setProductsNavbar',
            setRegionCode: 'auth/setRegionCode',
            setOrder: 'basket/setOrder',
            setUserCompanyId: 'auth/setUserCompanyId',
            setSourceUtmPhone: 'app/setSourceUtmPhone',
            setSourceUtmEmail: 'app/setSourceUtmEmail',
            setShowCallbackModalForm: 'app/setShowCallbackModalForm',
            setCityName: 'auth/setCityName',
        }),
        ...Vuex.mapActions({
            searchNavbar: 'search/searchNavbar',
            search: 'catalog/search',
            addPosition: 'basket/addPosition',
            createPosition: 'basket/create',
            searchPage: 'search/searchPage',
            searchUtm: 'utm/search',
            getCardById1c: 'ui_card_address/getCardById1c',
            geolocation: 'ui_contacts/geolocation',
            getContacts: 'ui_contacts/getContacts',
        }),
        openOfficesList() {
            if (this.offShowOfficesListId) {
                clearTimeout(this.offShowOfficesListId)
            }
            this.isShowOfficesList = true
        },
        closeOfficesList() {
            this.offShowOfficesListId = setTimeout(() => {
                this.isShowOfficesList = false
            }, 300)
        },
        openModalCallback() {
            this.setShowCallbackModalForm(true)
            this.isShowOfficesList = false
        },
        checkIssetElementInArray(array, value) {
            var isset = false
            array.map((element) => {
                if (element.name == value) {
                    isset = true
                }
            })
            return isset
        },
        maskedPhone(phone) {
            let masked = ''
            masked += '+' + phone.substring(0, 1) 
            masked += ' (' + phone.substring(1, 4) + ') ' 
            masked += phone.substring(4, 7) 
            masked += '-' + phone.substring(7)  
            
            return masked
        },
        setLocalStorageRegionFirstCity(region) {
            var array = this.offices
            var isSeted = false
            array.map((element) => { 
                if (element.region == region && !isSeted) {
                    localStorage.setItem('mayakRegionObject', JSON.stringify({city: element.city, region: element.region}))
                    this.choosenCity = element.city
                    isSeted = true
                }
            })
        },
        checkSourceUtm(region) {
            let source_utm = this.$route.query.utm_source
            if (source_utm) {
                source_utm = source_utm.replace(/"/g, '')
                this.utmPositions.map((element) => {
                    if (element.utm == source_utm) {
                        if (!region) {
                            // localStorage.setItem('regionMayak', JSON.stringify(element.root_1c_id))
                            this.setLocalStorageRegionFirstCity(element.root_1c_id)
                            this.city = element.root_1c_id
                            this.setCity(element.root_1c_id)
                            this.setSourceUtmPhone(element.phone)
                            this.setSourceUtmEmail(element.email)

                        }else{
                            if (region == element.root_1c_id) {
                                this.setSourceUtmPhone(element.phone)
                                this.setSourceUtmEmail(element.email)
                            }else{
                                this.setSourceUtmPhone(null)
                                this.setSourceUtmEmail(null)
                            }
                        }
                    }
                })
            }else{
                this.setSourceUtmPhone(null)
                this.setSourceUtmEmail(null)
            }
        },
        changeCompanyId() {
            localStorage.setItem('mayak_company_id', JSON.stringify(this.organizationId)) 
            this.setUserCompanyId(this.organizationId)
        },
        // changeRegion() {
        //     this.city = this.valueRegion
        //     this.changeCity()
        // },
        // acceptFirstRegion() {
        //     if (this.topLevelCategory[0]) {
        //         let region = this.topLevelCategory[0].id_1c
        //         this.city = region
        //         this.changeCity()
        //     }
        // },
        // declineFirstRegion() {
        //     if (this.topLevelCategory[1]) {
        //         let region = this.topLevelCategory[1].id_1c
        //         this.city = region
        //         this.changeCity()
        //     }
        // },
        // changeCity(){
        //     this.getCardById1c(this.city)
        //     this.setCity(this.city)
        //     localStorage.setItem('regionMayak', JSON.stringify(this.city))
        //     this.setRegionCode({region: this.city, array: this.topLevelCategory})
        //     setTimeout(() => this.checkSourceUtm(this.city), 300)
        //     if (localStorage.mayakOrderId) {
        //         localStorage.removeItem("mayakOrderId")
        //         this.setOrder(
        //             {
        //                 id: null,
        //                 positions: [],
                        
        //                 account_type: null,
        //                 location: null,
        //                 postcode: null,

        //                 delivery_type: null,
        //                 pay_type: null,

        //                 organization_name: null,
        //                 organization_address: null,
        //                 inn: null,
        //                 kpp: null,

        //                 fio: null,
        //                 email:null,
        //                 phone: null,
        //                 comment: null,
        //             }
        //         )
        //     }
        // },
        changeFrom(val){
            this.from = val
            this.setFrom(val)
            localStorage.setItem('fromMayak', JSON.stringify(val))
        },
        pushToLogin() {
            if (this.isAuthenticated) {
                this.push('nlk')
            }else{
                this.push('login')
            }
            this.setOpenMobileNavigation(false)
            this.setOpenMobileSubNavigation(false)
        },
        pushWithNavigationClose(str) {
            this.push(str)
            this.setOpenMobileNavigation(false)
            this.setOpenMobileSubNavigation(false)
        },
        getProducts() {
            if (!this.city) {
                this.form.query = ''
                this.updateForm(this.form)
                return
            }
            if (this.form.query.length < 4) {
                this.setProductsNavbar([])
                return
            }
            this.form.root_id = this.region
            this.updateForm(this.form)
            this.searchNavbar()
        },
        pushToProduct(product) {
            if (product.is_group && !product.is_sub_sign) {
                this.form.parent_id = product.id_1c
                this.updateForm(this.form)
                this.search()
                this.form.query = null
                this.isShowProductList = false
                this.push('catalog/' + product.id_1c)
            }else{
                this.form.query = null
                this.isShowProductList = false
                this.push('cardProduct/' + product.id)
            }
        },
        addInBasket(product) {
            if (product.is_group) {
                this.form.parent_id = product.id_1c
                this.updateForm(this.form)
                this.search()
                this.query = null
                this.push('/catalog/' + product.id_1c)
            }else{
                this.push('cardProduct/' + product.id)
                this.isShowProductList = false
            }           
        },
        searchProductsPage() {
            if (!this.form.query || this.form.query.length < 3) {
                return
            }
            this.form.root_id = this.region
            this.updateForm(this.form)
            this.searchPage()
            this.isShowProductList = false
            this.push('search')
        },
        onClickOutside() {
            this.isShowProductList = false
        },
        setDefaultCity() {
            let name = ''
            this.topLevelCategory.map((element) => {
                if (element.id_1c == this.city) {
                    name = element.name
                }
            })

            if (!name) {
                name = 'Выберите регион'
            }

            this.defaultValue = name
            this.isSetDefaultValue = true
        },
        pushToCalculators() {
            this.push('')

            setTimeout(() => {
                let element = document.getElementById('main_page_calcurators')
                let top = element.offsetTop
                window.scrollTo(0, top)
            }, 500)
        },
        pushToBuild(str) {
            if (str == 'build') {
                window.open('https://mk.mayakmetall.ru/', '_blank')
            }else if (str == 'metall') {
                window.open('https://mk.mayakmetall.ru/mk', '_blank')
            }
        },
        setChoosenCity(element) {
            localStorage.setItem('mayakRegionObject', JSON.stringify({city: element.name, region: element.region}))
            this.choosenCity = element.name
            this.setCityName(element.name)
            this.setCity(element.region)
            this.isShowRegionsList = false
            if (localStorage.mayakOrderId) {
                localStorage.removeItem("mayakOrderId")
                this.setOrder(
                    {
                        id: null,
                        positions: [],
                        
                        account_type: null,
                        location: null,
                        postcode: null,

                        delivery_type: null,
                        pay_type: null,

                        organization_name: null,
                        organization_address: null,
                        inn: null,
                        kpp: null,

                        fio: null,
                        email:null,
                        phone: null,
                        comment: null,
                    }
                )
            }
        },
        checkChoosenCityLocalStorage() {
            var regionObj = JSON.parse(localStorage.getItem('mayakRegionObject'))
            if (regionObj) {
                if (regionObj.region == 'H2018') {
                    this.choosenCountry = 'kz'
                }
                this.choosenCity = regionObj.city
                this.city = regionObj.region
                this.setCity(this.city)
            }else{
                if (this.offices.length != 0) {
                    var firstOffice = this.offices[0]
                    localStorage.setItem('mayakRegionObject', JSON.stringify({city: firstOffice.city, region: firstOffice.region}))
                    this.choosenCity = firstOffice.city
                    if (this.offices[0].region == 'H2018') {
                        this.choosenCountry = 'kz'
                    }
                    this.city = firstOffice.region
                    this.setCity(this.city)
                }
            }
        },
    }
}
</script>
<style scoped src="../../css/castom.css"></style>
<style lang="scss">
    .header-address{
        position: relative;   
    }
    .addresses-list-block{
        position: absolute;
        top: 20px;
        right: 0;
        background-color: white;
        padding: 20px;
        border: 1px solid #e7e7e7;
        border-radius: 10px;

        .address-item{
            margin-bottom: 10px;
        }
    }
    .navbar_link_email{
        text-decoration: unset;
        &:hover{
            color: #ED1C24;
        }
    }

    .right-part{
        display: flex;
        flex-wrap: nowrap;
        position: relative;
    }

    .navbar_city_block{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        padding-top: 28px;
        margin-right: 18px;
        position: relative;
        justify-content: flex-end;

        width: 28.5%;

        .open_region_block{
            position: relative;

            .open_region_row{
                display: flex;
                align-items: center;
                cursor: pointer;
            }
        }

        .point_icon{
            height: 24px;
            width: 24px;
            margin-right: 4px;
        }

        .arrow_icon{
            height: 16px;
            width: 16px;
        }

        .navbar_city_name{
            font-weight: 600;
            font-size: 14px;
            line-height: 21px;
            margin: 0;
            margin-right: 10px;
        }

        .work_button{
            border-radius: 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding: 4px 20px;
            margin-left: 18px;
        }
    }

    .header-bottom-block{
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        width: 100%;

        .header-logotype-nd{
            width: 28.5%;
            margin-right: 18px;

            .logotype{
                height: 27px;
                width: 240px;
                float: right;

                img{
                    width: 184px;
                    height: 26px;
                    max-width: 100% !important;
                }
            }
        }

        .header-search-nd{
            width: 47.5%;
            margin-right: 20px;

            .search{
                max-width: 100%;
            }
        }

        .header-contacts-nd{
            width: 21.5%;

            .right-part{
                align-items: center;

                .header-phone{
                    display: flex;
                    flex-wrap: nowrap;
                    align-items: center;
                    cursor: pointer;

                    img{
                        padding-left: 10px;
                        padding-right: 11px;
                    }
                }
            }

            .full_basket_icon{
                height: 35px;
                margin-right: 20px;
                cursor: pointer;
            }

            .lk_icon{
                height: 35px;
                cursor: pointer;
            }
        }
    }

    .regions_list_block{
        position: absolute;
        top: 40px;
        left: 0;
        background-color: white;
        box-shadow: 0px 4px 20px 0px #0000001A;
        padding-top: 25px;
        padding-left: 25px;
        padding-right: 40px;
        padding-bottom: 50px;
        border-radius: 10px;

        .lists{
            display: flex;
        }

        .regions_header{
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.4rem;

            margin-bottom: 39px;
        }

        .regions_list{
            margin-left: 0;
            margin-right: 60px;
            list-style-type: none;

            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.4rem;

            li{
                margin-bottom: 20px;
                display: flex;
                align-items: center;
                
                img{
                    margin-left: 10px;
                }

                &:hover{
                    color: #ED1C24;
                    cursor: pointer;
                }
            }
            .-active{
                color: #ED1C24;
                font-weight: 600;
            }
        }

        .cities_list{
            margin-left: 0;
            list-style-type: none;

            font-weight: 500;
            font-size: 1.8rem;
            line-height: 2.4rem;

            li{
                margin-bottom: 10px;

                &:hover{
                    color: #ED1C24;
                    cursor: pointer;
                }
            }

            .-active{
                font-weight: 600;
                color: #ED1C24;
            }
        }
    }

    .offices_block{
        position: absolute;
        top: 43px;
        right: 110px;
        background-color: white;
        box-shadow: 0px 4px 20px 0px #0000001A;
        border-radius: 10px;
        width: 100%;
        min-width: 400px;
        padding-left: 25px;
        padding-top: 22px;
        padding-right: 34px;
        padding-bottom: 21px;
        // display: flex;
        // flex-direction: column;
        // justify-content: start;

        .phone_icon{
            margin-right: 10px;
        }

        .call_button{
            border-radius: 10px;
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            padding: 8px 10px;
            margin-bottom: 22px;
        }

        .offices_subblock{
            margin-bottom: 20px;
        }

        .offices_block_header{
            font-weight: 600;
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin-bottom: 9px;
        }

        .offices_block_text{
            font-weight: 400;
            font-size: 1.8rem;
            line-height: 2.4rem;
            margin-bottom: 9px;
            color: grey;
        }
    }
</style>